import React, { useEffect, useState } from 'react';
import '../../style/Session.css'

export default function Session({ timeFinal, setTimeFinal, handleSession }) {
  // state to store time
  const [time, setTime] = useState(0);
  const [flag, setFlag] = useState(false)

  // state to check stopwatch running or not
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  // Hours calculation
  const hours = Math.floor(time / 360000);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = time % 100;

  // Method to start and stop timer
  const startAndStop = () => {
    setIsRunning(!isRunning);
    setTimeFinal(minutes + ':' + seconds)
    isRunning ? setFlag(true) : setFlag(false)
  };

  // Method to reset timer back to 0
  const reset = () => {
    setTime(0);
    isRunning ? setFlag(true) : setFlag(false)
  };

  return (
    <div className="stopwatch-container">
      <p className="stopwatch-time">
        {hours}:{minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
        {/* {milliseconds.toString().padStart(2, "0")} */}
      </p>
      <div className="stopwatch-buttons">
        <button className="stopwatch-button" onClick={startAndStop}>
          {isRunning ? "Stop" : "Start"}
        </button>
        <button className="stopwatch-button" onClick={reset}>
          Reset
        </button>

      </div>
      {flag
        ? (<div className='stopwatch-buttons'>
          <button
          onClick={handleSession}
          >
            End Session
          </button>
        </div>)
        : (<></>)}

    </div>
  );
};