import { AuthProvider } from './component/context/AuthContext';
import { PersistGate } from 'redux-persist/integration/react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import React from 'react';
import App from './App';
import './tailwind.css';
import './index.css';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );  

root.render(
  <AuthProvider>
    <Provider store={store}>
      <PersistGate loading={"loading"} persistor={persistor}>
        <BrowserRouter>
          <ProSidebarProvider>
            <App />
          </ProSidebarProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
