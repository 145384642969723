import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Outlet } from 'react-router-dom';
import Spinner from './Spinner';
import { useAuth } from '../context/AuthContext';

export default function HospitalRoute()
{
    const [ ok, setOk ] = useState( "" );
    const [ auth ] = useAuth();
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    useEffect( () =>
    {
        const authCheck = async () =>
        {
            const res = await axios.get( `${server}/api/medi/auth/hospital-auth` );
            if ( res.data.ok )
            {
                setOk( true );
            }
            else
            {
                setOk( false );
            }
        };
        if ( auth?.token ) authCheck();
        // eslint-disable-next-line
    }, [ auth.token ] );
    return ok ?
        <Outlet />
        : <Spinner />;
}
