import React from 'react';

export default function DeleteRole( {
    selected,
    handleDelete,
    roleName
} )
{
    return (
        <div className='card'>
            <div className='card-header text-center'>
                Delete Role
            </div>
            <div className='card-body text-center'>
                {roleName}
            </div>
            <div className='card-footer'>
                <button className='btn btn-success float-end' onClick={()=>{
                    handleDelete(selected)
                }}>
                    Confirm
                </button>
            </div>
        </div>
    );
}
