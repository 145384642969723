import React, { useEffect } from 'react';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

export default function Role( { type, name, setName, setSelectedOption, capabilityArray, handleSubmit, selectedOption } )
{
    return (
        <div className='card'>
            <div className='card-header text-center'>
                {type === 'new' ? ( "Add Role" ) : ( "Edit Role" )}
            </div>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    {/* name */}
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Role Name<sup style={{color:"red"}}> *</sup></label>
                        <input type="type" className="form-control" aria-describedby="emailHelp" onChange={e => { setName( e.target.value ); }} value={name} />
                    </div>

                    {/* capabilities */}
                    <label>Please Select Capabilities<sup style={{color:"red"}}> *</sup></label>
                    <div className='mb-3'>
                        <Select
                            isMulti
                            value={selectedOption}
                            options={capabilityArray}
                            onChange={setSelectedOption}
                        />
                    </div>
                    {/* submit */}
                    <div className='mb-3 text-center'>
                        <button type='submit' className="btn btn-primary col-12 mt-4">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
