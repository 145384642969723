import React, { useEffect, useState } from 'react';
import Layout from "../../component/layout/Layout.js";
import { useAuth } from '../../component/context/AuthContext.js';
import axios from 'axios';
import { Modal, Tabs } from 'antd';
import { FcBusinessman, FcBusinesswoman } from "react-icons/fc";
import Accordion from 'react-bootstrap/Accordion';
import styles from "./patient.module.css";
import ImageModal from '../admin/ImageModal.js';
import Invoice from '../admin/Invoice.js';
import toast from 'react-hot-toast';
import SessionVideoError from '../admin/SessionVideoError.js';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


export default function PatientDash()
{

  const auth = useAuth();
  const navigate = useNavigate();
  const patientID = auth[ 0 ]?.user?.patientID;
  const server = `${process.env.REACT_APP_AWS_LAMBDA}`;
  const [ patient, setPatient ] = useState( '' );
  const [ arr, setArr ] = useState( [] );
  const [ visible, setVisible ] = useState( false );
  const [ path, setPath ] = useState( '' );
  const [ PatentType, sePatentType ] = useState( '' );
  const [ module, setModule ] = useState( "" );
  const [ patientDetail, setPatientDetail ] = useState( "" );
  const [ paymentId, setPaymentId ] = useState( '' );
  const [ archive, setArchive ] = useState( "" );
  const [ videoUrl, setVideoUrl ] = useState( '' );
  const [ flag, setFlag ] = useState( false );
  const [ todaySession, setTOdaySession ] = useState( '' );
  const [ therapistName, setTherapistName ] = useState( "" );
  const [ patientName, setPatientName ] = useState( "" );
  const [ role, setRole ] = useState( "" );
  const [ errorToken, setErrorToken ] = useState( '' );
  const [ token, setToken ] = useState( "" );
  const [ roomPin, setRoomPin ] = useState( '' );
  const [ room_meeting_id, setRoomMeetingId ] = useState( '' );

  const patientDetails = async () =>
  {
    if ( patientID?.length > 0 )
    {
      const res = await axios.get( `${server}/api/medi/patient-dash/details/${patientID}` );
      setPatient( res.data.patient );
    }
  };

  useEffect( () =>
  {
    patientDetails();
    // eslint-disable-next-line
  }, [ patientID ] );

  useEffect( () =>
  {
    if ( patient._id )
    {
      let dummyArray = [];
      dummyArray.push( { "Packages": Object.values( patient?.packageList ).flat() } );
      dummyArray.push( { "Session History": Object.values( patient?.sessionList ).flat() } );
      dummyArray.push( { "Payment": Object.values( patient?.paymentHistory ).flat() } );
      setArr( dummyArray );
    }
  }, [ patient ] );

  const invoiceGenerator = async ( child ) =>
  {
    try
    {
      setPaymentId( child.id );
      const res = await axios.get( `${server}/api/medi/patient/invoice/${patientID}` );
      if ( res.data.success )
      {
        setPatientDetail( res.data.patient );
        setModule( 'invoice' );
        setVisible( true );
      }
    } catch ( error )
    {
      toast.error( "something went wrong while invoicing" );
    }
  };

  const handleVideo = async ( roomId ) =>
  {
    try
    {
      const res = await axios.get( `${server}/api/medi/vcall/archive/${roomId}` );
      setArchive( res.data.res.archive );
    } catch ( error )
    {
      console.log( error );
    }
  };

  useEffect( () =>
  {
    if ( archive?.length > 0 )
    {
      Object.values( archive ).map( ( item ) => ( () =>
      {
        if ( ( item.transcoded )?.length > 0 )
        {
          Object.values( item.transcoded ).map( ( sub ) =>
          {
            setVideoUrl( String( sub.url ).replace( process.env.REACT_APP_ENABLEX_APP_PORTAL, process.env.REACT_APP_ENABLEX_APP_USERNAME + ":" + process.env.REACT_APP_ENABLEX_APP_PASSWORD + "@" + process.env.REACT_APP_ENABLEX_APP_PORTAL ) );
          } );
        } else
        {
          if ( flag )
          {
            setModule( 'session_video_error' );
            setVisible( true );
          }
        }
      } )() );
    } else
    {
      if ( flag )
      {
        setModule( 'session_video_error' );
        setVisible( true );
      }
    }
  }, [ archive ] );

  useEffect( () =>
  {
    if ( videoUrl.length > 0 )
    {
      window.open( videoUrl );
    }
  }, [ videoUrl ] );

  useEffect( () =>
  {
    if ( String( patient ).length > 0 )
    {
      Object.values( patient?.roomDetails ).map( ( item ) => ( async () =>
      {
        const result = isDateToday( item[ 0 ].scheduled_time, patient.timezone );
        console.log( result );
      } )() );
    }
  }, [ patient ] );

  function isDateToday( dateString, timeZone )
  {
    // Parse the given date string into a Date object
    const givenDate = new Date( dateString );

    // Get the current date in the specified timezone
    const today = new Date().toLocaleString( "en-US", { timeZone: timeZone } );
    const todayDate = new Date( today );

    // Extract only the date part for comparison
    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth();
    const givenDay = givenDate.getDate();

    const todayYear = todayDate.getFullYear();
    const todayMonth = todayDate.getMonth();
    const todayDay = todayDate.getDate();

    // Check if the given date matches today's date
    return ( givenYear === todayYear && givenMonth === todayMonth && givenDay === todayDay );
  }

  function isValidFutureDate( dateString )
  {
    const inputDate = new Date( dateString );
    const now = new Date();

    // Check if the date is valid
    if ( isNaN( inputDate.getTime() ) )
    {
      return false;
    }

    // Check if the input date is in the future
    return inputDate > now;
  }

  const handleSubmit = async ( roomId, pin, meetingPin ) =>
  {

    try
    {
      if ( String( roomId ).length > 0 )
      {
        navigate( `/sessionValidate/${roomId}?mpin=${pin}&mid=${meetingPin}&pid=${patientID}&role="participant"` );
      }
    } catch ( error )
    {
      toast.error( error );
    }

  };

  return (
    <>
      <Layout>
        <div className='row'>
          <div className='col-12'>
            <BrowserView>
              <div className='row'>
                <div className='col-2 '>
                  <div className={styles.sidebar}>
                    <div className='card shadow'>
                      <div className='card-header text-center bg-info text-white'>
                        <b>UID / MIR No.</b>
                      </div>
                      <div className='card-body text-center'>
                        {patient.UID + " / " + patient.MrNo}
                      </div>
                    </div>
                    {String( patient?.startDate ).length > 0
                      ? ( <div className='card mt-2 shadow'>
                        <div className='card-header text-center bg-info text-white'>
                          <b>Booking Date</b>
                        </div>

                        <div className='card-body text-center'>
                          {new Date( patient.startDate ).toLocaleDateString()}
                        </div>
                      </div> )
                      : ( <></> )}

                    {/* <div className='card mt-2 shadow'>
                    <div className='card-header text-center bg-info text-white'>
                      <b>Status</b>
                    </div>
                    <div className='card-body text-center'>
                      {patient.status}
                    </div>
                  </div> */}
                    {String( patient?.type ).length > 0
                      ? ( <div className='card mt-2 shadow'>
                        <div className='card-header text-center bg-info text-white'>
                          <b>Type</b>
                        </div>
                        <div className='card-body text-center'>
                          {String( patient.type ).toUpperCase()}
                        </div>
                      </div> )
                      : ( <></> )}

                    {String( patient.sessionDone ).length > 0 ? ( <div className='card mt-2 shadow'>
                      <div className='card-header text-center bg-info text-white'>
                        <b>Sessions Done</b>
                      </div>
                      <div className='card-body text-center'>
                        {patient.sessionDone + " / " + patient.duration}
                      </div>
                    </div> ) : ( <></> )}

                    <div className='card mt-2 shadow'>
                      <div className='card-header text-center bg-info text-white'>
                        <b>Category</b>
                      </div>
                      <div className='card-body text-center'>
                        {String( patient?.category ).toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-8'>
                  <div className='row'>
                    <div className='col-1'>
                      {( () =>
                      {
                        if ( patient.gender === 'male' )
                        {
                          return (
                            <FcBusinessman size={100} />
                          );
                        }
                        else if ( patient.gender === 'female' )
                        {
                          return (
                            <FcBusinesswoman size={100} />
                          );
                        }
                      } )()}
                    </div>
                    <div className='col-9 mx-5'>
                      <div className='flex row'>
                        <div className='col-6'>
                          <h2>{String( patient?.name ).toUpperCase()}</h2>
                          <span>{String( patient?.city ).toUpperCase() + " / " + String( patient?.country ).toUpperCase()}</span>
                          <br />
                          <span>Age : {patient?.age}</span>
                        </div>
                        <div className='col-6 float-right'>
                          <div className='col-3 mt-3 float-end'>
                            {( () =>
                            {
                              if ( String( patient ).length > 0 )
                              {
                                return (
                                  Object.values( patient?.roomDetails ).map( ( item ) => ( () =>
                                  {
                                    if ( item[ 0 ].room_status !== "ended" && isValidFutureDate( item[ 0 ].scheduled_time ) )
                                    {
                                      return (
                                        <button
                                          className='btn btn-success text-white btn-sm '
                                          onClick={() => { handleSubmit( item[ 0 ].roomID, item[ 0 ].roomPin, item[ 0 ].room_meeting_id ); }}
                                        >
                                          Video Session Scheduled
                                        </button>
                                      );
                                    }
                                  } )()
                                  )
                                );
                              }
                            } )()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-2 '>
                    <div className='col-6'>
                      <div className='card shadow ' style={{ overflow: 'hidden', minHeight: '150px' }} >
                        <div className='card-header bg-info text-white'>
                          <h5><b>Hospital Details</b></h5>
                        </div>
                        <div className='mx-2 mt-2'>
                          <div className='row d-block'>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-3'>
                                  <b>Hospital</b>
                                </div>
                                <div className='col-1'>
                                  :
                                </div>
                                <div className='col-8'>
                                  {String( patient?.hospitalName ).toLocaleUpperCase()}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row d-block'>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-3'>
                                  <b>Therapist</b>
                                </div>
                                <div className='col-1'>
                                  :
                                </div>
                                <div className='col-8'>
                                  {/* {String( patient?.hospitalName ).toLocaleUpperCase()} */}
                                  {( patient?.therapistArray ) && String( JSON.parse( patient?.therapistArray )[ 0 ][ 'label' ] ).toUpperCase()}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className='row d-block'>
                          <div className='col-12'>
                            <div className='row'>
                              <div className='col-3'>
                                <b>Category</b>
                              </div>
                              <div className='col-1'>
                                :
                              </div>
                              <div className='col-8'>
                                {String( patient?.category ).toUpperCase()}
                              </div>
                            </div>
                          </div>
                        </div> */}
                          <div className='row d-block'>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-3'>
                                  <b>Status</b>
                                </div>
                                <div className='col-1'>
                                  :
                                </div>
                                <div className='col-8'>
                                  {String( patient?.status ).toUpperCase()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='card shadow'>
                        <div className='card-header bg-info text-white'>
                          <h5><b>Patient's Initial Condition</b></h5>
                        </div>
                        <div className='text-center mt-1' style={{ overflow: 'hidden', minHeight: '100px' }}>
                          {patient?.condition}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {JSON.stringify( patient )} */}
                  <div className='card mt-5 shadow'>
                    <Tabs
                      defaultActiveKey="1"
                      type="card"
                      items={arr.map( ( item, i ) =>
                      {
                        const id = String( i + 1 );
                        return {
                          label: Object.keys( item ),
                          key: id,
                          children: Object.values( Object.values( item ).map( ( x, k ) => (
                            <div key={k}>
                              {Object.values( x ).map( ( child, c ) => (
                                <div key={c}>
                                  <Accordion defaultActiveKey={c} >
                                    <Accordion.Item eventKey={c} >
                                      <Accordion.Header>
                                        {Object.keys( item )[ 0 ] + " : " + Number( c + 1 )}
                                      </Accordion.Header>
                                      {/* {JSON.stringify( child )} */}
                                      <div className='p-2'>
                                        {( () =>
                                        {
                                          if ( Object.keys( item )[ 0 ] === 'Packages' )
                                          {
                                            return (
                                              <div className='row'>
                                                <div className='col-12'>
                                                  <div className='row'>
                                                    <div className='col-6'>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Package Name</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {child?.PackageName}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Duration</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {child?.duration} days
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='col-6'>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Package Description</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {child?.PackageDesc}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Date</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {new Date( child?.date ).toLocaleDateString()}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {child?.amountAfterDiscountRaw ? (
                                                      <div className='col-6'>
                                                        <div className='row d-block'>
                                                          <div className='col-12'>
                                                            <div className='row'>
                                                              <div className='col-4'>
                                                                <b>Package Amount</b>
                                                              </div>
                                                              <div className='col-1'>
                                                                :
                                                              </div>
                                                              <div className='col-7'>
                                                                {child?.amountAfterDiscountRaw}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : ( <></> )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          if ( Object.keys( item )[ 0 ] === 'Session History' )
                                          {
                                            return (
                                              <div className='row'>
                                                <div className='col-12'>
                                                  <div className='row'>
                                                    <div className='col-6'>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Date</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {new Date( child?.date ).toLocaleDateString()}                                                          </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Session Note</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {child?.sessionTextArea}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Session Time Elapsed</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {child?.time}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Patient's Progress</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {child?.patientProgress}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                    </div>
                                                    <div className='col-6'>
                                                      {child?.images.length > 0 && <div className='row mt-1'>
                                                        <div className='col-12'>
                                                          <div className='card'>
                                                            <div className='card-header text-center'>
                                                              Uploaded Documents
                                                            </div>
                                                            <div className='card-body'>
                                                              <div className=''>
                                                                {Object.values( child?.images ).map( ( item, q ) => (
                                                                  <div key={q}>
                                                                    <div className='btn btn-warning mx-1' onClick={() =>
                                                                    {
                                                                      setVisible( true );
                                                                      setPath( item );
                                                                      setModule( "image" );
                                                                    }} key={q}>
                                                                      {"image" + Number( q + 1 )}
                                                                    </div>
                                                                  </div>
                                                                ) )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>}
                                                      {/* Download Session Video */}
                                                      {child?.roomId ? (
                                                        // <div className='row d-block'>
                                                        //   <div className='col-12'>
                                                        //     <div className='row'>
                                                        //       {/* <div className='col-4'>
                                                        //       <b>Session Video</b>
                                                        //     </div> */}
                                                        //       {/* <div className='col-1'>
                                                        //       :
                                                        //     </div> */}
                                                        //       <div className='col-7'>
                                                        //         <button
                                                        //           className='btn btn-success btn-sm'
                                                        //           onClick={() =>
                                                        //           {
                                                        //             setFlag( true );
                                                        //             handleVideo( child?.roomId );
                                                        //           }
                                                        //           }
                                                        //         >
                                                        //           Download Session Video
                                                        //         </button>
                                                        //       </div>
                                                        //     </div>
                                                        //   </div>
                                                        // </div>
                                                        <></>
                                                      ) : ( <></> )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          if ( Object.keys( item )[ 0 ] === 'Payment' )
                                          {
                                            return (
                                              <div className='row'>
                                                <div className='col-6'>
                                                  <div className='row d-block'>
                                                    <div className='col-12'>
                                                      <div className='row'>
                                                        <div className='col-4'>
                                                          <b>Date</b>
                                                        </div>
                                                        <div className='col-1'>
                                                          :
                                                        </div>
                                                        <div className='col-7'>
                                                          {new Date( child?.date ).toLocaleDateString()}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='row d-block'>
                                                    <div className='col-12'>
                                                      <div className='row'>
                                                        <div className='col-4'>
                                                          <b>Total Amount </b>
                                                        </div>
                                                        <div className='col-1'>
                                                          :
                                                        </div>
                                                        <div className='col-7'>
                                                          {( () =>
                                                          {
                                                            if ( child.status === 'package-assigned' )
                                                            {
                                                              return (
                                                                child?.amountAfterDiscount
                                                              );
                                                            }
                                                            else if ( child.status === 'refund' )
                                                            {
                                                              return ( child.totalAmount - child.discount );
                                                            }
                                                            else if ( child.status === 'partial-payment' )
                                                            {
                                                              return ( child.amountAfterDiscount );
                                                            }
                                                          } )()}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='row d-block'>
                                                    <div className='col-12'>
                                                      <div className='row'>
                                                        <div className='col-4'>
                                                          <b>Payment</b>
                                                        </div>
                                                        <div className='col-1'>
                                                          :
                                                        </div>
                                                        <div className='col-7'>
                                                          {( () =>
                                                          {
                                                            if ( patient.type === 'opd' )
                                                            {
                                                              return (
                                                                () =>
                                                                {
                                                                  if ( child.status === 'package-assigned' )
                                                                  {
                                                                    return ( child.current );
                                                                  }
                                                                  else if ( child.status === 'refund' )
                                                                  {
                                                                    return ( child.refundAmount );
                                                                  }
                                                                  else if ( child.status === 'partial-payment' )
                                                                  {
                                                                    return ( child.current );
                                                                  }
                                                                }
                                                              )();
                                                            }
                                                          } )()}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='row d-block'>
                                                    <div className='col-12'>
                                                      <div className='row'>
                                                        <div className='col-4'>
                                                          <b>Status</b>
                                                        </div>
                                                        <div className='col-1'>
                                                          :
                                                        </div>
                                                        <div className='col-7'>
                                                          {String( child?.status ).toUpperCase().replace( '-', ' ' )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='col-4'>

                                                </div>
                                                <div className='col-2'>
                                                  <div className='btn btn-primary float-end' onClick={() =>
                                                  {
                                                    invoiceGenerator( child );
                                                    sePatentType( patient.type );
                                                  }}>
                                                    Invoice
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        } )()}
                                      </div>
                                    </Accordion.Item>
                                  </Accordion>
                                  {/* <Accordion defaultActiveKey={c} >
                                  <Accordion.Item eventKey={c} >
                                    <Accordion.Header>
                                      {Object.keys( item )[ 0 ] + " : " + Number( c + 1 )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {( () =>
                                      {
                                        if ( Object.keys( item )[ 0 ] === 'Packages' )
                                        {
                                          return (
                                            <div className='row'>
                                              <div className='col-12'>
                                                <div className='row'>
                                                  <div className='col-6'>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Package Name</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {child?.PackageName}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Duration</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {child?.duration} days
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='col-6'>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Package Description</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {child?.PackageDesc}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Date</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {new Date( child?.date ).toLocaleDateString()}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {child?.amountAfterDiscountRaw ? (
                                                    <div className='col-6'>
                                                      <div className='row d-block'>
                                                        <div className='col-12'>
                                                          <div className='row'>
                                                            <div className='col-4'>
                                                              <b>Package Amount</b>
                                                            </div>
                                                            <div className='col-1'>
                                                              :
                                                            </div>
                                                            <div className='col-7'>
                                                              {child?.amountAfterDiscountRaw}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : ( <></> )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if ( Object.keys( item )[ 0 ] === 'Session History' )
                                        {
                                          return (
                                            <div className='row'>
                                              <div className='col-12'>
                                                <div className='row'>
                                                  <div className='col-6'>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Date</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {new Date( child?.date ).toLocaleDateString()}                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Session Note</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {child?.sessionTextArea}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Session Time Elapsed</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {child?.time}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='row d-block'>
                                                      <div className='col-12'>
                                                        <div className='row'>
                                                          <div className='col-4'>
                                                            <b>Patient's Progress</b>
                                                          </div>
                                                          <div className='col-1'>
                                                            :
                                                          </div>
                                                          <div className='col-7'>
                                                            {child?.patientProgress}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </div>
                                                  <div className='col-6'>

                                                    {child?.images.length > 0 && <div className='row mt-1'>
                                                      <div className='col-12'>
                                                        <div className='card'>
                                                          <div className='card-header text-center'>
                                                            Uploaded Documents
                                                          </div>
                                                          <div className='card-body'>
                                                            <div className=''>
                                                              {Object.values( child?.images ).map( ( item, q ) => (
                                                                <div key={q}>
                                                                  <div className='btn btn-warning mx-1' onClick={() =>
                                                                  {
                                                                    setVisible( true );
                                                                    setPath( item );
                                                                    setModule( "image" );
                                                                  }} key={q}>
                                                                    {"image" + Number( q + 1 )}
                                                                  </div>
                                                                </div>
                                                              ) )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if ( Object.keys( item )[ 0 ] === 'Payment' )
                                        {
                                          return (
                                            <div className='row'>
                                              <div className='col-6'>
                                                <div className='row d-block'>
                                                  <div className='col-12'>
                                                    <div className='row'>
                                                      <div className='col-4'>
                                                        <b>Date</b>
                                                      </div>
                                                      <div className='col-1'>
                                                        :
                                                      </div>
                                                      <div className='col-7'>
                                                        {new Date( child?.date ).toLocaleDateString()}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='row d-block'>
                                                  <div className='col-12'>
                                                    <div className='row'>
                                                      <div className='col-4'>
                                                        <b>Total Amount </b>
                                                      </div>
                                                      <div className='col-1'>
                                                        :
                                                      </div>
                                                      <div className='col-7'>
                                                        {( () =>
                                                        {
                                                          if ( child.status === 'package-assigned' )
                                                          {
                                                            return (
                                                              child?.amountAfterDiscount
                                                            );
                                                          }
                                                          else if ( child.status === 'refund' )
                                                          {
                                                            return ( child.totalAmount - child.discount );
                                                          }
                                                          else if ( child.status === 'partial-payment' )
                                                          {
                                                            return ( child.amountAfterDiscount );
                                                          }
                                                        } )()}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='row d-block'>
                                                  <div className='col-12'>
                                                    <div className='row'>
                                                      <div className='col-4'>
                                                        <b>Payment</b>
                                                      </div>
                                                      <div className='col-1'>
                                                        :
                                                      </div>
                                                      <div className='col-7'>
                                                        {( () =>
                                                        {
                                                          if ( patient.type === 'opd' )
                                                          {
                                                            return (
                                                              () =>
                                                              {
                                                                if ( child.status === 'package-assigned' )
                                                                {
                                                                  return ( child.current );
                                                                }
                                                                else if ( child.status === 'refund' )
                                                                {
                                                                  return ( child.refundAmount );
                                                                }
                                                                else if ( child.status === 'partial-payment' )
                                                                {
                                                                  return ( child.current );
                                                                }
                                                              }
                                                            )();
                                                          }
                                                        } )()}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='row d-block'>
                                                  <div className='col-12'>
                                                    <div className='row'>
                                                      <div className='col-4'>
                                                        <b>Status</b>
                                                      </div>
                                                      <div className='col-1'>
                                                        :
                                                      </div>
                                                      <div className='col-7'>
                                                        {String( child?.status ).toUpperCase().replace( '-', ' ' )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='col-4'>

                                              </div>
                                              <div className='col-2'>
                                                <div className='btn btn-primary float-end' onClick={() =>
                                                {
                                                  invoiceGenerator( child );
                                                  sePatentType( patient.type );
                                                }}>
                                                  Invoice
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      } )()}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion> */}
                                </div>
                              ) )}
                            </div>
                          ) ) ),
                        };
                      } )}
                    />
                  </div>
                </div>
                <div className='col-2'>
                  {patient?.type === 'opd' ? (
                    <div className={styles.sidebar}>
                      <div className='card shadow'>
                        <div className='card-header text-center bg-info text-white'>
                          <b>Total Amount</b>
                        </div>
                        <div className='card-body text-center'>
                          {patient.finalAmount}
                        </div>
                      </div>
                      <div className='card mt-2 shadow'>
                        <div className='card-header text-center bg-info text-white'>
                          <b>Discount</b>
                        </div>
                        <div className='card-body text-center'>
                          {patient.discount}
                        </div>
                      </div>
                      <div className='card mt-2 shadow'>
                        <div className='card-header text-center bg-info text-white'>
                          <b>Amount After Discount</b>
                        </div>
                        <div className='card-body text-center'>
                          {patient.amountAfterDiscount}
                        </div>
                      </div>
                      <div className='card mt-2 shadow'>
                        <div className='card-header text-center bg-info text-white'>
                          <b>Advance</b>
                        </div>
                        <div className='card-body text-center'>
                          {patient.advanceAmount}
                        </div>
                      </div>
                      <div className='card mt-2 shadow'>
                        <div className='card-header text-center bg-info text-white'>
                          <b>Due</b>
                        </div>
                        <div className='card-body text-center'>
                          {patient.due}
                        </div>
                      </div>
                    </div>
                  ) : ( <></> )}
                </div>
              </div>
            </BrowserView>
          </div>
        </div>
        <Modal open={visible}
          onCancel={() =>
          {
            setVisible( false );
            setPath( "" );
            setModule( "" );
            setFlag( false );
          }}
          footer={null}
          width={( () =>
          {
            if ( module === 'invoice' )
            {
              return ( 1000 );
            }
            else
            {
              return ( 600 );
            }
          } )()}
        >
          {( () =>
          {
            if ( module === 'image' )
            {
              return (
                <ImageModal image={path} />
              );
            }
            if ( module === 'invoice' )
            {
              return (
                <Invoice
                  PatentType={PatentType}
                  patientDetail={patientDetail}
                  paymentId={paymentId}
                />
              );
            }
            if ( module === 'session_video_error' )
            {
              return (
                <SessionVideoError />
              );
            }
          } )()}
        </Modal>
      </Layout >
    </>
  );
}
