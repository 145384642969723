import React from 'react';

export default function Refund( { amountDiscounted, discount, refund, advance, setRefund, duration, handleRefund, sertRefundText } )
{
    // console.log( amountDiscounted, discount, refund );
    const handleRefundAmount = ( e ) =>
    {
        let value = e.target.value;
        if ( value > advance )
        {
            alert( "Amount Exceeds" );
        } else
        {
            setRefund( value );
        }
    };

    const handleMinus = ( e ) =>
    {
        if ( e.code === 'Minus' || e.code === "Period" )
        {
            e.preventDefault();
        }
    };
    return (
        <>
            <div className='card'>
                <div className='card-header text-center'>
                    <b>Refund Amount</b>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-6 col-xl-6'>
                            <div className='mb-2'>
                                <label><b>Total Amount</b></label>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={amountDiscounted}
                                />
                            </div>
                        </div>
                        <div className='col-6 col-xl-6'>
                            <div className='mb-2'>
                                <label><b>Amount Received</b></label>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={advance}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-xl-12'>
                            <div className='mb-2'>
                                <label><b>Reason for Amount Refund </b><sup style={{ color: 'red' }}>*</sup></label>
                                <textarea
                                    className='form-control'
                                    placeholder='Please Enter Reason for Amount Refund'
                                    onChange={( e ) => sertRefundText( e.target.value )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 col-xl-6'>
                            <div className='mb-2'>
                                <label><b>Amount to be Refunded <sup style={{ color: 'red' }}>*</sup></b></label>
                                <input
                                    type='number'
                                    className='form-control'
                                    placeholder='Enter Refund Amount'
                                    value={refund}
                                    onChange={handleRefundAmount}
                                    min={0}
                                    onKeyDown={handleMinus}
                                />
                            </div>
                        </div>
                        <div className='col-6 col-xl-6'>
                            <div className='mb-2 float-end p-4'>
                                <label></label>
                                <button
                                    className='btn btn-success'
                                    onClick={handleRefund}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
