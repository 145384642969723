import React, { useEffect, useState } from 'react';

export default function PackageDetail( { packageData } )
{
    const [ time, setTime ] = useState( 0 );
    const [ running, setRunning ] = useState( false );
    useEffect( () =>
    {
        let interval;
        if ( running )
        {
            interval = setInterval( () =>
            {
                setTime( ( prevTime ) => prevTime + 10 );
            }, 10 );
        } else if ( !running )
        {
            clearInterval( interval );
        }
        return () => clearInterval( interval );
    }, [ running ] );
    return (
        <>
            <div className='overflow-auto' style={{ maxHeight: '600px' }}>
                {Object.values( packageData ).map( ( item, i ) => (
                    <div className='card' key={i}>
                        <div className='card-header bg-secondary text-light'>
                            <div className='text-center'>
                                <b>Date : </b>{new Date( item[ 0 ].date ).toDateString()}
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 col-xl-12'>
                                            <b>Package Name : </b>{item[ 0 ].PackageName}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-xl-12'>
                                            <b>Package Description : </b>{item[ 0 ].PackageDesc}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12 col-xl-12'>
                                            <b>Duration : </b>{item[ 0 ].duration} ( days )
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) )}
            </div>

            {/* <div className="stopwatch">
                <div className="numbers">
                    <span>{( "0" + Math.floor( ( time / 60000 ) % 60 ) ).slice( -2 )}:</span>
                    <span>{( "0" + Math.floor( ( time / 1000 ) % 60 ) ).slice( -2 )}:</span>
                    <span>{( "0" + ( ( time / 10 ) % 100 ) ).slice( -2 )}</span>
                </div>
                <div className="buttons">
                    <button onClick={() => setRunning( true )}>Start</button>
                    <button onClick={() => setRunning( false )}>Stop</button>
                    <button onClick={() => setTime( 0 )}>Reset</button>
                </div>
            </div> */}
        </>
    );
}
