import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Modal } from 'antd';
import AddPackage from './AddPackage';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MyPagination } from '../../component/pagination/MyPagination';
import DeletePackage from './DeletePackage';
// import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';


export default function CreatePackage()
{
    const [ packages, setPackages ] = useState( "" );
    const [ page, setPage ] = useState( 1 );
    const [ pageCount, setPageCount ] = useState( 0 );
    const [ selected, setSelected ] = useState();
    const [ query, setQuery ] = useState( '' );
    const [ visible, setVisible ] = useState( false );
    const [ type, setType ] = useState( '' );
    const [ hospitals, setHospitals ] = useState( [] );
    const [ name, setName ] = useState( "" );
    const [ updateddName, setUpdateddName ] = useState( "" );
    const [ duration, setDuration ] = useState( "" );
    const [ updatedDuration, setUpdatedDuration ] = useState( "" );
    const [ price, setPrice ] = useState( "" );
    const [ updatedPrice, setUpdatedPrice ] = useState( "" );
    const [ desc, setDesc ] = useState( "" );
    const [ updatedDesc, setUpdatedDesc ] = useState( "" );
    const [ updatedHospital, setUpdatedHospital ] = useState( [] );
    const [ selectedOption, setSelectedOption ] = useState( [] );
    const [ hospitalArray, setHospitalArray ] = useState( '' );
    const [ packageName, setPackaageName ] = useState( '' );
    const navigate = useNavigate();
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;


    /* converting hospitals to selected option type */
    const HospitalOptions = ( hospital ) =>
    {
        const options = [];
        Object.values( hospital ).map( element => (
            options.push( { value: element._id, label: element.name } )
        ) );
        setHospitalArray( options );
    };

    /* getting hospital list */
    const getHospitalRecords = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/hospital/get-all-hospital-list-unpaged` );
            if ( res?.data?.success )
            {
                setHospitals( res.data.hospitals );
                HospitalOptions( res.data.hospitals );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };
    useEffect( () =>
    {
        getHospitalRecords();
    }, [] );

    /* search result handler */
    const handleKeyUp = ( e ) =>
    {
        setQuery( e.target.value );
        if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); getAllPackages();
    };

    /* save package from handlesubmit */
    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();
        /* api request to save package */
        try
        {
            const stringHospital = JSON.stringify( selectedOption );
            /* getting hospital id from select box array  */
            const hospitalsNew = [];
            Object.values( selectedOption ).map( c => (
                hospitalsNew.push( c.value )
            ) );
            const res = await axios.post( `${server}/api/medi/package/create-package`, { name, duration, price, desc, stringHospital, hospitalsNew } );
            if ( res.data.success )
            {
                toast.success( res.data.message );
                getHospitalRecords();
                getAllPackages();
                setName( '' );
                setDuration( '' );
                setPrice( '' );
                setDesc( '' );
                setSelectedOption( '' );
                setVisible( false );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong" );
        }
    };

    /* handle Pagination page change */
    const handlePageChange = ( page ) =>
    {
        setPage( page );
    };

    /* function to get list of all users */
    const getAllPackages = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/package/getall-package?page=${page}&q=${query}` );
            setPackages( res.data.packages );
            setPageCount( res.data.pagination.pageCount );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something went wrong" );
        }
    };

    useEffect( () =>
    {
        getAllPackages();
    }, [ page ] );

    /* package edit for handle edit button */
    const handleEdit = async ( e ) =>
    {
        e.preventDefault();
        // console.log( updatedHospital );
        /* convert to string for select box values */
        const updatedStringHospital = JSON.stringify( updatedHospital );
        /* getting hospital id from select box array  */
        const updatedHospitalsNew = [];
        Object.values( updatedHospital ).map( c => (
            updatedHospitalsNew.push( c.value )
        ) );
        // console.log( updatedHospitalsNew );

        try
        {
            /* sending axios request to server to update data to packagemodal  */
            const res = await axios.put( `${server}/api/medi/package/edit-package/${selected}`,
                { updateddName, updatedDuration, updatedPrice, updatedDesc, updatedStringHospital, updatedHospitalsNew, selected } );
            if ( res.data.success )
            {
                toast.success( res.data.success );
                setVisible( false );
                getAllPackages();
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };

    /* handle delete package */
    const handleDelete = async ( id ) =>
    {
        try
        {
            const res = await axios.delete( `${server}/api/medi/package/delete-package/${id}` );
            if ( res.data.success )
            {
                toast.success( res.data.success );
                setVisible( false );
                getAllPackages();
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }

    };

    return (
        <Layout>
            <div className='row'>
                <div className='col-xl-2'></div>
                <div className='col-12 col-xl-8'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-transform-uppercase'>
                                <FontAwesomeIcon icon={faBoxOpen} /> &nbsp; Package Master
                            </h2>
                            <hr />
                        </div>
                    </div>
                    <Card>
                        <Card.Header>
                            <div className='row'>
                                <div className='col-12 col-xl-8'>
                                    {/* <FontAwesomeIcon icon={faBoxOpen} />  */}
                                    <b> Manage Package</b>
                                </div>
                                <div className='col-12 col-xl-4 float-end'>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-7'>
                                                <div className='input-group'>
                                                    <input type='text' placeholder='Search by name' minLength={3} className='form-control'
                                                        onKeyUp={( e ) => handleKeyUp( e )}></input>
                                                    <button className="btn btn-dark" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <button className='btn btn-success btn-sm' onClick={() =>
                                                {
                                                    setVisible( true );
                                                    setType( 'new' );
                                                }}>
                                                    Add Package
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Package Name</th>
                                        <th>Package Duration</th>
                                        <th>Price</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values( packages ).map( ( p ) => (
                                        <tr key={p._id}>
                                            <td>{p.name}</td>
                                            <td>{p.duration}</td>
                                            <td>{p.price}</td>
                                            <td>{p.desc}</td>
                                            <td>
                                                <div className='row'>
                                                    <div className='col-auto mt-2'>
                                                        <button className='btn btn-primary ms-2 btn-sm'
                                                            onClick={() =>
                                                            {
                                                                setVisible( true );
                                                                setType( 'edit' );
                                                                setUpdateddName( p.name );
                                                                setUpdatedDuration( p.duration );
                                                                setUpdatedPrice( p.price );
                                                                setUpdatedDesc( p.desc );
                                                                setSelected( p._id );
                                                                setUpdatedHospital( JSON.parse( p?.hospitals[ 0 ] ) );
                                                                // console.log(JSON.stringify(JSON.parse(p.hospitals[0])))
                                                                setSelected( p._id );
                                                            }}>
                                                            <FontAwesomeIcon icon={faBoxOpen} />
                                                        </button>
                                                    </div>
                                                    <div className='col-auto mt-1'>
                                                        <button className='btn btn-danger ms-1 btn-sm' onClick={() =>
                                                        {
                                                            setType( 'delete' );
                                                            setSelected( p._id );
                                                            setPackaageName( p.name );
                                                            setVisible( true );
                                                            // handleDelete( p._id );
                                                        }}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ) )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className='float-end mt-2' >
                {pageCount > 1 && (
                    <MyPagination
                        total={pageCount}
                        current={page}
                        onChangePage={handlePageChange}
                    />
                )}
            </div>
            <Modal onCancel={() =>
            {
                setVisible( false );
                setSelected( "" );
            }}
                open={visible} footer={null}>
                {( () =>
                {
                    if ( type === 'new' )
                    {
                        return (
                            <AddPackage
                                hospital={hospitals} setHospital={setHospitals}
                                name={name} setName={setName}
                                desc={desc} setDesc={setDesc}
                                price={price} setPrice={setPrice}
                                duration={duration} setDuration={setDuration}
                                selectedOption={selectedOption} setSelectedOption={setSelectedOption}
                                handleSubmit={handleSubmit} type={type}
                                hospitalArray={hospitalArray}
                            />
                        );
                    }
                    if ( type === 'edit' )
                    {
                        return (
                            <AddPackage
                                hospital={hospitals} setHospital={setHospitals}
                                name={updateddName} setName={setUpdateddName}
                                desc={updatedDesc} setDesc={setUpdatedDesc}
                                price={updatedPrice} setPrice={setUpdatedPrice}
                                duration={updatedDuration} setDuration={setUpdatedDuration}
                                selectedOption={updatedHospital} setSelectedOption={setUpdatedHospital}
                                handleSubmit={handleEdit}
                                type={type}
                                hospitalArray={hospitalArray}
                            />
                        );
                    }
                    if ( type === 'delete' )
                    {
                        return (
                            <DeletePackage
                                selected={selected} handleDelete={handleDelete}
                                packageName={packageName}
                            />
                        );
                    }
                } )()}

            </Modal>
        </Layout>
    );
}
