import React from 'react';

export default function ( {
    hospitalName,
    selected,
    handleDelete } )
{

    return (
        <div className='card'>
            <div className='card-header text-center'>
                Delete hospital
            </div>
            <div className='card-body text-center'>
                {hospitalName}
            </div>
            <div className='card-footer'>
                <button className='btn btn-success btn-sm float-end' onClick={() =>
                {
                    handleDelete( selected );
                }}>
                    Confirm
                </button>
            </div>
        </div>
    );
}
