import React from 'react';

export default function PackagePatients( { packages } )
{
    console.log( packages );
    return (
        <div>{Object.values( packages ).map( ( item ) => (
            <div className='card'>
                <div className='card-header text-center'>
                    {new Date( item[ 0 ].date ).toLocaleDateString()}
                </div>
                <div className='card-body'>
                    <b>Package Name : </b>
                    {item[ 0 ].PackageName}
                    <br />
                    <b>Package Description : </b>
                    {item[ 0 ].PackageDesc}
                    <br />
                    <b>Package Duration : </b>
                    {item[ 0 ].duration}
                    <br />
                    <b>Package Price : </b>
                    {item[ 0 ].amountAfterDiscountRaw}
                </div>
            </div>
        ) )}</div>
    );
}
