import React from 'react';

export default function PaymentHistory( { paymentHistory, handleClick } )
{
    // console.log(paymentHistory)
    return (
        <div className='overflow-auto' style={{ maxHeight: '600px' }}>

            {Object.values( paymentHistory ).map( ( item, i ) => (
                <div key={i} className='card m-2'>
                    <div className="card-header bg-secondary"
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{ color: 'white' }}>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 col-xl-5'>
                                            {/* {"# "+item[0].id} */}
                                        </div>
                                        <div className='col-12 col-xl-4 text-center'>
                                            {new Date( item[ 0 ].date ).toDateString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='card-body'>
                        <div className='row'>

                            <div className='col-12 col-xl-6'>

                                {/* <div>
                                    {item[0].date !== undefined ? (<div><b>Payment Date : </b>{ new Date(item[0].date).toDateString()}</div>) : (<></>)}
                                </div> */}
                                <div>
                                    {item[ 0 ].addedBy !== undefined ? ( <div><b>Accepted By : </b>{( item[ 0 ].addedBy )}</div> ) : ( <></> )}
                                </div>
                                <div>
                                    {item[ 0 ].current !== undefined ? ( <div><b>Amount : </b>{( item[ 0 ].current ) + " "}{item[ 0 ]?.currency !== undefined ? ( item[ 0 ]?.currency ) : ( "INR" )}</div> ) : ( <></> )}
                                </div>
                                <div>
                                    {item[ 0 ].PackageName !== undefined ? ( <div><b>Package Name : </b>{( item[ 0 ].PackageName )}</div> ) : ( <></> )}
                                </div>
                                <div>
                                    {item[ 0 ].status !== undefined ? ( <div><b>Status : </b>{( item[ 0 ].status )}</div> ) : ( <></> )}
                                </div>

                            </div>
                            <div className='col-12 col-xl-6 float-end'>
                                <div className='col-6 my-2 float-end mt-2'>
                                    <button
                                        className='bg-primary form-control text-light mt-2 btn-sm'
                                        onClick={( () => { handleClick( item ); } )}
                                    // onClick={(() => { console.log(JSON.stringify(item)); console.log(JSON.stringify(i)) })}
                                    >
                                        Invoice
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) )}
        </div>
    );
}
