import React from 'react';
import Select from 'react-select';


export default function Register( { handleSubmit, name, setName, email, setEmail, password, setPassword, role, setRole, type, roleOptions, HospitalArray, selectedHospital, setSelectedHospital } )
{
    return (
        <>
            <div className='card'>
                <div className='card-header bg-success text-light'>
                    <div className='text-center'>
                        {type === 'update' ? ( <b>Edit User</b> ) : ( <b>Add User</b> )}
                    </div>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit}>
                        {/* name */}
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Name<sup style={{color:'red'}}>*</sup></label>
                            <input type="text" className="form-control" id="name" name='name'
                                onChange={( e ) => setName( e.target.value )} value={name}
                                placeholder='Enter Name' />
                        </div>
                        {/* email */}
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email address<sup style={{color:'red'}}>*</sup></label>
                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                onChange={( e ) => setEmail( e.target.value )} value={email}
                                placeholder='Enter Email' />
                        </div>
                        {/* password */}
                        {type !== 'update' ? ( <>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password<sup style={{color:'red'}}>*</sup></label>
                                <input type="password" className="form-control" id="exampleInputPassword1"
                                    onChange={( e ) => setPassword( e.target.value )} value={password}
                                    placeholder='Enter Password' />
                            </div>
                        </> ) : ( <></> )}

                        {/* role */}
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Role<sup style={{color:'red'}}>*</sup></label>
                            <Select
                                value={role}
                                options={roleOptions}
                                onChange={setRole}
                            />
                            {/* <div className="form-floating">
                                <select className="form-control"
                                    onChange={( e ) => setRole( e.target.value )} value={role}>
                                    <option value={""}>Select Role</option>
                                    <option value={1} >Admin</option>
                                    <option value={2}>Manager</option>
                                    <option value={3}>Therapist</option>
                                </select>
                            </div> */}
                        </div>

                        {( role.label === "Therapist" ) || ( role.label === "Manager" ) || ( role.label === "Hospital" ) ? (
                            <div className='mb-3'>
                                <label htmlFor="exampleInputPassword1" className="form-label">Hospital<sup style={{color:'red'}}>*</sup></label>
                                <Select
                                    isMulti
                                    options={HospitalArray}
                                    onChange={setSelectedHospital}
                                    value={selectedHospital}
                                />
                            </div>
                        ) : ( <></> )}

                        <div className="mb-3 text-center">
                            <button type='submit' className="btn btn-primary col-12 mt-4">Submit</button>
                        </div>
                    </form>
                </div>
            </div >
        </>
    );
}
