import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { MyPagination } from '../../component/pagination/MyPagination.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen, faTrash, faDollarSign, faHospital } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import { Modal } from "antd";
import PriceUpdate from './PriceUpdate';
import DeleteHsopital from './DeleteHsopital';

export default function CreateHospital()
{

    const [ query, setQuery ] = useState( '' );
    const [ page, setPage ] = useState( 1 );
    const [ pageCount, setPageCount ] = useState( 0 );
    const [ hospitals, setHospitals ] = useState( [] );
    const [ visible, setVisible ] = useState( false );
    const [ selected, setSelected ] = useState( '' );
    const [ type, setType ] = useState( '' );
    const [ hospitalName, setHospitalName ] = useState( '' );
    const navigate = useNavigate();
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;


    /* search result handler */
    const handleKeyUp = ( e ) =>
    {
        setQuery( e.target.value );
        if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); getHospitalRecords();
    };

    /* add Hospital */
    const handleAddHospital = async () =>
    {
        try
        {
            navigate( "/dashboard/admin/add-hospital" );
            getHospitalRecords();
        } catch ( error )
        {
            toast.error( error );
        }
    };

    /* getting hospital list */
    const getHospitalRecords = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/hospital/get-all-hospital-list?page=${page}&q=${query}` );
            if ( res?.data?.success )
            {
                setHospitals( res.data.hospitals );
                setPageCount( res.data.pagination.pageCount );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };

    /* call hospital list */
    useEffect( () =>
    {
        getHospitalRecords();
    }, [ page ] );

    /* handle Pagination page change */
    const handlePageChange = ( page ) =>
    {
        setPage( page );
    };

    /* Delete Hospital */
    const handleDelete = async ( id ) =>
    {
        try
        {
            const { data } = await axios.delete( `${server}/api/medi/hospital/delete-hospital/${id}` );
            if ( data.success )
            {
                toast.success( data.message );
                setVisible( false );
                getHospitalRecords();
            }
            else
            {
                toast.error( data.message );
            }
        } catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong" );
        }
    };

    /* handle hospital price */
    // const handlePrice = async ( id ) =>
    // {
    //     const { data } = await axios.post( `/api/medi/price/update-price/${id}` );
    // };

    return (
        <Layout>
            <div className='row'>
                <div className='col-xl-2'></div>
                <div className='col-12 col-xl-8'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-transform-uppercase'>
                                <FontAwesomeIcon icon={faHospital} /> &nbsp; Hospital Master
                            </h2>
                            <hr />
                        </div>
                    </div>
                    <Card>
                        <Card.Header>
                            <div className='row'>
                                <div className='col-12 col-xl-8'>
                                    {/* <FontAwesomeIcon icon={faHospital} /> */}
                                    <b> Manage Hospital</b>
                                </div>
                                <div className='col-12 col-xl-4 float-end'>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-7'>
                                                <div className='input-group'>
                                                    <input type='text' placeholder='Search by name' minLength={3} className='form-control'
                                                        onKeyUp={( e ) => handleKeyUp( e )}></input>
                                                    <button className="btn btn-dark" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <button className='btn btn-success btn-sm' onClick={handleAddHospital}>
                                                    Add Hospital
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover responsive >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Address</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values( hospitals ).map( ( c ) => (
                                        <tr key={c._id}>
                                            <td>{c.name}</td>
                                            <td>{c.city}</td>
                                            <td>{c.state}</td>
                                            <td>{c.address}</td>
                                            <td>
                                                <Link to={`/dashboard/admin/edit-hospital/${c.slug}`} key={c._id}>
                                                    <Button variant='primary' className='btn btn-sm'>
                                                        <FontAwesomeIcon icon={faUserPen} />
                                                    </Button>
                                                </Link>
                                                <button className='btn btn-danger ms-1 btn-sm' onClick={() =>
                                                {
                                                    setType( 'delete' );
                                                    setSelected( c._id );
                                                    setVisible( true );
                                                    setHospitalName( c.name );
                                                    // handleDelete( c._id );
                                                }} >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ) )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className='float-end mt-2'>
                {pageCount > 1 && (
                    <MyPagination
                        total={pageCount}
                        current={page}
                        onChangePage={handlePageChange}
                    />
                )}
            </div>
            <Modal onCancel={() =>
            {
                setVisible( false );
                setSelected( "" );
                setHospitalName( '' );
            }} open={visible} footer={null}>
                {( () =>
                {
                    if ( type === 'delete' )
                    {
                        return (
                            <DeleteHsopital
                                hospitalName={hospitalName}
                                selected={selected}
                                handleDelete={handleDelete}
                            />
                        );
                    }
                } )()}
            </Modal>
        </Layout >
    );
}
