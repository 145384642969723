import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "react-datepicker/dist/react-datepicker.css";
import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';

export default function SessionNote( {
  sessionTextArea, setSessionTextArea,
  handleSessionNote, date, setDate,
  setSelectedImage, selectedImage,
  setPatientProgress, patientProgress
} )
{
  const imgTypes = [ 'image/jpeg', 'video/mp4' ];
  const [ disable, setDisable ] = useState( false );
  const [ type, setType ] = useState( true );
  const imageref = useRef( null );

  /* image handler */
  const imagehandle = ( e ) =>
  {
    let flag = true;
    if ( e.target.files && e.target.files.length > 0 )
    {
      Object.values( e.target.files ).map( item =>
      {
        if ( item.size > 5242880 )
        {
          return (
            flag = false,
            toast.error( "file size must be less than 5 mb" )
          );
        }
        if ( !imgTypes.includes( item.type ) )
        {
          return (
            flag = false,
            imageref.current.value = "",
            imageref.current.type = "text",
            imageref.current.type = "file",
            toast.error( "file type must be of jpeg or mp4 only" )
          );
        }
      } );
      if ( flag )
      {
        setSelectedImage( e.target.files );
      }
    }
  };

  const handleSubmit = () =>
  {
    if ( sessionTextArea?.length === 0 )
    {
      toast.error( "Please write in session note" );
    }
    if ( patientProgress?.length === 0 )
    {
      toast.error( "Please Write in Patient Progress" );
    }
    if ( sessionTextArea?.length > 0 && patientProgress?.length > 0 )
    {
      handleSessionNote();
      setDisable( true );
    }
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <label><b>Clinical Note <sup style={{ color: 'red' }}>*</sup></b></label>
          <textarea
            className='form-control'
            value={sessionTextArea}
            onChange={( e ) => setSessionTextArea( e.target.value )}
            placeholder='Enter Session ending notes here'
            cols={10}
            rows={5}
          />
        </div>
      </div>
      <div className='row mt-1'>
        <div className='col-12'>
          <label><b>Patient Progress Notes <sup style={{ color: 'red' }}>*</sup></b></label>
          <textarea
            className='form-control'
            placeholder='Enter patients Progress Details here'
            onChange={( e ) => setPatientProgress( e.target.value )}
            cols={10}
            rows={5}
          />
        </div>
      </div>
      <div className='row mt-1'>
        <div className='col-12'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                'DesktopDateTimePicker'
              ]}
            >
              <DemoItem label="Next Session Date">
                <DesktopDateTimePicker
                  defaultValue={dayjs( new Date().getTime() + ( 24 * 60 * 60 * 1000 ) )}
                  minDate={dayjs( new Date() )}
                  onChange={value => setDate( value )}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className='mt-2'>
          <label><b>Upload files : </b></label>
          <input
            type='file'
            className='form-control'
            multiple
            onChange={( e ) =>
            {
              setType( true );
              imagehandle( e );
            }}
            ref={imageref}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-12'>
          <button
            className={( () =>
            {
              if ( !disable )
              {
                return ( 'btn btn-success form-control btn-sm' );
              } else
              {
                return ( 'btn btn-success form-control btn-sm disabled' );
              }
            } )()}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
