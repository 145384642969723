import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { WhatsappShare } from 'react-share-kit';


export default function RoomDetails( { roomDetails, selected } )
{

  const [ arr, setArr ] = useState( '' );
  const [ therapist, setTherapist ] = useState( "" );
  const [ patient, setPatient ] = useState( "" );

  function isSameDate( date1, date2 )
  {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  useEffect( () =>
  {
    const res = Object.values( roomDetails ).filter( ( item ) => ( isSameDate( new Date( item[ 0 ].scheduled_time ), new Date() ) ) );

    setArr( res[ res?.length - 1 ] );
    // console.log( res[ res.length - 1 ] );
  }, [] );
  console.log( arr );

  useEffect( () =>
  {
    if ( arr?.length > 0 )
    {
      setTherapist( `https://medirehab.in/sessionValidate/${arr[ 0 ].roomID}?mpin=${arr[ 0 ].roomPin}&mid=${arr[ 0 ].room_meeting_id}&pid=${selected}&role=moderator` );
      setPatient( `https://medirehab.in/sessionValidate/${arr[ 0 ].roomID}?mpin=${arr[ 0 ].roomPin}&mid=${arr[ 0 ].room_meeting_id}&pid=${selected}&role=participant` );
    }

  }, [ arr ] );

  return (
    <div>
      <div className='card'>
        <div className='card-header justify-center flex bg-green-600 text-white gap-2'>
          <div>
            <FontAwesomeIcon icon={faVideo} />
          </div>
          {"   "} :  Call Details
        </div>
        <div className='card-body'>
          {" "} Below are the links for Therapist & Patient :
          <hr className="h-1 mt-2 bg-black border-0" />
          {/* <div className='mt-2'>
            <label>
              for Therpaist:
            </label>
            <div className='flex w-full'>
              <Link to={therapist}>Therapist Link</Link>
              <WhatsappShare url={therapist} />
            </div>
          </div> */}
          <div className='grid grid-cols-2 mt-1'>
            <label className='flex justify-center'>
              Share Patient Link over WhatsApp:
            </label>
            {/* <Link to={patient}>Patient Link</Link> */}
            <div className='flex justify-center'>
              <WhatsappShare url={patient} size={30} />
            </div>
          </div>

          <div className='mt-2 border p-3'>
            {patient}
          </div>
        </div>

      </div>
    </div >
  );
}
