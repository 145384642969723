import React, { useEffect, useState } from 'react';
import Layout from '../component/layout/Layout';
import Lottie from "lottie-react";
import patient from "../style/patient_registration.json";
import { Modal, Select } from "antd";
import { uid } from 'uid';
import { Country, State, City } from 'country-state-city';
import { useAuth } from '../component/context/AuthContext';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import DiagnosticTemplate from './admin/DiagnosticTemplate';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { resetDiagnosticTypes } from '../redux/sessionReducer';
import TimezoneSelect from "react-timezone-select";


const { Option } = Select;
const id = uid();

export default function PatientRegister()
{
    const navigate = useNavigate();
    const [ indianState, setState ] = useState( '' );
    const [ city, setCity ] = useState( '' );
    const [ auth, setAuth ] = useAuth();
    const [ hospitals, setHospitals ] = useState( [] );
    const [ name, setName ] = useState( "" );
    const [ age, setAge ] = useState( "" );
    const [ hospital, setHospital ] = useState( "" );
    const [ hospitalName, setHospitalName ] = useState( "" );
    const [ gender, setGender ] = useState( "" );
    const [ condition, setCondition ] = useState( "" );
    const [ address, setAddress ] = useState( "" );
    const [ phone, setPhone ] = useState( "" );
    const [ email, setEmail ] = useState( "" );
    const [ type, setType ] = useState( "" );
    const [ category, setCategory ] = useState( "" );
    const [ therapists, setTherapists ] = useState( [] );
    const [ selectedTherapist, setSelectedTherapist ] = useState();
    const [ therapistOptions, setTherapistOptions ] = useState( [] );
    const [ userId, setUserId ] = useState( "" );
    const [ UID, SetUID ] = useState( "" );
    const [ MrNo, setMrNo ] = useState( "" );
    const [ selectedCountry, setCountry ] = useState( '' );
    const [ visible, setVisible ] = useState( false );
    const [ check, setCheck ] = useState( '' );
    const diagnostics = useSelector( state => state.session.diagnosticTypes );
    const [ diagnosticType, setDiagnosticType ] = useState( diagnostics.type );
    const [ diangnosticData, setDiagnosticData ] = useState( diagnostics.data );
    const countries = Country.getAllCountries();
    const dispatch = useDispatch();
    const [ selectedTimezone, setSelectedTimezone ] = useState( "" );

    useEffect( () =>
    {
        setTimeout( () =>
        {
            auth?.user?.HospitalArray ? setHospitals( JSON.parse( auth.user.HospitalArray ) ) : ( <></> );
            setUserId( auth?.user?.id );
        }, 2000 );
    }, [ auth ] );

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    /* patient type options */
    const options = [
        { value: 'ipd', label: 'IPD' },
        { value: 'opd', label: 'OPD' },
        // { value: 'daily', label: 'Daily' },
        // { value: 'home-visits', label: 'Home Visits' }
    ];
    /* gender options */
    const genderOptions = [
        { value: 'male', label: "Male" },
        { value: 'female', label: "Female" } ];

    /* patient category */
    const patientCategoryOptions = [
        { value: "general", label: "General" },
        { value: "insurance", label: "Insurance" },
        { value: "empaneled", label: "Empaneled" },
        { value: "home-visits", label: "Home Visits" }
    ];

    /* getting country from imported library */
    const country = Country.getCountryByCode( 'IN' );
    const countryState = State.getStatesOfCountry( country.isoCode );

    /* handle cities */
    const cities = City.getCitiesOfState( country.isoCode, indianState );

    /* get therapists of selected hospital */
    const therapist = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/user/get-all-hospital-therapist/${hospital}` );
            if ( res.data.success )
            {
                setTherapists( res.data.therapist );

            }
        } catch ( error )
        {
            toast.error( "something went wrong fetching therapists" );
        }
    };
    // useEffect( () =>
    // {
    //     if ( hospital.length > 0 )
    //     {
    //         while (therapists.length > 0)
    //         {
    //             therapists.pop();
    //         }
    //         therapist();
    //     }
    // }, [ hospital ] );

    // useEffect( () =>
    // {
    //     if ( therapists.length > 0 )
    //     {
    //         Object.values( therapists ).map( ( c ) => (
    //             therapistOptions.push( { value: c._id, label: c.name } )
    //         ) );
    //         setTherapistOptions( therapistOptions );
    //         console.log( therapistOptions );
    //     }
    // }, [ therapists ] );

    /* handleSubmit */
    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();
        try
        {
            // console.log( 'hi' );
            const res = await axios.post( `${server}/api/medi/patient/create-patient`,
                {
                    name, age, hospital, hospitalName, gender, condition, address, selectedCountry,
                    //  indianState,
                    city, phone, email, type, category, userId, UID, MrNo, diagnosticType, diangnosticData, selectedTimezone: selectedTimezone?.value
                }
            );
            if ( res.data.success )
            {
                toast.success( res.data.message );
                setName( '' );
                setAge( '' );
                setHospital( '' );
                setGender( "" );
                setCondition( "" );
                setAddress( "" );
                setState( "" );
                setCity( "" );
                setSelectedTimezone( "" );
                setPhone( "" );
                dispatch( resetDiagnosticTypes() );
                setEmail( "" );
                setType( '' );
                setCountry( '' );
                setCategory( '' );
                navigate( "/dashboard/admin/patient-master" );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };

    const handleAge = ( e ) =>
    {
        const value = e.target.value;
        if ( value > 100 )
        {
            alert( 'Age must not be more than 100' );
        } else
        {
            setAge( value );
        }
    };


    const handleChange = ( event ) =>
    {
        const value = event.target.value;
        const sanitizedValue = value.replace( /[^\w\s]/gi, '' ); // Regex to remove special characters
        setName( sanitizedValue );
    };

    // console.log( selectedCountry, indianState, city );

    return (
        <Layout>
            <div className='row overflow-hidden' >
                <div className='col-xl-2'>

                </div>
                <div className='col-12 col-xl-8'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-transform-uppercase'>
                                Patient Registration
                            </h2>
                        </div>
                    </div>
                    <hr />

                    <div className='row'>
                        <div className='col-12'>
                            <div className='card p-3'>
                                <div className='card-header bg-light border text-center'>
                                    <span><b>Patient Registration Form</b></span>
                                </div>
                                <div className='card-body'>
                                    {/* form */}
                                    <form onSubmit={handleSubmit}>
                                        <div className='row' >
                                            <div className='col-12'>

                                                <div className='row mb-3'>
                                                    {/* Patient Name */}
                                                    <div className='col-6'>
                                                        <div>
                                                            <label>Patient Name<sup style={{ color: 'red' }}> *</sup></label>
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='Enter Patient Name'
                                                                value={name}
                                                                onChange={handleChange}
                                                            // onKeyDown={( value ) => handleNameValidation( value )}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Patient Age */}
                                                    <div className='col-6'>
                                                        <div>
                                                            <label>Patient Age<sup style={{ color: 'red' }}> *</sup></label>
                                                            <input
                                                                type='number'
                                                                className='form-control'
                                                                placeholder='Enter Patient Age'
                                                                value={age}
                                                                min={0}
                                                                max={100}
                                                                onChange={handleAge}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-6 mt-2'>
                                                        {/* hospitals */}
                                                        <div>
                                                            <label>Hospital<sup style={{ color: 'red' }}> *</sup></label>
                                                            <Select
                                                                bordered={false}
                                                                options={hospitals}
                                                                className='form-control'
                                                                onChange={( e, label ) =>
                                                                {
                                                                    setHospital( e );
                                                                    setHospitalName( label.label );
                                                                }}
                                                                // value={hospital}
                                                                placeholder={"Please Select Hospital"}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Gender */}
                                                    <div className='col-6 mt-2'>
                                                        <div>
                                                            <label>Gender<sup style={{ color: 'red' }}> *</sup></label>
                                                            <Select
                                                                bordered={false}
                                                                options={genderOptions}
                                                                className='form-control'
                                                                onChange={setGender}
                                                                // value={gender}
                                                                placeholder={"Please Select Gender"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {hospitalName !== 'MediRehab' ? ( <div className='row mb-3'>
                                                    {/* uid */}
                                                    <div className='col-6 mb-3'>
                                                        <div>
                                                            <label>UID<sup style={{ color: 'red' }}> *</sup></label>
                                                            <input
                                                                type='number'
                                                                // pattern="[0-9]"
                                                                className='form-control'
                                                                placeholder='Please Enter UID No.'
                                                                onChange={( e ) => SetUID( e.target.value )}
                                                                value={UID}
                                                                min={0}
                                                            >
                                                            </input>
                                                        </div>
                                                    </div>

                                                    {/* MR No */}
                                                    <div className='col-6 mb-3'>
                                                        <div>
                                                            <label>MR No.<sup style={{ color: 'red' }}> *</sup></label>
                                                            <input
                                                                type='number'
                                                                className='form-control'
                                                                placeholder='Please Enter MR No'
                                                                onChange={( e ) => setMrNo( e.target.value )}
                                                                value={MrNo}
                                                                min={0}
                                                            >

                                                            </input>
                                                        </div>
                                                    </div>
                                                </div> ) : ( <></> )}

                                                <div className='row mb-3'>
                                                    {/* Patient’s condition */}
                                                    <div className='col-12'>
                                                        <div>
                                                            <label>Patient’s condition<sup style={{ color: 'red' }}> *</sup></label>
                                                            <TextArea
                                                                className='form-control'
                                                                placeholder='Enter patient condition'
                                                                onChange={( e ) => setCondition( e.target.value )}
                                                                value={condition}
                                                            />
                                                            {/* <input type='text' className='form-control' placeholder='Enter patient condition' /> */}
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* Patient Address */}
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1">Patient Address</label>
                                                    <textarea
                                                        className='form-control'
                                                        placeholder="Please enter Patient Address"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        id='name'
                                                        className="form-control"
                                                        onChange={( e ) => setAddress( e.target.value )}
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <label>TimeZone:<sup style={{ color: "red" }}>*</sup></label>
                                                <div className="select-wrapper">
                                                    <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
                                                </div>
                                            </div>

                                            <div className='row mb-2'>

                                                <div className='col-6'>
                                                    <div>
                                                        <label>Country<sup style={{ color: 'red' }}> *</sup></label>
                                                        <Select
                                                            bordered={false}
                                                            placeholder='Select a State' size='large' showSearch
                                                            className='form-select mb-3'
                                                            onChange={( value ) => setCountry( value )}
                                                        >
                                                            {Object.values( countries ).map( ( item, i ) => (
                                                                <Option key={i} value={item.name}>{item.name}</Option>
                                                            ) )}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Hospital City<sup style={{ color: 'red' }}> *</sup></label>
                                                            <input
                                                                className='form-control'
                                                                placeholder='Enter City Name'
                                                                onChange={( e ) => setCity( e.target.value )}
                                                            >
                                                            </input>
                                                            {/* <Select
                                                                bordered={false}
                                                                placeholder="Select a City" showSearch
                                                                className='form-select mb-3'
                                                                onChange={( value ) => setCity( value )}>
                                                                {cities.map( ( ci, l ) => (
                                                                    <Option key={l} value={ci.name}>{ci.name}</Option>
                                                                ) )}
                                                            </Select> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-3'>
                                                {/* Phone Number */}
                                                <div className='col-6'>
                                                    <div>
                                                        <label>Phone No<sup style={{ color: 'red' }}> *</sup></label>
                                                        <input
                                                            type='number'
                                                            maxLength={13}
                                                            minLength={10}
                                                            min={0}
                                                            className='form-control'
                                                            placeholder='Enter Phone Number'
                                                            onChange={( e ) => setPhone( e.target.value )}
                                                            value={phone}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Email */}
                                                <div className='col-6'>
                                                    <div>
                                                        <label>Patient Email:</label>
                                                        <input
                                                            type='email'
                                                            className='form-control'
                                                            placeholder='Enter patient email'
                                                            onChange={( e ) => setEmail( e.target.value )}
                                                            value={email}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                {/* Patient Type */}
                                                <div className='col-6'>
                                                    <div>
                                                        <label>Patient Type<sup style={{ color: 'red' }}> *</sup></label>
                                                        <Select
                                                            bordered={false}
                                                            options={options}
                                                            className='form-control'
                                                            onChange={setType}
                                                            // value={type}
                                                            placeholder={"Select Type"}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Patient Category */}
                                                <div className='col-6'>
                                                    <div>
                                                        <label>Category<sup style={{ color: 'red' }}> *</sup></label>
                                                        <Select
                                                            bordered={false}
                                                            options={patientCategoryOptions}
                                                            className='form-control'
                                                            onChange={setCategory}
                                                            // value={category}
                                                            placeholder={"Select Category"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* submit */}
                                        <div className='float-end'>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={visible}
                onCancel={() =>
                {
                    setCheck( '' );
                    setVisible( false );
                }}
                footer={null}
            >
                {<DiagnosticTemplate />}
            </Modal>
        </Layout>
    );
}
