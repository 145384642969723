import React from 'react';

export default function DeleteCapability( {
    selected,
    handleDelete,
    capaName
} )
{
    return (
        <div className='card'>
            <div className='card-header text-center'>
                Delete Capability
            </div>
            <div className='card-body text-center'>
                {capaName}
            </div>
            <div className='card-footer'>
                <button className='btn btn-success btn-sm float-end' onClick={() =>
                {
                    handleDelete( selected );
                }}>
                    Confirm
                </button>
            </div>
        </div>
    );
}
