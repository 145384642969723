import { Modal } from 'antd';
import React, { useState } from 'react';
import ImageModal from '../admin/ImageModal';
import { Link } from 'react-router-dom';

export default function Hospital_Patient_Sessions( { sesssionList, initialCond } )
{
    const [ visible, setVisible ] = useState( false );
    const [ images, setImages ] = useState( [] );
    return (
        <div className='overflow-auto' style={{ maxHeight: '500px' }}>
            <div className='card'>
                <div className='card-header bg-success text-white text-center'>
                    Patient's Initial Condition
                </div>
                <div className='card-body text-center'>
                    {initialCond}
                </div>
            </div>
            {Object.values( sesssionList ).map( item => (
                <div className='card my-1 ' key={item.id} >
                    <div className='card-header'>
                        <div className='text-center'>
                            {new Date( item.date ).toLocaleDateString()}
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-8'>

                                        <br />
                                        <b>Session Duration : </b>
                                        {item.time}
                                        <br />
                                        <b>Therapist Name : </b>
                                        {item.therapist}
                                        <br />
                                        <hr></hr>
                                        <b>Clinical Note : </b>
                                        {item.sessionTextArea}
                                        <br />
                                        <hr></hr>
                                        <b>Patient's Progress : </b>
                                        {item.patientProgress}
                                    </div>
                                    <div className='col-4'>
                                        {item.images.length > 0 ? (
                                            <div className='text-center'>
                                                Documents
                                                <br />
                                                {Object.values( item?.images ).map( ( img, i ) => (
                                                    <Link key={i}
                                                        onClick={() =>
                                                        {
                                                            setVisible( true );
                                                            setImages( img );
                                                        }}>
                                                        <button className='btn btn-warning'>
                                                            {"Doc_" + i}
                                                        </button>
                                                        <br />
                                                    </Link>
                                                ) )}
                                            </div>
                                        ) : ( <></> )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) )}
            <Modal
                open={visible}
                onCancel={() =>
                {
                    setVisible( false );
                }}
                footer={null}
            >
                <ImageModal
                    image={images}
                />
            </Modal>
        </div>
    );
}
