import React, { useEffect, useState } from 'react';
import Select from 'react-select';


export default function AddPackage( { name, setName, duration, setDuration, price, setPrice, desc, setDesc, handleSubmit, selectedOption, setSelectedOption, hospitalArray, type } )
{
    return (
        <div className='card'>
            <div className='card-header'>
                <div className='text-center'>
                    <b>{type === 'new' ? "Add Package" : "Edit Package"}</b>
                </div>
            </div>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    {/* name */}
                    <div className='mb-3'>
                        <label className='form-label'>Package Name<sup style={{color:'red'}}>*</sup></label>
                        <input type='text' placeholder='Enter Package Name' onChange={( e ) => setName( e.target.value )}
                            value={name} className='form-control' />
                    </div>
                    {/* Duration total days */}
                  
                    <div className='mb-3'>
                        <label className='form-label'>Package Duration<sup style={{color:'red'}}>*</sup></label>
                        <input type='number' placeholder='Select Package Duration' min={1} onChange={( e ) => setDuration( e.target.value )}
                            value={duration} className='form-control' />
                    </div>
                    {/* price per day */}
                    <div className='mb-3'>
                        <label className='form-label'>Package Price Per Day<sup style={{color:'red'}}>*</sup></label>
                        <input type='number' placeholder='Select Package Price Per Day' min={1} onChange={( e ) => setPrice( e.target.value )}
                            value={price} className='form-control' />
                    </div>
                    {/* hospitals */}
                    <div className='mb-3'>
                        <label className='form-label'>Hospitals <sup style={{color:'red'}}>*</sup></label>
                        <Select
                            isMulti
                            onChange={setSelectedOption}
                            options={hospitalArray}
                            value={selectedOption}
                            labelledBy="Select"
                        />
                    </div>
                    {/* description */}
                    <div className='mb-3'>
                        <label className='form-label'>Package Description<sup style={{color:'red'}}>*</sup></label>
                        <textarea placeholder='Enter Package Description' onChange={( e ) => setDesc( e.target.value )}
                            value={desc} className='form-control' />
                    </div>
                    {/* submit */}
                    <div className='mb-3 text-center'>
                        <button type='submit' className="btn btn-primary col-12 mt-4">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
