import React from 'react'

export default function DocumentsView({ selectedSession }) {
    // console.log(selectedSession[0].images)
    return (
        <div id="carouselExample" className="carousel slide">

            {Object.values(selectedSession[0].images).map((item, i) => (
                <div className="carousel-inner" key={i} >
                    <div
                        // className="carousel-item active"
                        className={(() => {
                            if (i === 0) {
                                return (
                                    'carousel-item active'
                                )
                            } else {
                                return (
                                    'carousel-item'
                                )
                            }
                        })()}
                        key={i}
                    >
                        <img src={`${process.env.REACT_APP_SESSION_IMAGE_PATH}` + item.path} className="d-block w-100" alt="session-image" style={{ maxHeight: '1000px' }} />
                    </div>
                </div>
            ))}
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>
        </div>

    )
}
