import React from 'react';

export default function DeletePackage( { selected, handleDelete, packageName } )
{
    return (
        <div className='card'>
            <div className='card-header text-center bg-gray'>
                Delete Package
            </div>
            <div className='card-body text-center'>
                {packageName}
            </div>
            <div className='card-footer'>
                <button className='btn btn-success float-end' onClick={() =>
                {
                    handleDelete( selected );
                }}>
                    Confirm
                </button>
            </div>
        </div>
    );
}
