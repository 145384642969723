import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function Releaselog()
{

    const md = `
        # Code Name: ub.deltoid.client.1.9.4
        # Code Name: ub.deltoid.server.1.6.3

        ---
        ### NEW :
        ---
        - A feedback button has been added to the therapist's dashboard to prompt patients for feedback.
        - Patient feedback is used to calculate a rating.
        - A feedback report has been added to both the therapist's and patient's dashboards.
        - The admin can filter the feedback report based on various parameters, such as:
             - By therapist
             - By rating
        ---
        `;
    return (
        <div className='h-screen flex justify-center items-center '>
            <ReactMarkdown children={md}>

            </ReactMarkdown>
        </div>
    );
}
