import React from 'react';
import EasyEdit from 'react-easy-edit';


export default function PatientDetails( { patientDetails, selected, name,
    setName,
    phone,
    setPhone,
    email,
    setEmail, handleDetailsChange, edit, setEdit } )
{
    return (
        <div className='card'>
            <div className='card-header text-center'>
                Patient Details
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-12'>
                        {patientDetails[ 4 ]
                            ? (
                                <div className='row'>
                                    <div className='col-3'>
                                        Patient Name :
                                    </div>
                                    <div className='col-4'>
                                        <b>
                                            <input value={name} className='border p-1 rounded' type='text' onChange={( e ) => 
                                            {
                                                setEdit( prev => ( { ...prev, [ "name" ]: e.target.value } ) );
                                                setName( e.target.value );
                                            }} />
                                        </b>
                                    </div>
                                </div>
                            )
                            : ( <></> )
                        }
                    </div>
                    <div className='col-12'>
                        {patientDetails[ 0 ]
                            ? (
                                <div className='row'>
                                    <div className='col-3'>
                                        Patient Email :
                                    </div>
                                    <div className='col-4'>
                                        <b>
                                            <input value={email} className='border p-1 rounded-lg' type='text' onChange={( e ) =>
                                            {
                                                setEdit( prev => ( { ...prev, [ "email" ]: e.target.value } ) );
                                                setEmail( e.target.value );
                                            }}
                                            />
                                        </b>
                                    </div>
                                </div>

                            )
                            : ( <></> )
                        }
                    </div>
                    <div className='col-12'>
                        {patientDetails[ 1 ]
                            ? (
                                <div className='row'>
                                    <div className='col-3'>
                                        Phone :
                                    </div>
                                    <div className='col-4'>
                                        <b>
                                            <input value={phone} className='border p-1 rounded-lg' type='number' onChange={( e ) => 
                                            {
                                                setEdit( prev => ( { ...prev, [ "phone" ]: e.target.value } ) );
                                                setPhone( e.target.value );
                                            }}
                                            />
                                        </b>
                                    </div>
                                </div>

                            )
                            : ( <></> )
                        }
                    </div>
                    <div className='col-12 mt-1'>
                        {patientDetails[ 2 ]
                            ? (
                                <div className='row'>
                                    <div className='col-3'>
                                        City:
                                    </div>
                                    <div className='col-4'>
                                        <b>
                                            {" " + patientDetails[ 2 ]}
                                        </b>
                                    </div>
                                </div>

                            )
                            : ( <></> )
                        }
                    </div>
                    <div className='col-12 mt-2'>
                        {patientDetails[ 2 ]
                            ? (
                                <div className='row'>
                                    <div className='col-3'>
                                        Country:
                                    </div>
                                    <div className='col-4'>
                                        <b>
                                            {" " + patientDetails[ 3 ]}
                                        </b>
                                    </div>
                                </div>

                            )
                            : ( <></> )
                        }
                    </div>

                </div>
            </div>
            <div className='card-footer'>
                <div>
                    <button
                        className='btn btn-sm btn-success float-end rounded p-2'
                        onClick={() => handleDetailsChange( name, email, phone )}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}
