import React, { useRef, useState } from "react";
import Layout from "../../component/layout/Layout";
import { useAuth } from "../../component/context/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHandHoldingHeart, faCommentDollar } from '@fortawesome/free-solid-svg-icons';
import { Bar } from "react-chartjs-2";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";
import { Modal, Select, Tooltip } from "antd";
import Hospital_Patient_Sessions from "./Hospital_Patient_Sessions";
import PackagePatients from "./PackagePatients";
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { FcPortraitMode, FcClearFilters, FcBullish, FcComboChart, FcAreaChart, filterIcon, FcLeave, FcBusiness, FcDebt, FcApproval, FcCollaboration, FcOvertime, FcBearish } from "react-icons/fc";
import Revenue from "./Revenue";
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';
import RefundHospital from "./RefundHospital";



export default function HospitalDash()
{
    const [ auth ] = useAuth();
    const hospitalID = auth?.user?.HospitalId[ 0 ];

    let date = new Date();

    const [ initialCond, setInitialCond ] = useState( "" );
    const [ ipdData, setIPDData ] = useState( '' );
    const [ opdData, setOPDData ] = useState( '' );
    const [ patients, setPatients ] = useState( "" );
    const [ page, setPage ] = useState( 1 );
    const [ count, setCount ] = useState( 0 );
    const [ type, setType ] = useState( "" );
    const [ visible, setVisible ] = useState( false );
    const [ sesssionList, setSessionList ] = useState( [] );
    const [ packages, SetPackages ] = useState( [] );
    const [ revenue, setRevenue ] = useState( [] );
    var ref = useRef( null );
    const [ query, setQuery ] = useState( "" );
    const [ dateRange, setDataRange ] = useState( [] );
    const server = process.env.REACT_APP_AWS_LAMBDA;
    const [ patientData, setPatientData ] = useState( "" );
    const [ hospitalChart, setHospitalChart ] = useState( "" );
    const [ month, setmonth ] = useState( '' );
    const [ totalSales, setTotalSales ] = useState( 0 );
    const [ paymentArray, setPaymentArray ] = useState( [] );
    const [ sales, setSales ] = useState( 0 );
    const [ label, setLabel ] = useState( '' );
    const [ highpaidFinal, setHighPaidFinal ] = useState( [] );
    const [ highPaid, setHighPaid ] = useState( [] );
    const [ advance, setAdvance ] = useState( 0 );
    const [ refundAmount, setRefundAmount ] = useState( 0 );
    const [ due, setDue ] = useState( 0 );
    const [ patientCount, SetPatientCount ] = useState( 0 );
    const [ timeSessionProper, setTimeSessionProper ] = useState( 0 );
    const [ sessionTimeMilliSec, setSessionTimeMilliSec ] = useState( 0 );
    const [ catType, setCatType ] = useState( "" );
    const [ refundMonthWise, setRefundMonthWise ] = useState( 0 );
    const [ outstandingAmount, setOutstanding ] = useState( 0 );
    const [ resData, setResData ] = useState( "" );
    const [ hospitla, sethospital ] = useState( "" );
    const [ ipdTotal, setIPDTotal ] = useState( 0 );
    const [ opdTotal, setOPDTotal ] = useState( 0 );
    const [ ipdHeader, setIPDHeader ] = useState( '' );
    const [ opdHeader, setOPDHeader ] = useState( '' );
    const [ amount, setAmount ] = useState( 0 );
    const [ typeData, setTypeData ] = useState( [] );

    const ipdRef = useRef( null );
    const opdRef = useRef( null );

    const dateSet = () =>
    {
    };


    const patientList = async () =>
    {
        try
        {
            if ( catType === undefined )
            {
                setCatType( "" );
            }
            const { data } = await axios.get(
                `${server}/api/medi/profile/hospital/${hospitalID}?page=${page}&dateRange=${dateRange}&q=${query}&type=${catType}`
            );
            setPatients( data.list[ 0 ]?.data );
            setCount( data.list[ 0 ]?.pagination[ 0 ]?.pageCount );
        } catch ( error )
        {
            toast.error( "error in list" );
        }
    };
    // mongo call
    useEffect( () =>
    {
        patientList();
    }, [ hospitalID, page, dateRange, query, catType ] );

    // patients
    useEffect( () =>
    {
        // console.log(patients);
    }, [ patients ] );

    const handleDate = async ( value ) =>
    {
        let date = [];

        console.log( value );

        if ( value?.length > 0 )
        {
            Promise.all( value.map( ( item ) => date.push( new Date( item ).toISOString().slice( 0, 10 ) ) ) );
            let newDate = new Date( date[ 1 ] );
            newDate.setDate( newDate.getDate() + 1 );
            date[ 1 ] = newDate.toJSON().slice( 0, 10 );
            let newDate0 = new Date( date[ 0 ] );
            newDate0.setDate( newDate0.getDate() + 1 );
            date[ 0 ] = newDate0.toJSON().slice( 0, 10 );
        } else
        {
            date = "";
        }
        setDataRange( date );
        setPage( 1 );
    };

    const barChartData = async () =>
    {
        try
        {
            if ( catType === undefined )
            {
                setCatType( "" );
            }
            const res = await axios.get( `${server}/api/medi/reports/hospitalProfileReport?date=${dateRange}&hospital=${hospitalID}&type=${catType}` );
            setPatientData( res.data.patients );
        } catch ( error )
        {
            console.log( error );
            toast.error( error );
        }
    };

    useEffect( () =>
    {
        barChartData();
    }, [ dateRange, hospitalID, catType ] );

    useEffect( () =>
    {
        if ( patientData.length === 1 )
        {
            patientData.map( item => ( () =>
            {
                setmonth( item?._id.month + ', ' + item._id.year );
                setTotalSales( item?.totalSalesAmount );
                // setRefundAmount( item?.refund );
                // setAdvance( item?.advance );
                // setDue( item.totalSalesAmount - item.advance );
                setPaymentArray( item.patientId );
            } )() );

        }
        if ( patientData.length > 1 )
        {
            patientData.map( item => ( () =>
            {
                if ( ( item._id.month === new Date().getMonth() + 1 ) && ( item._id.year === new Date().getFullYear() ) )
                {
                    return (
                        setmonth( item?._id.month + ', ' + item._id.year ),
                        setTotalSales( item?.totalSalesAmount ),
                        // setRefundAmount( item?.refund ),
                        // setAdvance( item?.advance ),
                        // setDue( item.totalSalesAmount - item.advance ),
                        setPaymentArray( item.patientId )
                    );
                }
            } )() );

        }
    }, [ patientData ] );

    const patientReport = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/reports/high-paid-patients?date=${dateRange}&hospital=${hospitalID}&type=${catType}` );
            setHighPaid( res.data.highPaid );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something went wrong on patient report" );
        }
    };

    useEffect( () =>
    {
        patientReport();
    }, [ hospitalID, catType, dateRange ] );

    useEffect( () =>
    {
        if ( highPaid.length > 0 )
        {
            let dummy = [];
            Object.values( highPaid ).map( item => (
                dummy.push( { id: item._id, label: item?.label[ 0 ], value: item?.value } )
            ) );
            setHighPaidFinal( dummy );
        }
    }, [ highPaid ] );

    // chart meta data 
    const datasd = () =>
    {
        let salesData = [];
        let labelData = [];
        Object.values( patientData ).map( item =>
        (
            salesData.push( item.totalSalesAmount ),
            labelData.push( item._id.month + ", " + item._id.year )
        ) );
        setSales( [ { data: salesData, label: "Sales By Month" } ] );
        setLabel( labelData );
    };

    const typeOptions = [ { value: 'ipd', label: 'IPD' }, { value: 'opd', label: 'OPD' } ];


    useEffect( () =>
    {
        datasd();
    }, [ patientData ] );

    const data = {
        labels: label,
        datasets: sales
    };

    const sessionTime = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/reports/sessionTime?date=${dateRange}&hospital=${hospitalID}&type=${catType}` );
            setSessionTimeMilliSec( res.data.sessionTime );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something went wrong on sessionTime" );
        }
    };
    useEffect( () =>
    {
        if ( sessionTimeMilliSec?.length > 1 )
        {
            Object.values( sessionTimeMilliSec ).map( item => ( () =>
            {
                if ( ( item._id.month === new Date().getMonth() + 1 ) && ( item._id.year === new Date().getFullYear() ) )
                {
                    setTimeSessionProper( new Date( item.millisec ).toISOString().slice( 11, -1 ).split( '.' )[ 0 ] );
                }
            } )() );
        }
        if ( sessionTimeMilliSec?.length === 1 )
        {
            Object.values( sessionTimeMilliSec ).map( item => (
                setTimeSessionProper( new Date( item.millisec ).toISOString().slice( 11, -1 ).split( '.' )[ 0 ] )
            ) );
        }
        if ( sessionTimeMilliSec?.length === 0 )
        {
            setTimeSessionProper( 0 );
        }
    }, [ sessionTimeMilliSec ] );

    useEffect( () =>
    {
        sessionTime();
    }, [ dateRange, type, hospitalID, catType ] );

    useEffect( () =>
    {
        SetPatientCount( [ ...new Set( paymentArray ) ] );
    }, [ paymentArray?.length > 0 && paymentArray ] );

    const outstanding = async () =>
    {
        const res = await axios.get( `${server}/api/medi/reports/outstanding?date=${dateRange}&hospital=${hospitalID}&type=${catType}` );
        setOutstanding( res.data.outstanding );
    };

    useEffect( () =>
    {
        if ( outstandingAmount?.length > 1 )
        {
            if ( dateRange.length === 0 )
            {
                Object.values( outstandingAmount ).map( item => ( () =>
                {
                    if ( ( item._id.month === new Date().getMonth() + 1 ) && ( item._id.year && new Date().getFullYear() ) )
                    {
                        setAdvance( item.advance - refundAmount );
                        setDue( totalSales - item.advance + refundAmount );
                    }
                } )() );
            }
            else
            {
                Object.values( outstandingAmount ).map( item => ( () =>
                {
                    if ( ( item._id.month === new Date( dateRange[ 0 ] ).getMonth() + 1 ) && ( item._id.year && new Date().getFullYear() ) )
                    {
                        setAdvance( item.advance - refundAmount );
                        setDue( totalSales - item.advance + refundAmount );
                    }
                } )() );
                // console.log( new Date( dateRange[ 0 ] ).getMonth() + 1 );
            }

        }
        if ( outstandingAmount.length === 1 )
        {
            Object.values( outstandingAmount ).map( item => ( () =>
            {
                setAdvance( item.advance - refundAmount );
                setDue( totalSales - item.advance + refundAmount );
            } )() );
        }
    }, [ outstandingAmount, totalSales, refundAmount ] );

    useEffect( () =>
    {
        outstanding();
    }, [ hospitalID, catType, dateRange ] );

    // refund 
    const refundReport = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/reports/refundReport?date=${dateRange}&hospital=${hospitalID}` );
            setRefundMonthWise( res.data.patients );
            // setRefundAmount( res.data.patients );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something Went wrong on Hsopitals reports" );
        }
    };

    useEffect( () =>
    {
        refundReport();
    }, [ dateRange, type, hospitalID ] );


    useEffect( () =>
    {
        if ( refundMonthWise?.length === 1 && dateRange.length === 0 )
        {
            // console.log( 'po' );
            setRefundAmount( refundMonthWise[ 0 ][ 'refund' ] );
        }
        else if ( refundMonthWise?.length > 1 )
        {
            Object.values( refundMonthWise ).map( ( item ) => (
                () =>
                {
                    if ( dateRange.length === 0 )
                    {
                        if ( item._id.month === new Date().getMonth() + 1 )
                        {
                            setRefundAmount( item.refund );
                        }
                    }
                    else if ( dateRange.length > 0 )
                    {
                        if ( item._id.month === new Date( dateRange[ 0 ] ).getMonth() + 1 )
                        {
                            setRefundAmount( item.refund );
                        }
                    }
                }
            )() );
        }
        else if ( refundMonthWise?.length === 0 )
        {
            setRefundAmount( 0 );
        }
    }, [ refundMonthWise, dateRange ] );


    // download csv
    const handleReport = async () =>
    {
        try
        {
            const { data } = await axios.get( `${server}/api/medi/reports/csv-export?date=${dateRange}&hospital=${hospitalID}` );
            setResData( data.data );
            // SetCsvData( data.data[ 0 ].data );
            sethospital( data.data[ 0 ].hospital[ 0 ] );
            Object.values( data.data ).map( item => (
                () =>
                {
                    if ( item._id.type === 'ipd' )
                    {
                        setIPDTotal( item.sum - item.refund );
                    }
                    if ( item._id.type === 'opd' )
                    {
                        setOPDTotal( item.sum - item.refund );
                    }
                }
            )() );
        }
        catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong on export" );
        }
    };


    useEffect( () =>
    {
        if ( resData.length > 0 )
        {
            console.log( resData );
            let data = [];
            Object.values( resData ).map( item => (
                data.push( { [ item._id.type ]: item.data } )
            ) );
            setTypeData( data );
        }
    }, [ resData ] );


    useEffect( () =>
    {
        let ipdDummy = [];
        let opdDummy = [];
        let x = [];
        let y = [];
        Object.values( typeData ).map( item => (
            () =>
            {
                if ( Object.keys( item )[ 0 ] === 'ipd' )
                {
                    return (
                        ipdDummy.push( Object.keys( Object.values( item )[ 0 ][ 0 ] ) ),
                        // console.log( [ ...new Set( Object.values( item )[ 0 ] ) ] ),
                        setIPDData( Object.values( item )[ 0 ] )
                    );
                }
                if ( Object.keys( item )[ 0 ] === 'opd' )
                {
                    return (
                        opdDummy.push( Object.keys( Object.values( item )[ 0 ][ 0 ] ) ),
                        setOPDData( Object.values( item )[ 0 ] )
                    );
                }
            }
        )() );
        if ( ipdDummy.length > 0 )
        {
            Object.values( ipdDummy[ 0 ] ).map( item => (
                x.push( { label: item, key: item } )
            ) );
        }
        if ( opdDummy.length > 0 )
        {
            Object.values( opdDummy[ 0 ] ).map( item => (
                y.push( { label: item, key: item } )
            ) );
        }
        setIPDHeader( x );
        setOPDHeader( y );

    }, [ typeData ] );

    useEffect( () =>
    {
        if ( ipdData.length > 1 )
        {
            setIPDData( prevArray => [ ...prevArray, { 'Invoice_Amount': "Total : " + ipdTotal } ] );
            setTimeout( () =>
            {
                ipdRef?.current?.link.click();
            }, 500 );
        }
    }, [ ipdHeader.length > 0 && ipdData.length > 1 ] );

    console.log( ipdData, ipdData.length );
    useEffect( () =>
    {
        if ( opdData.length > 1 )
        {
            setOPDData( prevArray => [ ...prevArray, { 'Invoice_Amount': "Total : " + opdTotal } ] );
            setTimeout( () =>
            {
                opdRef?.current?.link.click();
            }, 500 );
        }
    }, [ opdHeader.length > 0 && opdData.length > 1 ] );


    // console.log( revenue );
    return (
        <Layout>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-2">
                            <div className="card">
                                <div className="card-header">
                                    <FcClearFilters /> <b> Filters</b>
                                </div>
                                <div className="card-body">
                                    <div className="col-12 ">
                                        <div>
                                            <b>Search by Name</b>
                                        </div>
                                        <Tooltip placement="bottomRight" title={"search by name"}>
                                            <input
                                                className="form-control mt-1"
                                                type="text"
                                                placeholder="Search by name"
                                                onChange={( e ) =>
                                                {
                                                    setQuery( e.target.value );
                                                    setPage( 1 );
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <br />
                                    <div className="">
                                        <b>Filter by Date</b>
                                    </div>
                                    <div className="row mt-1">
                                        <Tooltip placement="bottomRight" title={"filter by date"}>
                                            <DateRangePicker
                                                hoverRange={"month"}
                                                oneTap
                                                showOneCalendar
                                                shouldDisableDate={( date ) => isAfter( date, new Date() )}
                                                placeholder={"Filter Date"}
                                                // defaultValue={[ new Date( date.getFullYear(), date.getMonth(), 1 ), new Date() ]}
                                                onChange={( value ) => handleDate( value )}
                                            />
                                        </Tooltip>
                                    </div>
                                    <br />
                                    <div className="">
                                        <b>Filter by Type</b>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <Tooltip placement="bottomRight" title={"filter by type"}>
                                            <Select
                                                options={typeOptions}
                                                className='form-control'
                                                placeholder={"Select Type"}
                                                onChange={( value ) => setCatType( value )}
                                                allowClear
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="card shadow">
                                <div className="card-header">

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="">
                                                        <h3>
                                                            <FcAreaChart size={35} />
                                                            {"  "}
                                                            {patients?.length > 0 ? patients[ 0 ][ "hospitalName" ] : <></>}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-4"></div>
                                                <div className="col-2">
                                                    {hospitalID ? ( <div className=''>
                                                        <Tooltip placement="bottomRight" title={"Export to CSV"}>
                                                            <CSVLink
                                                                data={ipdData}
                                                                headers={ipdHeader}
                                                                asyncOnClick={true}
                                                                ref={ipdRef}
                                                                target="_blank"
                                                                filename={hospitla + "_ipd_" + ( dateRange ? ( moment( dateRange[ 0 ] ).format( 'MMM' ) ) : ( moment().format( "MMM" ) ) )}
                                                            />
                                                            <CSVLink
                                                                data={opdData}
                                                                headers={opdHeader}
                                                                asyncOnClick={true}
                                                                ref={opdRef}
                                                                filename={hospitla + "_opd_" + ( dateRange ? ( moment( dateRange[ 0 ] ).format( 'MMM' ) ) : ( moment().format( "MMM" ) ) )}
                                                                target="_blank"
                                                            />
                                                            <button className='form-control btn btn-success mt-2' onClick={handleReport}>
                                                                Download
                                                            </button>
                                                        </Tooltip>
                                                    </div> ) : ( <></> )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <hr /> */}
                                <div className="card-body">
                                    <div className='row' style={{ height: '200px' }}>
                                        {data.labels?.length > 0
                                            ? (
                                                <Bar data={data} className='border' />
                                            ) : ( <div className='text-center p-5'>No Records Found</div> )}
                                        {data.labels?.length > 0
                                            ? (
                                                <>
                                                    {/* <label>Sales By Hospitals</label> */}
                                                    <PieChart
                                                        series={[
                                                            {
                                                                data: highpaidFinal,
                                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                                faded: { innerRadius: 30, additionalRadius: -30 },
                                                            },
                                                        ]}
                                                        sx={{
                                                            [ `& .${pieArcClasses.faded}` ]: {
                                                                fill: 'gray',
                                                            },
                                                        }}
                                                        label={"sales by hospitals"}
                                                        width={250}
                                                        height={200}
                                                        className='border' />
                                                </>
                                            ) : ( <div className='text-center p-5'>No Records Found</div> )}
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow">
                                <div className="card-header">
                                    <div className="row">
                                        <h4>
                                            <FcPortraitMode /> Patients Details
                                        </h4>
                                    </div>
                                </div>
                                <div className="card-body overflow-auto" style={{ maxHeight: '250px' }}>
                                    {patients?.length > 0 ? (
                                        <MDBTable className="border">
                                            <MDBTableHead>
                                                <tr>
                                                    <th scope="col">Start Date</th>
                                                    <th scope="col">UID</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Type</th>
                                                    <th>Status</th>
                                                    <th score="col">Actions</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {Object.values( patients ).map( ( item ) => (
                                                    <Tooltip placement="bottom" title={( () =>
                                                    {
                                                        if ( ( item.paymentHistory[ 0 ].status === 'refund' ) )
                                                        {
                                                            return (
                                                                'Refund'
                                                            );
                                                        }
                                                        if ( ( item.advanceAmount < item.finalAmount ) && ( item.sessionList.length < item.duration ) )
                                                        {
                                                            return (
                                                                'Payment & Session Pending'
                                                            );
                                                        }

                                                        if ( ( item.advanceAmount < item.finalAmount ) && ( item.sessionDone < item.duration ) )
                                                        {
                                                            return (
                                                                'Payment & Session Pending'
                                                            );
                                                        }
                                                        if ( ( item.advanceAmount === item.finalAmount ) && ( item.sessionList.length === item.duration ) )
                                                        {
                                                            return (
                                                                'Completed'
                                                            );
                                                        }
                                                        if ( ( item.advanceAmount === item.finalAmount ) && ( item.sessionList.length < item.duration ) )
                                                        {
                                                            return (
                                                                'Sessions Pending'
                                                            );
                                                        }
                                                        if ( ( item.advanceAmount < item.amountAfterDiscount ) && ( item.sessionDone === item.duration ) && ( item.type === 'opd' ) )
                                                        {
                                                            return (
                                                                'OPD Payment Pending'
                                                            );
                                                        }
                                                        if ( ( item.type === 'ipd' ) && ( item.sessionDone === item.duration ) )
                                                        {
                                                            return (
                                                                'Ipd Completed'
                                                            );
                                                        }
                                                        if ( ( item.type === 'ipd' ) && ( item.amountAfterDiscount === 0 ) )
                                                        {
                                                            return (
                                                                'Ipd Money Not Recived'
                                                            );
                                                        }
                                                    } )()}>

                                                        <tr key={item._id} className={( () =>
                                                        {
                                                            if ( ( item.paymentHistory[ 0 ].status === 'refund' ) )
                                                            {
                                                                return (
                                                                    'alert alert-danger'
                                                                );
                                                            }
                                                            if ( ( item.advanceAmount < item.finalAmount ) && ( item.sessionList.length < item.duration ) )
                                                            {
                                                                return (
                                                                    'alert alert-warning'
                                                                );
                                                            }

                                                            if ( ( item.advanceAmount < item.finalAmount ) && ( item.sessionDone < item.duration ) )
                                                            {
                                                                return (
                                                                    'alert alert-danger'
                                                                );
                                                            }
                                                            if ( ( item.advanceAmount === item.finalAmount ) && ( item.sessionList.length === item.duration ) )
                                                            {
                                                                return (
                                                                    'alert alert-success'
                                                                );
                                                            }
                                                            if ( ( item.advanceAmount === item.finalAmount ) && ( item.sessionList.length < item.duration ) )
                                                            {
                                                                return (
                                                                    'alert alert-info'
                                                                );
                                                            }
                                                            if ( ( item.advanceAmount < item.amountAfterDiscount ) && ( item.sessionDone === item.duration ) && ( item.type === 'opd' ) )
                                                            {
                                                                return (
                                                                    'alert alert-primary'
                                                                );
                                                            }
                                                            if ( ( item.type === 'ipd' ) && ( item.sessionDone === item.duration ) )
                                                            {
                                                                return (
                                                                    'alert alert-success'
                                                                );
                                                            }
                                                            if ( ( item.type === 'ipd' ) && ( item.amountAfterDiscount === 0 ) )
                                                            {
                                                                return (
                                                                    'alert alert-warning'
                                                                );
                                                            }
                                                        } )()}>
                                                            <td>{new Date( item.startDate ).toDateString()}</td>
                                                            <td>{item.UID}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.paymentHistory[ 0 ].status !== 'refund' ? item.amountAfterDiscount : item.paymentHistory[ 0 ].refundAmount}</td>
                                                            <td>{item.paymentHistory[ 0 ].status !== 'refund' ? item.duration : ""}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.paymentHistory[ 0 ].status}</td>
                                                            <td>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="row">
                                                                            <div className="col-12">

                                                                                {/* // view sessions */}
                                                                                {( item?.sessionList.length > 0 ) && ( item?.paymentHistory[ 0 ].status !== 'refund' ) ? (
                                                                                    <Tooltip placement="bottomRight" title={"View Sessions"}>
                                                                                        <button
                                                                                            key={0}
                                                                                            className="btn btn-primary btn-sm"
                                                                                            onClick={() =>
                                                                                            {
                                                                                                setType( "sessions" );
                                                                                                setVisible( true );
                                                                                                setSessionList( item?.sessionList );
                                                                                                setInitialCond( item?.condition );
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faEye} />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <></>
                                                                                )}

                                                                                {/* // view packages */}
                                                                                {( item?.packageList.length > 0 ) && ( item?.paymentHistory[ 0 ].status !== 'refund' ) ?
                                                                                    ( <Tooltip placement="bottomRight" title={"View Packages"}>
                                                                                        <button key={1} className="btn btn-secondary btn-sm mx-1" onClick={() =>
                                                                                        {
                                                                                            setType( "package" );
                                                                                            setVisible( true );
                                                                                            SetPackages( item?.packageList );
                                                                                        }}>
                                                                                            <FontAwesomeIcon icon={faHandHoldingHeart} />
                                                                                        </button>
                                                                                    </Tooltip> ) : ( <></> )}

                                                                                {/* // view revenue */}
                                                                                {( item?.packageList.length > 0 ) && ( item?.paymentHistory[ 0 ].status !== 'refund' ) ?
                                                                                    ( <Tooltip placement="bottomRight" title={"View Revenue"}>
                                                                                        <button key={2} className="btn btn-info btn-sm mx-1" onClick={() =>
                                                                                        {
                                                                                            // console.log( item?.paymentHistory[ 0 ] );
                                                                                            setType( "revenue" );
                                                                                            setVisible( true );
                                                                                            setRevenue( item?.paymentHistory[ 0 ] );
                                                                                        }}>
                                                                                            <FontAwesomeIcon icon={faCommentDollar} />
                                                                                        </button>
                                                                                    </Tooltip> ) : ( <></> )}

                                                                                {/* // view refund */}
                                                                                {/* {( item?.packageList.length > 0 ) && ( item?.paymentHistory[ 0 ].status === 'refund' ) ?
                                                                                    ( <Tooltip placement="bottomRight" title={"View Refund"}>
                                                                                        <button key={2} className="btn btn-danger btn-sm mx-1" onClick={() =>
                                                                                        {
                                                                                            // console.log( item?.paymentHistory[ 0 ] );
                                                                                            setType( "refund" );
                                                                                            setVisible( true );
                                                                                            setRevenue( item?.paymentHistory[ 0 ] );
                                                                                        }}>
                                                                                            <FontAwesomeIcon icon={faCommentDollar} />
                                                                                        </button>
                                                                                    </Tooltip> ) : ( <></> )} */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </Tooltip>

                                                ) )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    ) : (
                                        <>
                                            <div className="text-center">No Records Found</div>
                                        </>
                                    )}
                                </div>
                                <div className="card-footer">
                                    <div className="float-end">
                                        {count > 0 ? (
                                            <Stack>
                                                <Pagination
                                                    count={count}
                                                    showFirstButton
                                                    showLastButton
                                                    onChange={( event, value ) =>
                                                    {
                                                        setPage( value );
                                                    }}
                                                />
                                            </Stack>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-2 mt-2'>
                            <BrowserView>
                                {patientData[ 0 ]?._id.month
                                    ? (
                                        <div className="overflow-auto" style={{ maxHeight: '600px' }}>
                                            <div>
                                                <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                    <CardHeader
                                                        avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcLeave /></Avatar>}
                                                        title={`Month : ` + month
                                                            // `${patientData[ 0 ]?._id.month}` + ", " + `${patientData[ 0 ]?._id.year}`
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                            <hr />
                                            <div>
                                                <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                    <CardHeader
                                                        avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcBusiness /></Avatar>}
                                                        title={'Sales : ' + totalSales
                                                            // `${patientData[ 0 ]?.totalSalesAmount}`
                                                        }
                                                    // sx={{}}
                                                    />
                                                </Card>
                                            </div>
                                            <hr />
                                            <div>
                                                <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                    <CardHeader
                                                        avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcApproval /></Avatar>}
                                                        title={'Advance : ' + advance
                                                            // `${patientData[ 0 ]?.advance}`
                                                        }
                                                    // sx={{}}
                                                    />
                                                </Card>
                                            </div>
                                            <hr />
                                            <div>
                                                <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                    <CardHeader
                                                        avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcBearish /></Avatar>}
                                                        title={'Refund : ' + refundAmount
                                                            // `${Object.values( due ).map( item => ( ( () =>
                                                            // {
                                                            //     if ( item._id.month === new Date().getMonth() + 1 ) 
                                                            //     {
                                                            //         return ( item.outstanding );
                                                            //     }
                                                            // } )() ) )}`
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                            <hr />
                                            <div>
                                                <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                    <CardHeader
                                                        avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcDebt /></Avatar>}
                                                        title={'Due : ' + due
                                                            // `${Object.values( due ).map( item => ( ( () =>
                                                            // {
                                                            //     if ( item._id.month === new Date().getMonth() + 1 ) 
                                                            //     {
                                                            //         return ( item.outstanding );
                                                            //     }
                                                            // } )() ) )}`
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                            <hr />
                                            <div>
                                                <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                    <CardHeader
                                                        avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcCollaboration /></Avatar>}
                                                        title={"Count : " + patientCount.length
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                            <hr />
                                            <div>
                                                <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                    <CardHeader
                                                        avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcOvertime /></Avatar>}
                                                        title={"Time : " + timeSessionProper
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                        </div>
                                    )
                                    : ( <></> )}
                            </BrowserView>
                            <MobileView>

                            </MobileView>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={visible}
                onCancel={() =>
                {
                    setVisible( false );
                    setSessionList( "" );
                    setType( "" );
                    setInitialCond( "" );
                    setAmount( 0 );
                }}
                footer={null}
            >
                {( () =>
                {
                    if ( type === "sessions" )
                    {
                        return (
                            <Hospital_Patient_Sessions
                                sesssionList={sesssionList}
                                initialCond={initialCond}
                            /> );
                    }
                    if ( type === "refund" )
                    {
                        return (
                            <RefundHospital
                                revenue={revenue}
                            /> );
                    }
                    if ( type === 'package' )
                    {
                        return (
                            <PackagePatients
                                packages={packages}
                            />
                        );
                    }
                    if ( type === 'revenue' )
                    {
                        return (
                            <Revenue
                                revenue={revenue}
                                amount={amount}
                                setAmount={setAmount}
                            />
                        );
                    }
                } )()}
            </Modal>
        </Layout>
    );
}
