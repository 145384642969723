import React from 'react';
import { Modal, Select } from "antd";

export default function AssignTherapist( { therapist, setSelectedTerapist, selectedTherapist, handleSubmit, therapistArray } )
{
    // console.log(selectedTherapist)
    return (
        <div className='card'>
            <div className='card-header text-center'>
                Assign Therapist
            </div>
            <div className='row'>
                <div className='col-12'>
                    <form onSubmit={handleSubmit}>
                        <div className='mb-3 ms-1'>
                            <label><b>Therapists:</b></label>
                            <Select
                                className='form-control'
                                placeholder={"select therapist"}
                                options={therapist}
                                onChange={setSelectedTerapist}
                                value={selectedTherapist}
                                bordered={null}
                            />

                        </div>
                        {selectedTherapist ? ( <div className='float-end'>
                            <button className='btn btn-success'>Submit</button>
                        </div> ) : ( <></> )}

                    </form>
                </div>
            </div>
        </div>
    );
}
