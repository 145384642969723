import React, { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';


export default function FeedbackSubmittedMsg()
{
    const [ isExploding, setIsExploding ] = useState( true );
    return (
        <>
            {isExploding && <ConfettiExplosion force={0.8} width={3000} />}
            <div className="w-full h-screen flex justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                    <div className="text-3xl font-bold">Thank You !!!</div>
                    <div className="mt-2 text-lg">Your feedback was submitted successfully</div>
                </div>
            </div>
        </>
    );
}
