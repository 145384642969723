import React from 'react';

export default function Footer()
{
  return (
    <>
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
          <span className="navbar-text">
            All Rights Reserved © MediRehab
          </span>
        </div>
      </nav>
    </>
  );
}
