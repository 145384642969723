import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Icon } from '@iconify/react';
import { FcSportsMode, FcApprove, FcStatistics } from "react-icons/fc";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function DiagnosticViewModal( { diagnosticType, diagnosticData } )
{
    const [ assessment, setAssessment ] = useState( JSON.parse( diagnosticData ) );
    console.log( assessment );
    // console.log( diagnosticType );

    useEffect( () =>
    {
        setAssessment( JSON.parse( diagnosticData ) );
    }, [ diagnosticData ] );

    function valuetext( value )
    {
        return `${value}°C`;
    }

    const preventMinus = ( e ) =>
    {
        if ( e.code === 'Minus' || e.code === "Period" )
        {
            e.preventDefault();
        }
    };
    return (
        <>
            <div className='card'>
                <div className='card-header text-center'>
                    {( () =>
                    {
                        if ( diagnosticType === 'Orthopedic' )
                        {
                            return (
                                <FcSportsMode size={50} />
                            );
                        }
                        if ( diagnosticType === 'Neurological' )
                        {
                            return (
                                <FcApprove size={50} />
                            );
                        }
                        if ( diagnosticType === 'Cardiac' )
                        {
                            return (
                                <FcStatistics size={50} />
                            );
                        }
                    } )()}
                    <b>{diagnosticType} Assessment
                    </b>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12'>
                            {( () =>
                            {
                                if ( diagnosticType === 'Orthopedic' )
                                {
                                    return (
                                        <>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <List aria-labelledby="decorated-list-demo">
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="openmoji:knee-pain" width={30} height={30} /></ListItemDecorator> <b>Pain</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="arcticons:alight-motion" width={30} height={30} /></ListItemDecorator> <b>Range of Motion</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="icon-park-outline:muscle" width={30} height={30} /></ListItemDecorator> <b>Muscle Strength</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="healthicons:intestinal-pain-outline" width={30} height={30} /></ListItemDecorator> <b>Swelling / Oedema</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="oui:function" width={30} height={30} /></ListItemDecorator> <b>Functional Assessment</b>
                                                        </ListItem>
                                                    </List>
                                                </div>
                                                <div className='col-6'>
                                                    <Box>
                                                        {JSON.stringify(assessment?.pain)}
                                                        <Slider
                                                            id={0}
                                                            aria-label="Temperature"
                                                            defaultValue={assessment?.pain}
                                                            getAriaValueText={valuetext}
                                                            valueLabelDisplay="auto"
                                                            step={1}
                                                            marks
                                                            min={0}
                                                            max={10}
                                                            // defaultValue={ }
                                                            disabled
                                                        />
                                                    </Box>
                                                    <span style={{ color: 'red', display: 'none' }} id={'Orthopedic'}>number must be less than 5</span>

                                                    <hr />
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>

                                                    <input
                                                        id={1}
                                                        key={assessment?.rom}
                                                        type='number'
                                                        onKeyDown={preventMinus}
                                                        min={0}
                                                        max={5}
                                                        placeholder='0-5'
                                                        defaultValue={assessment?.rom}
                                                        className='form-control'
                                                        readOnly
                                                    />
                                                    <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-1'}>number must be less than 5</span>

                                                    <hr />
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <input
                                                        id={2}
                                                        type='number'
                                                        onKeyDown={preventMinus}
                                                        min={0}
                                                        max={5}
                                                        placeholder='0-5'
                                                        defaultValue={assessment?.ms}
                                                        className='form-control'
                                                        readOnly
                                                    />
                                                    <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-2'}>number must be less than 5</span>

                                                    <hr />
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <input
                                                        id={3}
                                                        type='number'
                                                        onKeyDown={preventMinus}
                                                        min={0}
                                                        max={5}
                                                        placeholder='0-5'
                                                        defaultValue={assessment?.swelling}
                                                        className='form-control'
                                                        readOnly
                                                    />
                                                    <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-3'}>number must be less than 5</span>

                                                    <hr />
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <input
                                                        id={4}
                                                        type='number'
                                                        onKeyDown={preventMinus}
                                                        min={0}
                                                        max={5}
                                                        defaultValue={assessment?.fa}
                                                        placeholder='0-5'
                                                        className='form-control'
                                                        readOnly
                                                    />
                                                    <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-4'}>number must be less than 5</span>

                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                                if ( diagnosticType === 'Neurological' )
                                {
                                    return (
                                        <>
                                            <div className='row'>
                                                <div className='col-xl-4 col-12'>
                                                    <List aria-labelledby="decorated-list-demo">
                                                        <div className='card'>
                                                            <div className='card-header text-center'>
                                                                <Icon icon="twemoji:letter-m" width={30} height={30} /><b> Motor Assessment</b>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <ListItem>
                                                                                    <ListItemDecorator><Icon icon="twemoji:leg-dark-skin-tone" width={30} height={30} /></ListItemDecorator> <b>Muscle Tone</b>
                                                                                </ListItem>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <input
                                                                                    id={0}
                                                                                    type='number'
                                                                                    onKeyDown={preventMinus}
                                                                                    min={0}
                                                                                    max={5}
                                                                                    defaultValue={assessment?.muscleTone}
                                                                                    placeholder='0-5'
                                                                                    className='form-control'
                                                                                    readOnly
                                                                                />
                                                                                <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-0'}>number must be less than 5</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <ListItem>
                                                                                    <ListItemDecorator><Icon icon="arcticons:musclewiki" width={30} height={30} /></ListItemDecorator> <b>Muscle Wasting</b>
                                                                                </ListItem>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <input
                                                                                    id={1}
                                                                                    type='number'
                                                                                    onKeyDown={preventMinus}
                                                                                    min={0}
                                                                                    max={5}
                                                                                    defaultValue={assessment?.muscleWasting}
                                                                                    placeholder='0-5'
                                                                                    className='form-control'
                                                                                    readOnly
                                                                                />
                                                                                <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-1'}>number must be less than 5</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <ListItem>
                                                                                    <ListItemDecorator><Icon icon="icon-park-outline:muscle" width={30} height={30} /></ListItemDecorator> <b>Muscle Strength</b>
                                                                                </ListItem>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <Box >
                                                                                    {JSON.stringify( assessment?.muscleStrngth )}
                                                                                    <Slider
                                                                                        id={2}
                                                                                        aria-label="Temperature"
                                                                                        defaultValue={assessment?.muscleStrngth}
                                                                                        getAriaValueText={valuetext}
                                                                                        valueLabelDisplay="auto"
                                                                                        step={1}
                                                                                        marks
                                                                                        min={0}
                                                                                        max={10}
                                                                                        disabled
                                                                                    />
                                                                                </Box>
                                                                                <span style={{ color: 'red', display: 'none' }} id={'Neurologi'}>number must be less than 5</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </List>
                                                </div >
                                                <div className='col-xl-4 col-12 mt-2'>
                                                    <div className='card'>
                                                        <div className='card-header text-center'>
                                                            <Icon icon="twemoji:letter-s" width={30} height={30} /><b> Sensory Assessment</b>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <ListItem>
                                                                                <ListItemDecorator><Icon icon="noto:letter-s" width={30} height={30} /></ListItemDecorator> <b>Superficial Sensation</b>
                                                                            </ListItem>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <input
                                                                                id={3}
                                                                                type='number'
                                                                                onKeyDown={preventMinus}
                                                                                min={0}
                                                                                max={5}
                                                                                defaultValue={assessment?.superficialSensation}
                                                                                placeholder='0-5'
                                                                                className='form-control'
                                                                                readOnly
                                                                            />
                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-3'}>number must be less than 5</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <ListItem>
                                                                                <ListItemDecorator><Icon icon="simple-icons:deepgram" width={30} height={30} /></ListItemDecorator> <b> Deep Sensation</b>
                                                                            </ListItem>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <input
                                                                                id={4}
                                                                                type='number'
                                                                                onKeyDown={preventMinus}
                                                                                min={0}
                                                                                max={5}
                                                                                defaultValue={assessment?.deepSensation}
                                                                                placeholder='0-5'
                                                                                className='form-control'
                                                                                readOnly
                                                                            />
                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-4'}>number must be less than 5</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <ListItem>
                                                                                <ListItemDecorator><Icon icon="simple-icons:r" width={30} height={30} /></ListItemDecorator> <b> Reflexes</b>
                                                                            </ListItem>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <input
                                                                                id={5}
                                                                                type='number'
                                                                                onKeyDown={preventMinus}
                                                                                min={0}
                                                                                max={5}
                                                                                defaultValue={assessment?.reflexes}
                                                                                placeholder='0-5'
                                                                                className='form-control'
                                                                                readOnly
                                                                            />
                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-5'}>number must be less than 5</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-xl-4 col-12'>
                                                    <List aria-labelledby="decorated-list-demo">

                                                        <div className='card'>
                                                            <div className='card-header text-center'>
                                                                <Icon icon="icon-park:f-n-key" width={30} height={30} /><b> Functional Assessment</b>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <ListItem>
                                                                                    <ListItemDecorator><Icon icon="fontisto:paypal-p" width={30} height={30} /></ListItemDecorator> <b>Posture Analysis</b>
                                                                                </ListItem>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <input
                                                                                    id={6}
                                                                                    type='number'
                                                                                    onKeyDown={preventMinus}
                                                                                    min={0}
                                                                                    max={5}
                                                                                    defaultValue={assessment?.postureAnalysis}
                                                                                    placeholder='0-5'
                                                                                    className='form-control'
                                                                                    readOnly
                                                                                />
                                                                                <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-6'}>number must be less than 5</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <ListItem>
                                                                                    <ListItemDecorator><Icon icon="fa6-brands:glide-g" width={30} height={30} /></ListItemDecorator> <b>Gait Analysis</b>
                                                                                </ListItem>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <input
                                                                                    id={7}
                                                                                    type='number'
                                                                                    onKeyDown={preventMinus}
                                                                                    min={0}
                                                                                    defaultValue={assessment?.gaitAnalysis}
                                                                                    max={5}
                                                                                    placeholder='0-5'
                                                                                    className='form-control'
                                                                                    readOnly
                                                                                />
                                                                                <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-7'}>number must be less than 5</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr />
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <ListItem>
                                                                                    <ListItemDecorator><Icon icon="fa6-solid:bed" width={30} height={30} /></ListItemDecorator> <b>Bed Mobility</b>
                                                                                </ListItem>
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                <input
                                                                                    id={8}
                                                                                    type='number'
                                                                                    onKeyDown={preventMinus}
                                                                                    min={0}
                                                                                    max={5}
                                                                                    defaultValue={assessment?.bedMobility}
                                                                                    placeholder='0-5'
                                                                                    className='form-control'
                                                                                    readOnly
                                                                                />
                                                                                <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-8'}>number must be less than 5</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </List>
                                                </div >
                                            </div>
                                        </>
                                    );
                                }
                                if ( diagnosticType === 'Cardiac' )
                                {
                                    return (
                                        <>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <List aria-labelledby="decorated-list-demo">
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="mdi:heart-vitals" width={30} height={30} /></ListItemDecorator> <b>Vital Signs</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="icon-park:chest" width={30} height={30} /></ListItemDecorator> <b>Chest Examination</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="medical-icon:i-respiratory" width={30} height={30} /></ListItemDecorator> <b>Respiratory Examination</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="medical-icon:i-cardiology" width={30} height={30} /></ListItemDecorator> <b>Cardiac Endurance</b>
                                                        </ListItem>
                                                        <hr />
                                                        <ListItem>
                                                            <ListItemDecorator><Icon icon="oui:function" width={30} height={30} /></ListItemDecorator> <b>Functional Assessment</b>
                                                        </ListItem>
                                                    </List>
                                                </div>
                                                <div className='col-6'>
                                                    <Box>
                                                        <input
                                                            id="0"
                                                            type='number'
                                                            onKeyDown={preventMinus}
                                                            min={0}
                                                            max={5}
                                                            defaultValue={assessment?.vitalSigns}
                                                            placeholder='0-5'
                                                            className='form-control'
                                                            // style={{ borderBlockColor: 'red' }}
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-0'}>number must be less than 5</span>
                                                    <hr />
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <Box>
                                                        <input
                                                            id="1"
                                                            type='number'
                                                            onKeyDown={preventMinus}
                                                            min={0}
                                                            max={5}
                                                            defaultValue={assessment?.chestExm}
                                                            placeholder='0-5'
                                                            className='form-control'
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-1'}>number must be less than 5</span>

                                                    <hr />

                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <Box>

                                                        <input
                                                            id="2"
                                                            type='number'
                                                            onKeyDown={preventMinus}
                                                            min={0}
                                                            max={5}
                                                            defaultValue={assessment?.respiratoryExm}
                                                            placeholder='0-5'
                                                            className='form-control'
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-2'}>number must be less than 5</span>

                                                    <hr />
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <Box>

                                                        <input
                                                            id="3"
                                                            type='number'
                                                            onKeyDown={preventMinus}
                                                            min={0}
                                                            max={5}
                                                            defaultValue={assessment?.cardiacEnd}
                                                            placeholder='0-5'
                                                            className='form-control'
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-3'}>number must be less than 5</span>

                                                    <hr />
                                                    <MobileView>
                                                        <hr />
                                                    </MobileView>
                                                    <Box>
                                                        <input
                                                            id="4"
                                                            type='number'
                                                            onKeyDown={preventMinus}
                                                            min={0}
                                                            max={5}
                                                            defaultValue={assessment?.functunalAss}
                                                            placeholder='0-5'
                                                            className='form-control'
                                                            readOnly
                                                        />
                                                    </Box>
                                                    <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-4'}>number must be less than 5</span>

                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                            } )()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
