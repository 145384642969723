import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout.js';
import AdminMenu from './AdminMenu';
import { useParams } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Select } from "antd";
import { useRef } from 'react';
const { Option } = Select;


export default function EditHospital()
{
    const params = useParams();
    const [ name, setName ] = useState( "" );
    const [ state, setState ] = useState( "" );
    const [ city, setCity ] = useState( "" );
    const [ address, setAddress ] = useState( "" );
    const [ selectedImage, setSelectedImage ] = useState( '' );
    const [ contentType, setContentType ] = useState( '' );
    const [ id, setId ] = useState( '' );
    const [ type, setType ] = useState( '' );
    const navigate = useNavigate();
    const imageRef = useRef( null );
    const [ flag, setFlag ] = useState( 0 );

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    /* get single hospital */
    const singleHospital = async () =>
    {
        try
        {
            const { data } = await axios.post( `${server}/api/medi/hospital/edit-hospital/${params.slug}` );
            setName( data.hospital.name );
            setState( data.hospital.state );
            setCity( data.hospital.city );
            setAddress( data.hospital.address );
            setId( data.hospital._id );
            setSelectedImage( data.hospital.logo );
            // setContentType( data.hospital.logo.contentType );
        } catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong" );
        }
    };
    useEffect( () =>
    {
        singleHospital();
    }, [] );

    /* image handler */
    const imagehandle = ( e ) =>
    {
        if ( e.target.files && e.target.files.length > 0 )
        {
            setSelectedImage( e.target.files[ 0 ] );
            setType( 'new' );
        }
    };

    /* image reset */
    const reset = ( e ) =>
    {
        setSelectedImage( e.target.value = null );
        imageRef.current.value = "";
        imageRef.current.type = "text";
        imageRef.current.type = "file";
    };


    /* getting country from imported library */
    const country = Country.getCountryByCode( 'IN' );
    const countryState = State.getStatesOfCountry( country.isoCode );
    /* handle cities */
    const cities = City.getCitiesOfState( country.isoCode, state );

    /* form submit */
    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();
        try
        {

            let imageUrl = "";
            let res = "";
            if ( type === 'new' )
            {
                // aws temp url get request from aws
                const { data } = await axios.get( `${server}/s3Url` );
                // uploading file to aws bucket
                await fetch( data, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    body: selectedImage
                } );
                // getting image name uploaded on aws
                imageUrl = data.split( '?' )[ 0 ];
                res = await axios.put( `${server}/api/medi/hospital/update-hospital/${id}`, { "logo": imageUrl } );
            }
            // const form = new FormData();
            // form.append( 'name', name );
            // form.append( 'state', state );
            // form.append( 'city', city );
            // form.append( 'address', address );
            // type === 'new' ? ( form.append( 'logo', selectedImage ) ) : ( <></> );
            if ( flag === 1 )
            {
                res = await axios.put( `${server}/api/medi/hospital/update-hospital/${id}`, { name, state, city, address, flag } );

            }
            if ( res.data.success )
            {
                toast.success( res.data.message );
                navigate( "/dashboard/admin/create-hospital" );
            } else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };

    const handleChange = ( event ) =>
    {
        const value = event.target.value;
        const sanitizedValue = value.replace( /[^\w\s]/gi, '' ); // Regex to remove special characters
        setName( sanitizedValue );
        setFlag( 1 );
    };

    return (
        <Layout>
            <div className='card'>
                <div className='card-head bg-primary text-light text-center'>
                    <span><b>Edit Hospital</b></span>
                </div>
                <div className='card-body'>

                    <div className="mb-3 form-group">
                        <label className="form-label">Hospital Name<sup style={{ color: 'red' }}>*</sup></label>
                        <input type="text" className="form-control" placeholder="Enter Hospital Name" onChange={handleChange}
                            value={name} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Hospital State<sup style={{ color: 'red' }}>*</sup></label>
                        <Select placeholder='Select a State' size='large' showSearch value={state}
                            className='form-select mb-3' onChange={( value ) => { setState( value ); setCity( "" ); }}>
                            {Object.values( countryState ).map( ( c, i ) => (
                                <Option key={i} value={c.isoCode}>{c.name}</Option>
                            ) )}
                        </Select>
                    </div>
                    {state.length === 0 ? ( <></> ) : ( <div className="mb-3">
                        <label className="form-label">Hospital City<sup style={{ color: 'red' }}>*</sup></label>
                        <Select placeholder="Select a City" className='form-select mb-3' onChange={( value ) => setCity( value )} value={city}>
                            {cities.map( ( ci, l ) => (
                                <Option key={l} value={ci.name}>{ci.name}</Option>
                            ) )}
                        </Select>
                    </div> )}
                    <div className="mb-3 ">
                        <label className="form-label">Hospital Address<sup style={{ color: 'red' }}>*</sup></label>
                        <input type="text" className="form-control" placeholder="Enter Hospital Address" onChange={( e ) => setAddress( e.target.value )}
                            value={address} />
                    </div>

                    <div className="mb-3 ">
                        <label className="form-label">Hospital Image<sup style={{ color: 'red' }}>*</sup></label>
                        <input type="file" className="form-control" placeholder="Please upload hospital image" onChange={imagehandle} ref={imageRef} />
                    </div>

                    <div className='mb-3'>
                        {selectedImage && (
                            <div>
                                <img src={type === 'new' ? URL.createObjectURL( selectedImage ) : selectedImage} alt='product-image' height={'200px'}
                                    className='img img-responsive'
                                />
                                <button className='btn btn-danger d-flex' onClick={reset}>Remove Image</button>
                            </div>
                        )}
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-success float-end' onClick={handleSubmit}>Update</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
