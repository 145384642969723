import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';

export default function CallView()
{

  const [ meetingPin, setMeetingPin ] = useState( "" );
  const [ meetingID, setMeetingID ] = useState( "" );
  const [ patientID, setPatientID ] = useState( "" );
  const [ role, setRole ] = useState( "" );

  const navigate = useNavigate();
  const params = useParams();
  const roomId = params.id;

  const queryParams = new URLSearchParams( window.location.search );

  useEffect( () =>
  {
    setMeetingPin( queryParams.get( "mpin" ) );
    setMeetingID( queryParams.get( "mid" ) );
    setPatientID( queryParams.get( "pid" ) );
    setRole( queryParams.get( "role" ) );
  }, [ queryParams ] );

  const handleSubmit = async()=>{
    try {
      navigate( `/multiRoom/${roomId}?pid=${patientID}&role=${role}` );
    } catch (error) {
        console.log(error)
    }
  }

  return (
    <div className=''>
      <section>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-24">
            <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
              <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl">
                log in
              </h2>

              <form action="#" method="POST" className="mt-8">
                <div className="space-y-5">
                  <div>
                    <label htmlFor="" className="text-base font-medium text-gray-900">
                      {" "}
                      Meeting ID{" "}
                    </label>
                    <div className="mt-2">
                      <input
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="text"
                        placeholder="Meeting ID"
                        defaultValue={meetingID}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label htmlFor="" className="text-base font-medium text-gray-900">
                        {" "}
                        Meeting Pin{" "}
                      </label>

                    </div>
                    <div className="mt-2">
                      <input
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="text"
                        placeholder="Meeting Pin"
                        defaultValue={meetingPin}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => handleSubmit()}
                      type="button"
                      className="inline-flex w-full items-center justify-center rounded-md bg-black px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                    >
                      Get started{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="ml-2"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="h-full w-full">
            <img
              className="mx-auto h-full w-full rounded-md object-cover"
              src="https://images.unsplash.com/photo-1630673245362-f69d2b93880e?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1740&amp;q=80"
              alt=""
            />
          </div>
        </div>
      </section>
    </div>

  );
}
