import React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';

export default function FeedBackList( { feedbackList } )
{

    const movement = {
        shoulderPain: "Shoulder Pain",
        shoulderRangeOfMotion: "Shoulder Range of Motion",
        elbowStiffness: "Elbow Stiffness",
        wristMovement: "Wrist Movement",
        fingerMovement: "Finger Movement",
        trunkBalance: "Trunk Balance",
        hipRotation: "Hip Rotation",
        kneeFlexionAndExtension: "Knee Flexion and Extension",
        ankleStiffness: "Ankle Stiffness",
        ankleMobility: "Ankle Mobility",
        overallLegStrength: "Overall Leg Strength",
        writtenFeedback: "Written Feedback",
        writtenFeedBack: "Written Feedback"
    };

    return (
        <div>
            {Object.values( feedbackList ).map( ( item, i ) => (

                <div className='card mt-2' key={i}>

                    {/* 1st row */}
                    <div className='card-header text-center bg-info'>
                        Feedback on {item.createdAt}
                    </div>
                    {/* 2nd row */}
                    {item?.rating &&
                        <div className='w-full flex justify-center mt-2'>
                            <Box sx={{ '& > legend': { mt: 2 } }}>
                                <Rating name="read-only" value={item?.rating} readOnly />
                            </Box>
                        </div>
                    }
                    {/* 3rd row */}
                    {!item?.id &&
                        <div className='card-body'>
                            <table className='table table-auto'>
                                <thead>
                                    <tr>
                                        <th>
                                            Type of Movement
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries( item ).map( ( [ key, value ] ) =>
                                        ( () =>
                                        {
                                            if ( key !== "imageURL" && key !== "createdAt" && key !== "rating" )
                                            {
                                                return ( <tr key={key}>
                                                    <td>
                                                        {movement[ key ]}
                                                    </td>
                                                    <td>
                                                        {value}
                                                    </td>
                                                </tr> );
                                            }
                                            if ( key === "imageURL" && !value.length == 0 )
                                            {
                                                return (
                                                    <tr className='rounded-lg'>
                                                        <td colSpan={3} className='col-span-2 bg-red-600'>
                                                            <video
                                                                controls
                                                                className='w-full h-30 object-cover rounded col-span-2'
                                                            >
                                                                <source src={value} />
                                                            </video>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        } )() )}
                                </tbody>
                            </table>
                        </div>
                    }
                    {item?.id &&
                        <div className='flex justify-center m-5'>
                            Mail Sent...Feedback Pending !!!
                        </div>}
                </div>
            ) )}
        </div>
    );
}
