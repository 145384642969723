import React from 'react';
import { useAuth } from '../../component/context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { faTableColumns, faHospital, faBoxOpen, faHospitalUser, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const drawerWidth = 240;

const openedMixin = ( theme ) => ( {
    width: drawerWidth,
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    } ),
    overflowX: 'hidden',
} );

const closedMixin = ( theme ) => ( {
    transition: theme.transitions.create( 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    } ),
    overflowX: 'hidden',
    width: `calc(${theme.spacing( 7 )} + 1px)`,
    [ theme.breakpoints.up( 'sm' ) ]: {
        width: `calc(${theme.spacing( 8 )} + 1px)`,
    },
} );

const DrawerHeader = styled( 'div' )( ( { theme } ) => ( {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing( 0, 1 ),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
} ) );

const AppBar = styled( MuiAppBar, {
    shouldForwardProp: ( prop ) => prop !== 'open',
} )( ( { theme, open } ) => ( {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create( [ 'width', 'margin' ], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    } ),
    ...( open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create( [ 'width', 'margin' ], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        } ),
    } ),
} ) );

const Drawer = styled( MuiDrawer, { shouldForwardProp: ( prop ) => prop !== 'open' } )(
    ( { theme, open } ) => ( {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...( open && {
            ...openedMixin( theme ),
            '& .MuiDrawer-paper': openedMixin( theme ),
        } ),
        ...( !open && {
            ...closedMixin( theme ),
            '& .MuiDrawer-paper': closedMixin( theme ),
        } ),
    } ),
);

// export default function AdminMenu()
// {
//     const [ auth, setAuth ] = useAuth();
//     let capabilities = [ "" ];

//     /* capabilities from local strorage */
//     capabilities = auth.user.capabilities;

//     return (
//         <Sidebar className='bg-light'>
//             <Menu>
//                 <MenuItem active={window.location.pathname === "/dashboard/admin/"}
//                     icon={<FontAwesomeIcon icon={faTableColumns} />} component={<Link to="/dashboard/admin/" ></Link>}>DashBoard</MenuItem>
//                 {/* user master */}
//                 {capabilities.includes( 'manage-user' ) ?
//                     ( <SubMenu icon={<FontAwesomeIcon icon={faUserDoctor} />} label='User Master'>
//                         <MenuItem active={window.location.pathname === "/dashboard/admin/manage-user"}
//                             component={<Link to="/dashboard/admin/manage-user"></Link>}>Users</MenuItem>
//                         <MenuItem active={window.location.pathname === "/dashboard/admin/create-role"}
//                             component={<Link to={"/dashboard/admin/create-role"} />}>Role</MenuItem>
//                         <MenuItem active={window.location.pathname === "/dashboard/admin/create-capability"}
//                             component={<Link to={"/dashboard/admin/create-capability"} />}>Capabitilty</MenuItem>
//                     </SubMenu>
//                     ) : ( <></> )}
//                 {/* hospital master */}
//                 {capabilities.includes( 'manage-hospital' )
//                     ? ( <MenuItem active={window.location.pathname === "/dashboard/admin/create-hospital"}
//                         icon={<FontAwesomeIcon icon={faHospital} />} component={<Link to="/dashboard/admin/create-hospital"></Link>}>Hospital Master</MenuItem> )
//                     : ( <></> )
//                 }
//                 {/* package master */}
//                 {capabilities.includes( 'manage-package' )
//                     ? ( <MenuItem active={window.location.pathname === "/dashboard/admin/create-package"}
//                         icon={<FontAwesomeIcon icon={faBoxOpen} />} component={<Link to="/dashboard/admin/create-package"></Link>}>Package Master</MenuItem> )
//                     : ( <></> )
//                 }
//                 {/* patient master */}
//                 {capabilities.includes( 'manage-patient' )
//                     ? ( <MenuItem active={window.location.pathname === "/dashboard/admin/patient-master"}
//                         icon={<FontAwesomeIcon icon={faHospitalUser} />} component={<Link to="/dashboard/admin/patient-master"></Link>}>My Patients</MenuItem> )
//                     : ( <></> )
//                 }

//             </Menu>
//         </Sidebar>
//     );
// }

export default function AdminMenu()
{
    const theme = useTheme();
    const [ open, setOpen ] = React.useState( false );

    const handleDrawerOpen = () =>
    {
        setOpen( true );
    };

    const handleDrawerClose = () =>
    {
        setOpen( false );
    };

    return (
        <div>
            <div position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...( open && { display: 'none' } ),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Mini variant drawer
                    </Typography>
                </Toolbar>
            </div>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {[ 'Inbox', 'Starred', 'Send email', 'Drafts' ].map( ( text, index ) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ) )}
                </List>
                <Divider />
            </Drawer>
            
        </div>
    );
}
