import { useAuth } from '../../component/context/AuthContext';
import FeedBackModalShow from './FeedBackModalShow';
import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import Pagination from '@mui/material/Pagination';
import { DateRangePicker } from 'rsuite';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import isAfter from 'date-fns/isAfter';
import { Modal, Select } from 'antd';
import toast from 'react-hot-toast';
import moment from 'moment';
import axios from 'axios';

export default function FeedBackReport()
{

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    const [ auth ] = useAuth();
    const [ err, setErr ] = useState( "" );
    const [ page, setPage ] = useState( 1 );
    const [ rating, setRating ] = useState( "" );
    const [ hospital, setHospital ] = useState( '' );
    const [ pageCount, setPageCount ] = useState( 0 );
    const [ visible, setVisible ] = useState( false );
    const [ feedBacks, setFeedBacks ] = useState( '' );
    const [ dateRange, setDateRange ] = useState( '' );
    const [ modalData, setModalData ] = useState( "" );
    const [ therapist, setTherapist ] = useState( [] );
    const [ adminHospital, setAdminHospital ] = useState( "" );
    const [ selectedHospital, setSelectedHospital ] = useState( "" );
    const [ selectedTherapist, setSelectedTherapist ] = useState( "" );

    const id = auth?.user?.id;
    const role = auth?.user?.roleName;

    // get feedbacks list from database
    const getData = async () =>
    {
        try
        {
            if ( selectedTherapist === undefined )
            {
                setSelectedTherapist( "" );
            }
            if ( rating === undefined )
            {
                setRating( "" );
            }
            const { data } = await axios.get( `${server}/api/medi/patient/getFeedBackData?role=${role}&id=${id}&date=${dateRange}&page=${page}&therapist=${selectedTherapist}&rating=${rating}` );

            if ( data.success )
            {
                if ( data?.feedBack[ 0 ]?.data.length > 0 )
                {
                    setFeedBacks( data?.feedBack[ 0 ]?.data );
                    setPageCount( data?.feedBack[ 0 ]?.pagination[ 0 ]?.pageCount );
                    setErr( "" );
                } else
                {
                    setFeedBacks( [] );
                    setErr( "No Records Found !!!" );
                }
            }

        } catch ( error )
        {
            toast.error( error );
        }
    };

    /* get patient hospital therapists  */
    const getHospitalTherapist = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/user/get-hospital-therapists/${selectedHospital}` );
            if ( res.data.success )
            {
                let therapistArray = [];
                Promise.all(
                    Object.values( res.data.therapist ).map( item => (
                        therapistArray.push( { value: item._id, label: item.name } )
                    ) )
                );
                setTherapist( therapistArray );
            }
        } catch ( error )
        {
            toast.error( "something went wrong fetching therapist" );
        }
    };
    useEffect( () =>
    {
        if ( selectedHospital?.length )
        {
            getHospitalTherapist();
        }
    }, [ selectedHospital ] );

    const handleCalender = ( value ) =>
    {
        if ( value !== null )
        {
            let dateok = [];
            Object.values( value ).map( item => (
                dateok.push( moment( item ).format() )
            ) );
            setDateRange( dateok );
        } else
        {
            setDateRange( "" );
        }
    };

    useEffect( () =>
    {
        getData();
    }, [ dateRange, page, selectedTherapist, rating ] );


    const getHospitalRecords = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/hospital/get-all-hospital-list-unpaged` );
            if ( res?.data?.success )
            {
                setAdminHospital( res.data.hospitals );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };

    useEffect( () =>
    {
        getHospitalRecords();
    }, [] );

    useEffect( () =>
    {
        let adminHospitalArray = [];
        Object.values( adminHospital ).map( item => (
            adminHospitalArray.push( { value: item._id, label: item.name } )
        ) );
        setHospital( adminHospitalArray );
    }, [ adminHospital ] );

    // scroes 
    const scores = [
        { value: "0, 1", label: "1" },
        { value: "1, 3", label: "1-3" },
        { value: "3, 5", label: "3-5" },
        { value: "4.9, 5", label: "5" },
    ];

    return (
        <Layout>
            {/* filter for mobile */}
            <div className='sm:w-full md:hidden border ml-2 p-2 grid grid-cols-3 gap-1 rounded-sm'>
                <div className='mt-3'>
                    <label><b>By Date :</b></label>
                    <DateRangePicker
                        className=''
                        hoverRange={'month'}
                        oneTap
                        showOneCalendar
                        onChange={( value ) => handleCalender( value )}
                        shouldDisableDate={date => isAfter( date, new Date() )}
                        placeholder={"Filter Date"}
                    />
                </div>
                <div className='mt-3'>
                    {
                        role == 'Admin' && <div className=''>
                            <label><b>By Hospital :</b></label>
                            <Select
                                options={hospital}
                                className=''
                                placeholder={"Select Hospital"}
                                allowClear
                                onChange={( value ) => { setSelectedHospital( value ); }}
                            />
                        </div>
                    }
                </div>
                <div>
                    {selectedHospital?.length > 0 ? (
                        <div className='mt-3'>
                            <label><b>By Therapist :</b></label>
                            <Select
                                options={therapist}
                                className=''
                                placeholder={"Select a Therapist"}
                                allowClear
                                onChange={( value ) => { setSelectedTherapist( value ); }}
                            />
                        </div>
                    ) : ( <></> )}
                </div>
            </div>
            {/* for desktop */}
            <div className='flex gap-1'>
                {/* 1st col */}
                <div className='flex justify-center w-1/6 sm:w-full md:w-1/6'>
                    <div className='w-full h-1/3'>
                        <div className='border border-gray-300 rounded-lg shadow p-4 hidden sm:block'>
                            <div className='w-full bg-blue-500 flex justify-center items-center rounded-lg p-3 text-xl font-bold text-white shadow-md  transition-all duration-300 ease-in-out transform hover:scale-105'>
                                <label className='drop-shadow-lg'>Filters</label>
                            </div>
                            <div className='mt-3'>
                                <label><b>By Date :</b></label>
                                <DateRangePicker
                                    className='w-full border border-gray-300 rounded-lg p-2'
                                    hoverRange={'month'}
                                    oneTap
                                    showOneCalendar
                                    onChange={( value ) => handleCalender( value )}
                                    shouldDisableDate={date => isAfter( date, new Date() )}
                                    placeholder={"Filter Date"}
                                />
                            </div>
                            <div className='mt-3'>
                                {
                                    role == 'Admin' && <div className=''>
                                        <label><b>By Hospital :</b></label>
                                        <Select
                                            options={hospital}
                                            className='form-control'
                                            placeholder={"Select Hospital"}
                                            allowClear
                                            onChange={( value ) => { setSelectedHospital( value ); }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                {selectedHospital?.length > 0 ? (
                                    <div className='mt-3'>
                                        <label><b>By Therapist :</b></label>
                                        <Select
                                            options={therapist}
                                            className='form-control'
                                            placeholder={"Select a Therapist"}
                                            allowClear
                                            onChange={( value ) => { setSelectedTherapist( value ); }}
                                        />
                                    </div>
                                ) : ( <></> )}
                            </div>
                            <div>
                                {role == 'Admin' &&
                                    <div className='mt-3'>
                                        <label><b>By Rating :</b></label>
                                        <Select
                                            options={scores}
                                            className='form-control'
                                            placeholder={"Select Rating"}
                                            allowClear
                                            onChange={( value ) => { setRating( value ); }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* 2nd col */}
                <div className='md:w-4/6 sm:w-full flex border shadow rounded p-4'>
                    <div className='w-full'>
                        <div className='w-full bg-blue-500 flex justify-center items-center rounded-lg p-3 text-xl font-bold text-white shadow-md'>
                            <div className='ml-4'>FeedBack List : </div>
                        </div>
                        {feedBacks.length > 0 &&
                            <>
                                <div className='mt-2'>
                                    <table className='table table-auto shadow'>
                                        <thead>
                                            <tr>
                                                {/* <th>SR No.</th> */}
                                                <th>Name</th>
                                                <th>Date</th>
                                                <th>Therapist</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typeof ( feedBacks ) !== "undefined" && Object.values( feedBacks ).map( ( item, i ) => (
                                                <>
                                                    {/* {JSON.stringify( item )} */}
                                                    <tr key={i}>
                                                        <td>
                                                            <Link to={"#"} onClick={() =>
                                                            {
                                                                setModalData( item.feedbacks.feedback );
                                                                setVisible( true );
                                                            }}>
                                                                <div>{item.feedbacks.name}</div>
                                                            </Link>
                                                        </td>
                                                        <td>{item?.feedbacks.feedback?.createdAt}</td>
                                                        <td>{JSON.parse( item?.feedbacks?.therapist )[ 0 ]?.label}</td>
                                                        <td>
                                                            <div
                                                                className={item.feedbacks.feedback.id ? ( "bg-red-500 w-fit rounded p-2 text-white font-bold" ) : ( "bg-green-500 w-fit rounded p-2 text-white font-bold" )}
                                                            >
                                                                {item.feedbacks.feedback.id ? "Pending" : "Success"}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            ) )}
                                        </tbody>
                                    </table>
                                    {typeof ( feedBacks ) == "undefined" &&
                                        <div className='flex justify-center'>no record found</div>
                                    }
                                </div>
                                <div className='mt-2 flex justify-end'>
                                    {
                                        pageCount > 1 && (
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={pageCount}
                                                    color="primary"
                                                    showFirstButton
                                                    showLastButton
                                                    onChange={( event, value ) => setPage( value )}
                                                />
                                            </Stack>
                                        )
                                    }
                                </div>
                            </>
                        }
                        {err &&
                            <div className='flex justify-center items-center mt-5'>
                                {err}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal open={visible} onCancel={() =>
            {
                setVisible( false );
                setModalData( "" );
            }}
                footer={null}
            >
                <FeedBackModalShow
                    data={modalData}
                />
            </Modal>
        </Layout>
    );
}
