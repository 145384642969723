import { faAddressBook, faClock, faUser, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const momentTimezone = require( 'moment-timezone' );


export default function ScheduleCall( { timezone, handleSubmitCallSchdl, setValue, setSelected, emails, setEmails, focused, setFocused, setOk, ok } )
{
    useEffect( () =>
    {
        setSelected( timezone._id );
    }, [] );

    return (
        <div className='bg-success p-2 text-dark bg-opacity-10'>
            <div className='fs-2'>
                Schedule tele consultation
            </div>
            <div>
                <div className='card'>
                    <div className='card-header fs-6'>
                        Request Details
                    </div>
                    <div className='card-body'>
                        <div>
                            <div className='row'>
                                <div>
                                    <FontAwesomeIcon icon={faUser} />{"   "}{timezone.name}
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faAddressBook} />{"  "}{timezone.email}{"  |  "}{timezone.phone}
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faUserDoctor} />{"  "}{String( timezone.therapistArray ).split( 'label":"' )[ 1 ].split( '"}]' )}
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faClock} />{"  "}{momentTimezone.tz( timezone.timezone ).format( 'LLL' )}{" | "} ( PATIENT'S CURRENT LOCAL TIME )
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card mt-3'>
                    <div className='card-header fs-6 bg-warning'>
                        PLEASE SELECT ACCORDING TO PATIENT'S LOCAL TIME
                    </div>
                    <div className='card-body'>
                        <div>
                            <div className='row'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={[ 'DateTimePicker' ]}>
                                        <DateTimePicker
                                            label="Basic date time picker"
                                            disablePast
                                            onChange={( newValue ) => setValue( newValue )}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div className='mt-2'>
                                <ReactMultiEmail
                                    placeholder='Input guest emails'
                                    emails={emails}
                                    onChange={( _emails ) =>
                                    {
                                        setEmails( _emails );
                                    }}
                                    autoFocus={true}
                                    onFocus={() => setFocused( true )}
                                    onBlur={() => setFocused( false )}
                                    getLabel={( email, index, removeEmail ) =>
                                    {
                                        return (
                                            <div data-tag key={index}>
                                                <div data-tag-item>{email}</div>
                                                <span data-tag-handle onClick={() => removeEmail( index )}>
                                                    ×
                                                </span>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                            <div>
                                <div className='float-end mt-2'>
                                    <button
                                        onClick={() =>
                                        {
                                            setOk( true );
                                            handleSubmitCallSchdl();
                                        }}
                                        className={( () =>
                                        {
                                            if ( ok )
                                            {
                                                return ( 'btn btn-sm btn-primary disabled' );
                                            } else
                                            {
                                                return ( 'btn btn-sm btn-primary' );
                                            }
                                        } )()}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
