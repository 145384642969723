import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal, Tooltip } from 'antd';
import Table from 'react-bootstrap/Table';
import AddDiagnosticsModal from './AddDiagnosticsModal';
import axios from 'axios';
import toast from 'react-hot-toast';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function AddDiagnsotic()
{
    const [ visible, setVisible ] = useState( false );
    const [ diagnostic, setDiagnostic ] = useState( "" );
    const [ diagnosticList, setDiagnosticList ] = useState( [ { 'sub_name': '' } ] );
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;
    const [ diagnosticData, setDiagnosticData ] = useState( '' );
    const [ pageCount, setPageCount ] = useState( 0 );
    const [ page, setPage ] = useState( 1 );

    const handleSubmit = async () =>
    {
        try
        {
            const { data } = await axios.post( `${server}/api/medi/diagnostic/create-diagnostic`, { diagnostic, diagnosticList } );
            if ( data.success )
            {
                toast.success( data.message );
                setVisible( false );
                setDiagnostic( "" );
                setDiagnosticList( [ { 'sub_name': '' } ] );
                getDiagnosticList();
            } else
            {
                toast.error( data.message );
            }
        } catch ( err )
        {
            toast.error( err );
        }
    };

    const handleMoreDiagnostics = () =>
    {
        setDiagnosticList( [ ...diagnosticList, { 'sub_name': '' } ] );
    };

    const getDiagnosticList = async () =>
    {
        const { data } = await axios.get( `${server}/api/medi/diagnostic/diagnosticList?page=${page}` );
        if ( data.success )
        {
            setDiagnosticData( data.DiagnoasticList );
            setPageCount( data.pagination.pageCount );
        } else
        {
            toast.error( data.message );
        }
    };

    useEffect( () =>
    {
        getDiagnosticList();
    }, [ page ] );

    return (
        <Layout>
            <div className='row'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-xl-2'>
                        </div>
                        <div className='col-12 col-xl-8'>
                            <div className='d-flex'>
                                <h2>
                                    <FontAwesomeIcon icon={faStethoscope} size={'lg'} />
                                    &nbsp;Diagnostics Master</h2>
                            </div>
                            <hr />
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-xl-10 col-12'>
                                                    <b>Manage Diagnostics</b>
                                                </div>
                                                <div className='col-xl-2 col-12'>
                                                    <button className='btn btn-success btn-sm ' onClick={() =>
                                                    {
                                                        setVisible( true );
                                                    }}>
                                                        Add Diagnostic
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values( diagnosticData ).map( ( item, i ) => (
                                                <tr key={i}>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <Tooltip
                                                                    placement={'bottomRight'}
                                                                    title={'edit diagnostic'}
                                                                >
                                                                    <button
                                                                        className='btn btn-success btn-sm'
                                                                    ><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                                </Tooltip>
                                                                <Tooltip placement={'bottomRight'} title={'delete diagnostic'}>
                                                                    <button className='btn btn-danger btn-sm mx-1'><FontAwesomeIcon icon={faTrash} /></button>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='float-end mt-2' >
                {pageCount > 1 && (
                    <Stack spacing={2}>
                        <Pagination
                            count={pageCount}
                            showFirstButton
                            showLastButton
                            color='primary'
                            onChange={( event, value ) => setPage( value )}
                        />
                    </Stack>
                )}
            </div>
            <Modal open={visible} onCancel={() =>
            {
                setVisible( false );
            }} footer={null}>
                <AddDiagnosticsModal
                    diagnostic={diagnostic}
                    setDiagnostic={setDiagnostic}
                    handleSubmit={handleSubmit}
                    diagnosticList={diagnosticList}
                    setDiagnosticList={setDiagnosticList}
                    handleMoreDiagnostics={handleMoreDiagnostics}
                />
            </Modal>
        </Layout>
    );
}
