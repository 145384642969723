import React, { useState } from 'react';
import Layout from '../component/layout/Layout';
import axios from "axios";
import "../../src/style/AuthStyle.css";
import { toast } from 'react-hot-toast';
import { useAuth } from '../component/context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import login from "../style/login.json";
import Lottie from "lottie-react";
// import axiosInstance from "../lib/axiosInstance.js";

export default function Home()
{
    /* variables */
    const [ email, setemail ] = useState( "" );
    const [ password, setPassword ] = useState( "" );
    const [ auth, setAuth ] = useAuth();
    const navigate = useNavigate();
    const [ height, setHeight ] = useState( window.innerHeight );

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    /* handle form submit */
    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();
        try
        {
            /* axios post request */
            const res = await axios.post( `${server}/api/medi/auth/login`, { email, password } );
            // console.log( res.data.success );
            /* validation */
            if ( res.data.success )
            {
                toast.success( res.data.message );
                setAuth( {
                    ...auth,
                    user: res.data.user,
                    token: res.data.token
                } );
                localStorage.setItem( 'auth', JSON.stringify( res.data ) );
                if ( res.data.user.roleName === 'Admin' || res.data.user.roleName === "Therapist" )
                {
                    navigate( "/dashboard/admin" );
                }
                else if ( res.data.user.roleName === 'Hospital' )
                {
                    navigate( "/hospital/dash" );
                }
                else if ( res.data.user.roleName === 'Patient' )
                {
                    navigate( "/patient/dash" );
                }
            }
            if ( !res.data.success )
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };


    return (
        <>
            <Layout>
                <div className='card shadow'>
                    <div className='row'>
                        <div className='col-4 col-xl-2'>

                        </div>
                        <div className='col-4 col-xl-4 p-3'>
                            <Lottie animationData={login} />
                        </div>
                        <div className='col-12 col-xl-6'>
                            <div className='form-container'>
                                <div className='card'>
                                    <div className='card-header text-center'>
                                        Login
                                    </div>
                                    <form onSubmit={handleSubmit} className='border p-3 rounded'>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                onChange={( e ) => setemail( e.target.value )} value={email} required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                            <input type="password" className="form-control" id="exampleInputPassword1"
                                                onChange={( e ) => setPassword( e.target.value )} value={password} required />
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Link className='float-end' to={"/forget-password"}>Forget Password</Link>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='text-center mt-3'>
                                                <button type="submit" className="btn btn-primary col-6">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </>
    );
}
