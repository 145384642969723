import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useAuth } from '../component/context/AuthContext';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Modal, Tooltip } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch, faCirclePlay, faCommentsDollar, faSearchDollar, faBox, faStop } from '@fortawesome/free-solid-svg-icons';
import { uid } from 'uid';
import { startSession, endSession, resetSession } from '../redux/sessionReducer';
import SessionNote from './admin/SessionNote';
import moment from "moment";


export default function Session()
{
    let currentDate = new Date().toUTCString();

    const sessions = useSelector( state => state.session.sessions );
    const [ page, setPage ] = useState( 1 );
    const [ pageCount, setPageCount ] = useState( 0 );
    const [ query, setQuery ] = useState( '' );
    const [ userId, setUserId ] = useState( '' );
    const [ type, setType ] = useState( '' );
    const [ visible, setVisible ] = useState( false );
    const [ time, setTime ] = useState( 0 );
    const [ selected, setSelected ] = useState( '' );
    const [ sessionDone, setSessionDone ] = useState( '' );
    const [ typeNew, setTypeNew ] = useState( '' );
    const [ sessionTextArea, setSessionTextArea ] = useState( "" );
    const [ sessionList, setSessionList ] = useState( [] );
    const [ date, setDate ] = useState( "" );
    const [ advance, setAdvance ] = useState( 0 );
    const [ patientDetail, setPatientDetail ] = useState( '' );
    const [ totalAmount, setTotalAmount ] = useState( "" );
    const [ advanceNew, SetAdvanceNew ] = useState( 0 );
    const [ paymentHistory, setPaymentHistory ] = useState( [] );
    const [ selectedPatient, setSelectedPatient ] = useState( "" );
    const [ paymentId, setPaymentId ] = useState( '' );
    const [ selectedImage, setSelectedImage ] = useState( [] );
    const [ patientProgress, setPatientProgress ] = useState( '' );
    const [ timeFinal, setTimeFinal ] = useState( 0 );
    const [ auth ] = useAuth();
    const dispatch = useDispatch();
    const [ milliSec, setMilliSec ] = useState( 0 );

    let capabilities = [ "" ];
    capabilities = auth?.user?.capabilities;

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    const handleSession = async () =>
    {
        try
        {
            setVisible( false );
            setTypeNew( 'note' );
            setVisible( true );
        } catch ( error )
        {
            toast.error( "something went wrong while session done" );
        }
    };

    const handleSessionNote = async () =>
    {
        try
        {
            const sessionCount = sessionDone;
            const sessionIncr = Number( sessionCount ) + 1;

            const form = new FormData();
            let arrImages = [];
            await Promise.all( Object.values( selectedImage ).map( ( item, i ) => (
                ( async () =>
                {
                    // getting aws upload url from aws 
                    const { data } = await axios.get( `${server}/s3Url/session` );
                    // uploading images to aws s3 bucket 
                    await fetch( data, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                        body: item
                    } );
                    // getting image name
                    const imageUrl = data.split( '?' )[ 0 ];
                    arrImages.push( imageUrl );
                }
                )() ) ) );

            // const sessionData = {
            //     'id': uid( 16 ),
            //     'time': time, 'sessionDone': sessionIncr, 'sessionTextArea': sessionTextArea, 'patientProgress': patientProgress,
            //     'date': new Date().toLocaleDateString(), 'materialDate': date, 'therapist': auth?.user?.name
            // };
            const sessionData = {
                'id': uid( 16 ), 'dummy': 'key', 'milliSec': milliSec,
                'time': timeFinal, 'sessionDone': sessionIncr, 'sessionTextArea': sessionTextArea, 'patientProgress': patientProgress,
                'date': moment().format(), 'materialDate': date, 'therapist': auth?.user?.name, "therapistID": auth?.user?.id
              };

            form.append( 'sessionData', JSON.stringify( sessionData ) );
            form.append( 'images', JSON.stringify( arrImages ) );

            const res = await axios.post( `${server}/api/medi/patient/session-done/${selected}`, form );
            if ( res.data.success )
            {
                toast.success( res.data.message );
                setVisible( false );
                setTime( '' );
                setSessionTextArea( '' );
                setSessionDone( "" );
                setVisible( false );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "Something went wrong while starting session" );
        }
    };

    return (
        <div className='mt-2'>
            {/* {JSON.stringify( sessions )} */}
            {sessions.length > 0 ? ( <div>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Running Sessions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.values( sessions ).map( ( it, k ) => (
                            <Tr key={k}>
                                <Td>
                                    {it.name}
                                </Td>
                                <Td>
                                    {( () =>
                                    {
                                        if ( Object.values( sessions ).filter( item => item.id === it.id ).length > 0 )
                                        {
                                            return (
                                                ( capabilities?.includes( 'start-session' ) )
                                                    ? (
                                                        <div className='row'>

                                                            <div className='col-auto'>
                                                                <div className='border rounded float-end ' >
                                                                    <button
                                                                        className='btn btn-sm btn-danger form-control'
                                                                        onClick={() =>
                                                                        {
                                                                            setTimeFinal( () =>
                                                                            {
                                                                                let oldTime = Object.values( sessions ).find( item => item.id === it.id )[ 'sessionTime' ];
                                                                                let newTime = moment().format();
                                                                                let sessionTime = moment.duration( moment( newTime ).diff( oldTime ) );
                                                                                setMilliSec( sessionTime._milliseconds );
                                                                                let timeDuration = sessionTime._data.hours + " hrs: " + sessionTime._data.minutes + " min: " + sessionTime._data.seconds + " sec";
                                                                                return (
                                                                                    timeDuration
                                                                                );
                                                                            } );

                                                                            dispatch( endSession( {
                                                                                id: it.id
                                                                            } ) );
                                                                            handleSession();
                                                                            setSelected( it.id );
                                                                            setSessionDone( it.sessionDone );
                                                                        }}
                                                                    >
                                                                        <Tooltip placement="bottomRight" title={"End Session"} >
                                                                            <FontAwesomeIcon icon={faStop} />
                                                                        </Tooltip>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    : ( <></> )
                                            );
                                        }
                                    } )()}
                                </Td>
                            </Tr>
                        ) )}
                    </Tbody>
                </Table>
            </div> ) : ( "No Running Sessions" )}

            <Modal
                open={visible}
                onCancel={() =>
                {
                    setVisible( false );
                    setPaymentHistory( '' );
                    setSelectedPatient( '' );
                    setAdvance( 0 );
                    setTotalAmount( '' );
                    SetAdvanceNew( 0 );
                    setPaymentId( '' );
                    setSelected( '' );
                    setSessionList( '' );
                }}
                footer={null}
                destroyOnClose={true}
                maskClosable={( () =>
                {
                    if ( typeNew === 'session' )
                    {
                        return ( false );
                    } else
                    {
                        return ( true );
                    }
                } )()}
                // width={1000}
                width={( () =>
                {
                    if ( typeNew === 'invoice' )
                    {
                        return ( 1000 );
                    }
                    else
                    {
                        return ( 600 );
                    }
                } )()}
            >
                {( () =>
                {
                    if ( typeNew === 'note' )
                    {
                        return (
                            <SessionNote
                                sessionTextArea={sessionTextArea} setSessionTextArea={setSessionTextArea}
                                handleSessionNote={handleSessionNote} date={date} setDate={setDate}
                                selectedImage={selectedImage} setPatientProgress={setPatientProgress}
                                setSelectedImage={setSelectedImage} patientProgress={patientProgress}
                            />
                        );
                    }
                } )()}

            </Modal>
        </div>
    );
}



