import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout.js';
import toast from 'react-hot-toast';
import PasswordChecklist from "react-password-checklist";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function CreateNewPassword()
{
    const [ password, setPassword ] = useState( "" );
    const [ passwordAgain, setPasswordAgain ] = useState( "" );
    const [ email, setEmail ] = useState( '' );
    const [ flag, setFlag ] = useState( false );
    const navigate = useNavigate();

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    const queryParams = new URLSearchParams( window.location.search );

    useEffect( () =>
    {
        setEmail( queryParams.get( 'email' ) );
    }, [ queryParams ] );

    const handleSubmit = async () =>
    {
        try
        {
            const response = await axios.post( `${server}/api/medi/auth/reset-password`, { email, password } );
            if ( response.data.success )
            {
                toast.success( response.data.message );
                navigate( "/" );
            } else
            {
                toast.error( response.data.message );
            }
        } catch ( error )
        {
            toast.error( error );
        }
    };
    return (
        <div>
            <Layout>
                <div className='mt-36'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-4'></div>
                                <div className='col-4'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            Create New Password
                                        </div>
                                        <div className='card-body'>
                                            <form className='border p-3 rounded'>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">New Password</label>
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        onChange={( e ) => setPassword( e.target.value )} value={password} required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputPassword1" className="form-label">Repeat New Password</label>
                                                    <input type="password" className="form-control" id="exampleInputPassword1"
                                                        onChange={( e ) => setPasswordAgain( e.target.value )} value={passwordAgain} required />
                                                </div>
                                                <PasswordChecklist
                                                    rules={[ "minLength", "specialChar", "number", "capital", "match" ]}
                                                    minLength={6}
                                                    value={password}
                                                    valueAgain={passwordAgain}
                                                    onChange={( isValid ) =>
                                                    {
                                                        setFlag( isValid );
                                                    }}
                                                />
                                                <div className='row'>
                                                    <div className='text-center mt-3'>
                                                        <button onClick={() => handleSubmit()} type="submit" className={( () =>
                                                        {
                                                            if ( flag )
                                                            {
                                                                return ( "btn btn-primary col-6" );
                                                            } else
                                                            {
                                                                return ( "btn btn-primary col-6 disabled" );
                                                            }
                                                        } )()}>Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}
