import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';

export default function FeedBackLayout()
{
  const [ selectedImage, setSelectedImage ] = useState( [] );
  const [ err, setError ] = useState( false );
  const [ show, setShow ] = useState( true );
  const [ data, setData ] = useState( {} );
  const [ rating, setRating ] = useState( 0 );

  const server = `${process.env.REACT_APP_AWS_LAMBDA}`;
  const imgTypes = [ 'image/jpeg', 'video/mp4' ];
  const imageref = useRef( null );
  const navigate = useNavigate();
  const { pid, id } = useParams();

  const scores = {
    "no-change": 1,
    "slight-change": 3,
    "visible-improvement": 5,
  };

  // Function to calculate the total or average rating
  const calculateOverallRating = ( updatedData, scores ) =>
  {
    const totalMovements = Object.keys( updatedData ).length;
    const totalScore = Object.values( updatedData ).reduce( ( acc, val ) => acc + scores[ val ], 0 );

    // Calculate the average rating if needed
    const avgRating = totalMovements > 0 ? totalScore / totalMovements : 0;

    // Set the rating state
    setRating( avgRating );
  };

  // parameters 
  const movement = [
    "Shoulder Pain", "Shoulder Range of Motion", "Elbow Stiffness", "Wrist Movement",
    "Finger Movement", "Trunk Balance", "Hip Rotation", "Knee Flexion and Extension",
    "Ankle Stiffness", "Ankle Mobility", "Overall Leg Strength"
  ];

  // submit feedback
  const handleSubmit = async ( e ) =>
  {
    e.preventDefault();
    try
    {
      if ( Object.entries( data ).length === 0 )
      {
        toast.error( "Please select at least one option" );
        setError( true );
      } else
      {
        setShow( false );
        let arrImages = [];

        await Promise.all( Object.values( selectedImage ).map( ( item, i ) => (
          ( async () =>
          {
            const { data } = await axios.get( `${server}/s3Url/session` );
            await fetch( data, {
              method: "PUT",
              headers: {
                "Content-Type": "multipart/form-data"
              },
              body: item
            } );
            const imageUrl = data.split( '?' )[ 0 ];
            arrImages.push( imageUrl );
          } )()
        ) ) );

        let finalData = {};
        finalData = { ...data, [ "imageURL" ]: arrImages, [ "rating" ]: rating };

        const res = await axios.post( `${server}/api/medi/patient/feedbackSave/${pid}/${id}`, { finalData } );
        if ( res.data.success )
        {
          toast.success( res.data.message );
          navigate( '/feedbackSubmittedMsg' );
        } else
        {
          toast.error( res.data.message || "An error occurred. Please try again." );
        }
      }
    } catch ( error )
    {
      toast.error( "An error occurred. Please try again." );
      console.error( error );
    }
  };

  // convert to camel case
  const camelCase = ( str ) =>
  {
    return str.replace( /(?:^\w|[A-Z]|\b\w)/g, ( word, index ) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    ).replace( /\s+/g, '' );
  };

  useEffect( () =>
  {
    setError( false );
  }, [ data ] );

  // upload images/vides to aws s3 
  const imagehandle = ( e ) =>
  {
    let flag = true;
    if ( e.target.files && e.target.files.length > 0 )
    {
      const validFiles = Array.from( e.target.files ).filter( ( item ) =>
      {
        if ( item.size > 52428800 )
        {
          toast.error( "File size must be less than 50 MB" );
          flag = false;
          return false;
        }
        if ( !imgTypes.includes( item.type ) )
        {
          toast.error( "File type must be JPEG or MP4 only" );
          flag = false;
          return false;
        }
        return true;
      } );
      if ( flag )
      {
        setSelectedImage( ( prev ) => [ ...prev, ...validFiles ] );
      }
    }
  };

  // remove video from selection array
  const removeFile = ( index ) =>
  {
    setSelectedImage( ( prev ) => prev.filter( ( _, i ) => i !== index ) );
  };

  return (
    <>
    <div className='flex justify-center items-center p-4 sm:p-6 lg:p-8'>
      <div className='w-full max-w-4xl bg-white shadow-lg rounded-lg p-4 sm:p-6 lg:p-8'>
        <div className='border rounded-lg p-3'>
          <div className='text-center mb-4 text-xl sm:text-2xl font-bold text-gray-800'>PROGRESS NOTES</div>
          <form onSubmit={handleSubmit}>
            <div className='overflow-x-auto'>
              <table className='table-auto w-full'>
                <thead>
                  <tr className='bg-gray-100 text-xs sm:text-sm'>
                    <th className='border border-gray-200 p-2 text-center'>S.NO</th>
                    <th className='border border-gray-200 p-2'>Type of Movement</th>
                    <th className='border border-gray-200 p-2 text-center'>No Change</th>
                    <th className='border border-gray-200 p-2 text-center'>Slight Change</th>
                    <th className='border border-gray-200 p-2 text-center'>Visible Improvement</th>
                  </tr>
                </thead>
                <tbody>
                  {movement.map((item, index) => (
                    <tr key={index} className="bg-white border-b hover:bg-gray-50 text-xs sm:text-sm">
                      <td className="border border-gray-200 text-center p-2">{index + 1}</td>
                      <td className="border border-gray-200 p-2">{item}</td>
                      <td className="border border-gray-200">
                        <label className="flex justify-center items-center">
                          <input
                            type="radio"
                            name={`progress-${index}`}
                            value="no-change"
                            onChange={() => {
                              setData((prev) => ({ ...prev, [camelCase(item)]: "no-change" }));
                              calculateOverallRating({ ...data, [camelCase(item)]: "no-change" }, scores);
                            }}
                            className="form-radio text-blue-600"
                          />
                        </label>
                      </td>
                      <td className="border border-gray-200">
                        <label className="items-center justify-center flex">
                          <input
                            type="radio"
                            name={`progress-${index}`}
                            value="slight-change"
                            onChange={() => {
                              setData((prev) => ({ ...prev, [camelCase(item)]: "slight-change" }));
                              calculateOverallRating({ ...data, [camelCase(item)]: "slight-change" }, scores);
                            }}
                            className="form-radio text-yellow-600"
                          />
                        </label>
                      </td>
                      <td className="border border-gray-200">
                        <label className="flex justify-center items-center">
                          <input
                            type="radio"
                            name={`progress-${index}`}
                            value="visible-improvement"
                            onChange={() => {
                              setData((prev) => ({ ...prev, [camelCase(item)]: "visible-improvement" }));
                              calculateOverallRating({ ...data, [camelCase(item)]: "visible-improvement" }, scores);
                            }}
                            className="form-radio text-green-600"
                          />
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <hr className='w-full bg-gray-300 h-1 my-4' />
            <div className='relative'>
              <div className='absolute -top-4 left-4 border p-1 rounded-md bg-slate-100 font-bold'>Write Feedback</div>
              <div>
                <textarea
                  placeholder='Tell us more about your experience'
                  className='p-4 border w-full rounded-lg h-24'
                  onChange={(e) => setData((prev) => ({ ...prev, [camelCase("Written FeedBack")]: e.target.value }))}
                />
              </div>
            </div>
            <hr className='w-full bg-gray-300 h-1 my-4' />
            <div className='text-center'>
              <div className='font-bold text-xl mb-2'>Upload Video or Image</div>
              <div className='text-gray-600 mb-4'>Please select a JPEG image or MP4 video file (max size: 50 MB).</div>
              <input
                type='file'
                className='mt-2'
                accept="image/jpeg, video/mp4"
                ref={imageref}
                multiple={true}
                onChange={imagehandle}
              />
              <div className='flex flex-wrap justify-center mt-4'>
                {selectedImage.map((item, index) => (
                  <div key={index} className="w-1/2 sm:w-1/4 p-2">
                    {item.type.startsWith("image") ? (
                      <img src={URL.createObjectURL(item)} alt="" className="w-full h-full object-cover" />
                    ) : (
                      <video src={URL.createObjectURL(item)} controls className="w-full h-full object-cover"></video>
                    )}
                    <button className='p-1 w-full bg-red-600 text-white rounded mt-2' onClick={() => removeFile(index)}>Remove</button>
                  </div>
                ))}
              </div>
            </div>
            <hr className='w-full bg-gray-300 h-1 my-4' />
            <div className='flex justify-center'>
              <button type="submit" className='bg-blue-500 text-white p-2 rounded-lg font-bold hover:bg-blue-600'>
                Submit Feedback
              </button>
            </div>
            {err && <p className="text-red-600 font-bold mt-2">Please fill out the form completely.</p>}
          </form>
        </div>
      </div>
    </div>
  </>
  
  );
}

