import Header from './Header';
import Footer from './Footer';
import { Toaster } from 'react-hot-toast';
import AdminMenu from '../../pages/admin/AdminMenu';
import { useAuth } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export default function Layout( { children } )
{
  const [ auth ] = useAuth();
  const location = useLocation();

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <Header />
        </div>
      </div>
      <div className='row'>
        <div className={!auth?.user || location.pathname === "/" || location.pathname === '/patient-register' ? ( "col-12" ) : ( "col-12" )}>
          <main className='m-3' style={{
             minHeight: 
             "78vh"
            // height
              }}>
            <Toaster />
            {children}
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
