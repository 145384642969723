import React, { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactPlayer from 'react-player';

export default function ImageModal( { image } )
{
    const [ type, setType ] = useState( 'image' );
    // console.log( image );

    useEffect( () =>
    {
        const post = image?.split( '.' )[ 1 ];
        if ( post !== 'mp4' )
        {
            setType( 'image' );
        }
        if ( post === 'mp4' )
        {
            setType( 'video' );
        }
    }, [ image ] );

    const pre = `${process.env.REACT_APP_SESSION_IMAGE_PATH}`;

    return (
        <div className='container'>
            {( () =>
            {
                if ( type === 'image' )
                {
                    return (
                        <TransformWrapper>
                            <TransformComponent>
                                <img src={image} className="d-block w-100" alt="session-image" style={{ maxHeight: '1000px' }} />
                            </TransformComponent>
                        </TransformWrapper>
                    );
                }
                if ( type === 'video' )
                {
                    return (
                        <ReactPlayer playing className='player-wrapper' url={image} width="100%" height="100%" controls={true} />
                    );
                }
            } )()}

        </div>
    );
}
