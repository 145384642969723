import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Layout from '../../component/layout/Layout.js';


export default function ForgetPassword()
{


    const [ email, setEmail ] = useState( '' );
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    const navigate = useNavigate();

    const handleSubmit = async () =>
    {
        try
        {
            const { data } = await axios.post( `${server}/api/medi/auth/email-verfication`, { email } );
            if ( data?.success )
            {
                toast.success( data?.message );
                navigate( `/otp-verification?email=${email}` );
            } else
            {
                toast.error( data?.message );
            }
        } catch ( error )
        {
            toast.error( error );
        }
    };

    return (
        <>
            <Layout>
                <div className='flex justify-center items-center h-[calc(78vh-50px)] border'>
                    <section className="rounded-md  p-2">
                        <div className="flex items-center justify-center bg-white px-4 py-10 sm:px-6 sm:py-16 lg:px-8">
                            <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">

                                <h2 className="text-2xl font-bold leading-tight text-black">
                                    Forget Password
                                </h2>

                                <p className="mt-2text-sm text-gray-600 ">
                                    Please enter your registered email address for OTP{" "}

                                </p>
                                <form action="#" method="POST" className="mt-8">
                                    <div className="space-y-5">
                                        <div>
                                            <label htmlFor="" className="text-base font-medium text-gray-900">
                                                {" "}
                                                Email address{" "}
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                                                    type="email"
                                                    onChange={( e ) => setEmail( e.target.value )}
                                                    placeholder="Email"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                onClick={() => handleSubmit()}
                                                type="button"
                                                className="inline-flex w-full items-center justify-center rounded-md bg-black px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                                            >
                                                Submit{" "}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="ml-2"
                                                >
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                    <polyline points="12 5 19 12 12 19"></polyline>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>

                </div>
            </Layout>

        </>
    );
}
