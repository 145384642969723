import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import SessionNote from './SessionNote';
import { Modal } from 'antd';
import moment from "moment";
import axios from 'axios';
import { uid } from 'uid';
import './room.css';
import toast from 'react-hot-toast';
import { meetingToken, resetToken, startSession } from '../../redux/sessionReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faPhone, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import CallMsg from './CallMsg';
import { useNavigate } from 'react-router-dom';

let EnxRtc = window.EnxRtc;


export default function Room( props )
{
    const queryParams = new URLSearchParams( window.location.search );

    const [ therapistName, setTherapistName ] = useState( "" );
    const [ patientName, setPatientName ] = useState( "" );
    const [ patientID, setPatientID ] = useState( "" );
    const [ patient, setPatient ] = useState( "" );
    const [ role, setRole ] = useState( "" );
    const [ errorToken, setErrorToken ] = useState( '' );
    const [ token, setToken ] = useState( "" );
    const [ flag, setFlag ] = useState( false );
    const [ icon, setIcon ] = useState( faMicrophone );
    const [ videoIcon, setVideoIcon ] = useState( faVideo );
    const [ visible, setVisible ] = useState( false );
    const [ redirect, setredirect ] = useState( false );
    const [ activeList, setActiveList ] = useState( 0 );

    const navigate = useNavigate();
    let nav = false;

    let msg = ( role === "moderator" ) ? "Please stay, Patient will join in shortly...." : "Please stay, Therapist will join in shortly....";

    const dispatch = useDispatch();
    let { roomId } = useParams();

    let sessions = '';
    sessions = useSelector( state => state.session.token );
    const [ sessionDataRedux, setSessionData ] = useState( sessions );


    useEffect( () =>
    {
        setSessionData( sessions );
    }, [ sessions ] );

    useEffect( () =>
    {
        setPatientID( queryParams.get( "pid" ) );
        setRole( queryParams.get( "role" ) );
    }, [ queryParams ] );


    const handleSubmit = async () =>
    {
        try
        {
            if ( roomId.length > 0 )
            {
                if ( patientName.length > 0 )
                {
                    if ( therapistName.length > 0 )
                    {
                        if ( token.length === 0 )
                        {

                            nav = true;
                            const res = await axios.post( `${server}/api/medi/vcall/create-token`, {
                                roomId,
                                role: ( () =>
                                {
                                    if ( role === "participant" )
                                    {
                                        return role;
                                    }
                                    else if ( role === 'moderator' )
                                    {
                                        return role;
                                    }
                                    else return "participant";
                                } )(),
                                name: ( () =>
                                {
                                    if ( role === "participant" ) return patientName;
                                    else if ( role === "moderator" ) return therapistName;
                                    else return role;
                                } )(),
                                user_ref: ( () =>
                                {
                                    if ( role === "participant" ) return patientName;
                                    else if ( role === "moderator" ) return therapistName;
                                    else return role;
                                } )()
                            } );
                            if ( res.status === 200 )
                            {
                                if ( res?.data?.res?.token )
                                {
                                    setToken( res?.data?.res?.token );
                                    // navigate( `/meetingRoom/${res?.data?.token}?pid=${patientID}&role=${role}&roomId=${roomId}` );

                                } else if ( res?.data?.error.error )
                                {
                                    console.log( res?.data?.error.error );
                                    // setError( res?.data?.error );
                                    toast.error( res?.data?.error.error );
                                    setErrorToken( res?.data?.error.error );
                                    setFlag( true );
                                }
                            } else if ( res.error )
                            {
                                console.log( res?.data?.error.message );
                                // setError( res?.data?.error );
                                toast.error( res?.data?.error.message );
                            }

                        }
                    }
                }
            }
        } catch ( error )
        {
            console.log( error );
            toast.error( error );
        }
    };

    useEffect( () =>
    {
        handleSubmit();
    }, [ roomId, patientName, therapistName ] );

    let ATList = [];
    let audio_muted = false;
    let video_muted = false;
    let video_type = "SD";

    let room = null;
    let SubscribedStreamMap = new Map();
    let localStream,
        remote_view,
        sAMute = true,
        sVMute = true,
        rAmute = true,
        rVMute = true;

    let optionsLocal;
    let remoteOptions;
    let isModerator;
    let VideoSize = {
        'HD': [ 320, 180, 1280, 720 ],
        'SD': [ 640, 480, 640, 480 ],
        'LD': [ 80, 45, 640, 360 ]
    };
    let config = {
        video: true,
        audio: true,
        data: true,
        videoSize: VideoSize[ video_type ],
    };

    optionsLocal = {
        player: {
            height: "150px",
            width: "150px",
            minHeight: "150px",
            minWidth: "150px",
        },
        toolbar: {
            displayMode: false,
        },
        resizer: false,
    };
    remoteOptions = {
        player: {
            height: "100%",
            width: "100%",
        },
        resizer: false,
        toolbar: {
            displayMode: false,
        },
    };

    useEffect( () =>
    {
        if ( token?.length > 0 )
        {
            if ( role.length > 0 )
            {
                joinRoom( role, patientID, roomId );
            }
        }
    }, [ token ] );

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    const patientDetails = async () =>
    {
        try
        {
            if ( patientID?.length > 0 )
            {
                const res = await axios.get( `${server}/api/medi/patient-dash/details/${patientID}` );
                if ( res.status === 200 )
                {
                    setPatient( res.data.patient );
                    setPatientName( res.data.patient.name );
                    if ( res.data.patient?.therapistArray !== undefined )
                    {
                        setTherapistName( JSON.parse( res.data.patient?.therapistArray )[ 0 ]?.label );
                    }
                    else
                    {
                        setTherapistName( res.data.patient?.trial[ 0 ][ 0 ].therapistName );
                    }
                }
            }
        } catch ( error )
        {
            console.log( error );
        }
    };

    useEffect( () =>
    {
        patientDetails();
        if ( role === "moderator" )
            dispatch( startSession( {
                id: patientID,
                sessionTime: moment().format(),
                name: patient?.name,
                sessionDone: patient.sessionDone
            } ) );
        // console.log(patientID)
    }, [ patientID ] );
    // var icon = "" 

    let enableMuteButton = ( muteUnmuteBtn ) =>
    {
        muteUnmuteBtn.removeAttribute( "disabled" );
        muteUnmuteBtn.style.cursor = "pointer";
        muteUnmuteBtn.style.pointerEvents = "auto";
    };

    let goFlag = 0;

    let joinRoom = ( role, patientID, roomId ) =>
    {
        console.log( 'joining the room...' );
        document.querySelector( "#local_view" ).classList.add( "local_class_peep" );
        document.querySelector( "#remote_view" ).classList.add( "remote_class_peep" );
        let muteUnmuteBtn = document.querySelector( "#self_vMute" );
        EnxRtc.Logger.setLogLevel( 0 );


        localStream = EnxRtc.joinRoom( token, config, function ( success, error )
        {

            if ( error && error == null )
            {
                document.querySelector( ".error_div" ).innerHTML = "Room connection error." + error.message;
            }
            // if room connects successfully
            if ( success && success !== null )
            {

                //play local view
                localStream.play( "self-view", optionsLocal );

                // assigning room object to a variable
                room = success.room;
                // console.log( room );
                // check if the user joined as moderator or participant
                isModerator = room.me.role === "moderator" ? true : false;
                var ownId = success.publishId;
                for ( var i = 0; i < success.streams.length; i++ )
                {
                    room.subscribe( success.streams[ i ] );
                }
                // Active talkers handling
                room.addEventListener( "active-talkers-updated", function ( event )
                {
                    console.log( "Active Talker List :- " + JSON.stringify( event ) );
                    var video_player_len = document.querySelector( "#call_div" ).childNodes;

                    ATList = event.message.activeList;
                    setActiveList( event.message.activeList );

                    console.log( event, "event" );
                    console.log( ATList, "ATList" );

                    if (
                        event.message &&
                        event.message !== null &&
                        event.message.activeList &&
                        event.message.activeList !== null
                    )
                    {
                        if ( ATList.length === 0 )
                        {
                            setVisible( true );
                            document.querySelector( "#call_div" ).innerHTML = "";
                            document.querySelector( ".remote-name" ).innerText = "";
                        }
                        if ( ATList.length > 0 )
                        {
                            setVisible( false );
                        }
                        if ( SubscribedStreamMap.size > 0 )
                        {
                            if ( video_player_len.length >= 1 )
                            {
                                return;
                            } else
                            {

                                for ( var stream in room.remoteStreams.getAll() )
                                {
                                    var st = room.remoteStreams.getAll()[ stream ];

                                    for ( var j = 0; j < ATList.length; j++ )
                                    {
                                        if ( ATList[ j ].streamId === st.getID() )
                                        {
                                            remote_view = st;
                                            document.querySelector( ".self-name" ).innerHTML = room.me.name;
                                            st.play( "call_div", remoteOptions );
                                            document.querySelector( ".remote-name" ).innerHTML = ATList[ j ].name;
                                        }
                                    }
                                }
                            }
                        }
                    }
                } );

                // // room recording start  notification
                // room.addEventListener( "room-record-on", function ()
                // {
                //     document.querySelector( "#rec_notification" ).style.display = 'block';
                // } );
                // // room recording stop  notification
                // room.addEventListener( "room-record-off", function ()
                // {
                //     document.querySelector( "#rec_notification" ).style.display = 'none';
                // } );

                room.addEventListener( "stream-subscribed", function ( streamEvent )
                {
                    console.log( 'stream-subscribed-called' );
                    if ( streamEvent.stream.getID() !== ownId )
                    {
                        SubscribedStreamMap.set(
                            streamEvent.stream.getID(),
                            streamEvent.stream
                        );
                    }
                } );
                // user disconnection notification
                room.addEventListener( "user-disconnected", function ( streamEvent )
                {
                    document.querySelector( "#call_div" ).innerHTML = "";
                    document.querySelector( ".remote-name" ).innerText = "";
                } );
                // room disconnected notification
                room.addEventListener( "room-disconnected", function ( streamEvent )
                {
                    if ( role === 'moderator' )
                    {
                        if ( goFlag === 0 )
                        {
                            window.location.href = `/meetingPrescription?pid=${patientID}&role=${role}&roomId=${roomId}`;
                        } else
                        {
                            window.location.reload();
                        }
                    }
                    else
                    {
                        window.location.href = "/meetingOver";
                    }
                } );

                // console.log( room, "roooooooom" );
                // window.addEventListener( "unload", () =>
                // {
                //     alert( 'hiiii' );
                //     room.disconnect();
                // } );

                window.addEventListener( "beforeunload", ( event ) =>
                {
                    console.log( "Tab is about to be closed." );
                    // Your code to handle the event, such as disconnecting from a service or saving data
                    room.disconnect();

                    // Optionally, prevent the default unload behavior (not always needed in modern browsers)
                    event.preventDefault();
                    // Chrome requires setting returnValue for custom messages, though most browsers ignore custom messages now
                    event.returnValue = 'hiiii';
                } );


                window.addEventListener( "popstate", () =>
                {
                    goFlag = 1;
                    room.disconnect();
                } );
            }
        } );

        // self stream audio mute/unmute  function
        document.querySelector( "#self_aMute" ).addEventListener( 'click', function ( e )
        {
            if ( audio_muted )
            {
                if ( room?.mute )
                {
                    console.log( "Your audio is muted by moderator" );
                } else
                {
                    localStream.unmuteAudio( function ( arg )
                    {
                        console.log( localStream, "localStream_Unmute" );
                        setIcon( faMicrophone );
                        document.querySelector( "#self_aMute" ).classList.remove( "fa-microphone-slash" );
                        document.querySelector( "#self_aMute" ).classList.add( "fa-microphone" );
                        audio_muted = false;
                    } );
                }
            } else
            {
                localStream.muteAudio( function ( arg )
                {
                    console.log( localStream, "localStream_mute" );
                    setIcon( faMicrophoneSlash );
                    document.querySelector( "#self_aMute" ).classList.remove( "fa-microphone" );
                    document.querySelector( "#self_aMute" ).classList.add( "fa-microphone-slash" );
                    audio_muted = true;
                } );
            }
        } );

        document.querySelector( "#disconnect_call" ).addEventListener( "click", async function ()
        {
            if ( role === "moderator" )
            {
                room?.dropUser( [], function ( res )
                {
                    // console.log( res );
                    if ( res.result == 0 )
                    {
                        room.disconnect();
                    }
                } );
            } else
            {
                room.disconnect();
            }
        } );

        document.querySelector( '#mute_video' ).addEventListener( 'click', function ()
        {
            muteUnmuteBtn.style.cursor = "wait";
            muteUnmuteBtn.style.pointerEvents = "none";
            muteUnmuteBtn.disabled = true;
            muteUnmuteBtn.setAttribute( "disabled", "disabled" );

            if ( video_muted )
            {
                localStream.unmuteVideo( function ( res )
                {
                    if ( res.result === 0 )
                    {
                        setVideoIcon( faVideo );
                        document.querySelector( "#self_vMute" ).classList.remove( "fa-video-slash" );
                        document.querySelector( "#self_vMute" ).classList.add( "fa-video" );
                        video_muted = false;
                        enableMuteButton( muteUnmuteBtn );
                    } else if ( res.result === 1140 )
                    {
                        console.error( res.error );
                        enableMuteButton( muteUnmuteBtn );
                    }
                } );
            } else
            {
                localStream.muteVideo( function ( res )
                {
                    if ( res.result === 0 )
                    {
                        setVideoIcon( faVideoSlash );
                        document.querySelector( "#self_vMute" ).classList.remove( "fa-video" );
                        document.querySelector( "#self_vMute" ).classList.add( "fa-video-slash" );
                        video_muted = true;
                        enableMuteButton( muteUnmuteBtn );
                    } else if ( res.result === 1140 )
                    {
                        enableMuteButton( muteUnmuteBtn );
                    }
                } );
            }
        } );

    };

    return (
        <>
            {!flag ? (
                <>
                    <div>
                        <div className="layout">
                            <div id="overlay" style={{ 'display': 'none' }}>
                                <div className="loader" style={{ "width": "50px" }}>
                                    <img src="./img/loading.gif" alt="" />
                                </div>
                            </div>

                            <div className="container" >
                                <div className="row p-0 m-0" id="call_container_div">

                                    <div className="local_class_peep" id="local_view">
                                        <div id="self-view"></div>
                                        <div className="self-name">Local User</div>
                                    </div>

                                    <div className="remote_class_peep" id="remote_view">
                                        <div id="call_div"></div>
                                        <div id="show_stream_div"></div>
                                        <div className="remote-name">Remote User</div>
                                    </div>

                                    <div className="toolbar-buttons">
                                        <div className="tools">
                                            <div className="mute-icon" id="mute_audio" title="Mute/Unmute Audio">
                                                <button className='btn '>

                                                    <i className="fa fa-microphone" id="self_aMute"><FontAwesomeIcon icon={icon} /></i>
                                                </button>
                                            </div>
                                            <div className="video-mute-icon" id="mute_video" title="Mute/Unmute Video">
                                                <button className='btn btn-sm '>

                                                    <i className="fa fa-video" id="self_vMute"><FontAwesomeIcon icon={videoIcon} /></i>
                                                </button>
                                            </div>
                                            {/* <div className="video-mute-icon" id="share_screen_btn" title="Start Share">
                                      <i className="fa fa-desktop fa-fw SSicon"></i>
                                  </div> */}
                                            {/* <div className="video-mute-icon" id="toggle_chat" title="Chat">
                                      <i className="fas fa-comment-dots fa-fw CBicon" ></i>
                                      <span className="tag tag-danger top" id="chat-tag">&nbsp;</span>
                                  </div> */}
                                            <div className="video-mute-icon end-call bg-danger" title="End Call">
                                                <button className='btn'>
                                                    <i className="fas fa-phone fa-fw CBicon font" id="disconnect_call"><FontAwesomeIcon icon={faPhone} /></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            ) : ( <div className='h-screen flex justify-center items-center'> {errorToken}</div> )}
            <Modal
                open={visible}
                footer={null}
                closable={false}
                onCancel={() =>
                {
                    setVisible( false );
                }}
            >
                <CallMsg msg={msg} />
            </Modal>
        </>

    );
}


