import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sessions: [],
    diagnosticTypes: [],
    token: [],
    users: []
};

export const sessionSlice = createSlice( {
    name: 'session',
    initialState,
    reducers: {
        startSession: ( state, action ) =>
        {
            let item = state.sessions.find( item => item.id === action.payload.id );
            if ( item )
            {
                item.sessionTime = action.payload.sessionTime;
            } else
            {
                state.sessions.push( action.payload );
            }
        },
        updateSession: ( state, action ) =>
        {
            state.sessions = [];
            state.sessions = action.payload;
        }
        ,
        endSession: ( state, action ) =>
        {
            state.sessions = state.sessions.filter( item => item?.id !== action.payload.id );
        },
        resetSession: ( state ) =>
        {
            state.sessions = [];
        },
        diagnosticTypeR: ( state, action ) =>
        {
            // state.diagnosticTypes = [];
            state.diagnosticTypes = action.payload;
        },
        resetDiagnosticTypes: ( state ) =>
        {
            state.diagnosticTypes = [];
        },
        meetingToken: ( state, action ) =>
        {
            state.token = action.payload;
        },
        resetToken: ( state ) =>
        {
            state.token = [];
        },
        vCallUser: ( state, action ) =>
        {
            state.users = action.payload;
        }
    },
} );

// Action creators are generated for each case reducer function
export const { startSession, updateSession, endSession, resetSession, diagnosticTypeR, resetDiagnosticTypes, meetingToken, resetToken, vCallUser } = sessionSlice.actions;

export default sessionSlice.reducer;