import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import Layout from '../../component/layout/Layout.js';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function OtpVerification()
{
  const [ otp, setOtp ] = useState( '' );
  const [ email, setEmail ] = useState( '' );
  const server = `${process.env.REACT_APP_AWS_LAMBDA}`;
  const queryParams = new URLSearchParams( window.location.search );
  const navi = useNavigate();

  useEffect( () =>
  {
    setEmail( queryParams.get( 'email' ) );
  }, [ queryParams ] );


  const handleSubmit = async () =>
  {
    try
    {
      const { data } = await axios.post( `${server}/api/medi/auth/validate-otp`, { otp, email } );
      if ( data.success )
      {
        toast.success( data.message );
        navi( `/reset-password?email=${email}` );
      } else
      {
        toast.error( data.message );
      }
    } catch ( error )
    {
      toast.error( error );
    }
  };

  return (
    <Layout>
      <div className='flex h-[calc(78vh-50px)] items-center justify-center'>
        <div className='card'>
          <div className='card-header'>
            <div className='flex justify-center'>
              Verify OTP
            </div>
          </div>
          <div className='card-body'>
            <p>Enter your 4-digit OTP from your registered mail</p>
            <div className='ring-1 rounded p-2 mt-2 flex justify-center'>
              <OtpInput
                inputStyle={'text-4xl'}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={( props ) => <input {...props} />}
              />
            </div >
          </div>
          <div className='card-footer'>
            <button
              className='btn btn-success float-end'
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>

      </div>
    </Layout>
  );
}
