import React from 'react';

export default function SessionVideoError()
{
    return (
        <div>
            <div className='card'>
              
                <div className='card-body'>
                    <div>
                        Your session video is getting processed. Please check after 15 minutes.
                    </div>
                </div>
            </div>
        </div>
    );
}
