import React from 'react';

export default function MeetingOver()
{
  return (
    <div className='h-screen flex justify-center items-center flex-col gap-4'>
      <div className='text-4xl '>
        Meeting Over
      </div>
      <div className='text-sm grid grid-cols-6'>
        <div className='col-start-3 col-span-2'>
          click here to login to <a className='underline' href='/'>MediRehab Login</a>
        </div>
      </div>
    </div>
  );
}
