import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-hot-toast';

export default function Invoice( { patientDetail, paymentId, PatentType } )
{
    const [ show, setShow ] = useState( true );
    const [ type, setType ] = useState( false );
    const [ sessionDetail, setSessionDetail ] = useState( "" );
    const [ pre, setPre ] = useState( true );
    const [ amountafterDis, setDisAmount ] = useState( '' );
    const [ sessionDisc, setSessionDisc ] = useState( '' );
    const [ refund, setRefund ] = useState( 0 );

    useEffect( () =>
    {
        if ( paymentId )
        {
            setSessionDetail( Object.values( patientDetail[ 0 ][ 'patient' ].paymentHistory ).filter( item => item[ 0 ].id === paymentId ) );
            setType( true );
        }
    }, [ paymentId ] );

    useEffect( () =>
    {
        if ( sessionDetail.length > 0 )
        {
            if ( sessionDetail[ 0 ][ 0 ]?.refundAmount )
            {
                setRefund( sessionDetail[ 0 ][ 0 ]?.refundAmount );
            }
        }
    }, [ sessionDetail.length > 0 ] );

    const handlePrint = async () =>
    {
        try
        {
            setShow( false );
            window.print();
            setShow( true );
        } catch ( error )
        {
            toast.error( "Something went wrong while printing" );
        }
    };

    let currentAdvanceFromArray = '';
    if ( !type )
    {
        if ( patientDetail[ 0 ]?.patient?.paymentHistory.length > 0 )
        {
            currentAdvanceFromArray = patientDetail[ 0 ].patient.paymentHistory[ patientDetail[ 0 ].patient.paymentHistory.length - 1 ][ 0 ].amount;
        }
        else
        {
            currentAdvanceFromArray = patientDetail[ 0 ]?.patient.advanceAmount;
            setPre( false );
        }
    }

    useEffect( () =>
    {
        setDisAmount( ( ( ( patientDetail[ 0 ]?.patient.finalAmount ) - ( patientDetail[ 0 ]?.patient.advanceAmount ) ) - patientDetail[ 0 ]?.patient.discount ) );
    }, [ !type && patientDetail[ 0 ]?.patient?.discount ] );

    useEffect( () =>
    {
        if ( sessionDetail.length > 0 )
        {

            if ( patientDetail[ 0 ]?.patient?.discount )
            {
                setSessionDisc( ( sessionDetail[ 0 ][ 0 ].pending ) - ( patientDetail[ 0 ]?.patient?.discount ) );
            }
            else
            {
                setSessionDisc( ( sessionDetail[ 0 ][ 0 ].pending ) );
            }
        }
    }, [ type ] );

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>

                    <div className='row'>
                        <div className='col-6'>
                            <img style={{ width: 170, height: 50 }} src={`/MediRehab-LOGO-1.png`} alt='MediRehab Logo' />
                        </div>
                        <div className='col-6'>
                            <div className='float-end f-size'>
                                <h2>Invoice</h2>
                                <hr />
                                {type ? ( new Date( sessionDetail[ 0 ][ 0 ].date ).toDateString() ) : ( JSON.stringify( new Date().toDateString() ) )}
                                {/* <p>{JSON.stringify(new Date().toDateString())}</p> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <hr />

            <div className='row'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-12 col-xl-8'>

                            <div><b>Patient UID / MR No.</b> : {patientDetail[ 0 ]?.patient?.UID + ' / ' + patientDetail[ 0 ]?.patient?.MrNo}</div>
                            <div><b>Patient Name</b> : {patientDetail[ 0 ]?.patient?.name}</div>
                            <div><b>Category</b> : {patientDetail[ 0 ]?.patient?.category}</div>
                            <div><b>Assigned Therapist</b> : {patientDetail[ 3 ]?.therapist?.name} {patientDetail[ 1 ]?.hospital?.name ? " ( " + patientDetail[ 1 ]?.hospital.name + " )" : ( <></> )}</div>

                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div className='row'>
                <div className='col-12'>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Package Name</th>
                                <th>Description</th>
                                <th align='right'>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{type ? ( sessionDetail[ 0 ][ 0 ]?.PackageName ) : ( patientDetail[ 2 ]?.package?.name )}</td>
                                <td>{type ? ( sessionDetail[ 0 ][ 0 ]?.PackageDesc ) : ( patientDetail[ 2 ]?.package?.desc )}<div className='float-end'><b>Total</b></div></td>
                                <td align='right'>{type ? ( sessionDetail[ 0 ][ 0 ].totalAmount ) : ( patientDetail[ 0 ]?.patient.finalAmount )}{type ? ( sessionDetail[ 0 ][ 0 ]?.amountAfterRefund ) : ( <></> )}</td>
                            </tr>
                            {patientDetail[ 0 ]?.patient?.discount && patientDetail[ 0 ]?.patient?.discount !== 0 ? ( <tr>
                                <td></td>
                                <td align='right'><b>Discount</b></td>
                                <td align='right'>{type ? ( sessionDetail[ 0 ][ 0 ].discount ) : ( patientDetail[ 0 ]?.patient.discount )}</td>
                            </tr> ) : ( <></> )}

                            {PatentType !== 'ipd'
                                ? ( <tr>
                                    <td></td>
                                    <td align='right'><b>Total Amount Paid</b></td>
                                    <td align='right'>
                                        {type ? ( sessionDetail[ 0 ][ 0 ].paidPrevious ) : ( patientDetail[ 0 ]?.patient.paidPrevious )}
                                        {type ? ( sessionDetail[ 0 ][ 0 ]?.advAmountAfterRefund ) : ( <></> )}
                                    </td>
                                </tr> )
                                : ( <></> )}

                            {PatentType !== 'ipd' && refund > 0
                                ? (
                                    <tr>
                                        <td></td>
                                        <td align='right'><b>Refund</b></td>
                                        <td align='right'>{type ? ( sessionDetail[ 0 ][ 0 ]?.refundAmount ) : ( <></> )}</td>
                                    </tr>
                                ) : ( <></> )}

                            {PatentType !== 'ipd' && refund === 0
                                ? ( <tr>
                                    <td></td>
                                    {pre ? ( <td align='right'><b>Current Amount</b></td> ) : ( <></> )}
                                    {pre ? ( <td align='right'>{type ? ( sessionDetail[ 0 ][ 0 ].current ) : ( patientDetail[ 0 ]?.patient.advanceAmount - patientDetail[ 0 ]?.patient.paidPrevious )}</td> ) : ( <></> )}

                                    {/* <td>{patientDetail[0]?.patient.advanceAmount}</td> */}
                                </tr> )
                                : ( <></> )}
                            {PatentType !== 'ipd'
                                ? ( <tr>
                                    <td></td>
                                    <td align='right'><b>Amount Due</b></td>
                                    <td align='right'>{type ? ( sessionDetail[ 0 ][ 0 ].due ) : ( amountafterDis )}</td>
                                    {/* <td>{(patientDetail[0]?.patient.finalAmount) - (patientDetail[0]?.patient.advanceAmount)}</td> */}
                                </tr> )
                                : ( <></> )}

                        </tbody>
                    </Table>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-6'>

                        </div>
                        <div className='col-6'>
                            <div className='float-end'>
                                <small>signature</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-6'>

                        </div>
                        <div className='col-6'>
                            {show ? ( <button
                                className='btn btn-success'
                                onClick={handlePrint}
                            >
                                Print
                            </button> ) : ( <></> )}

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
