import React, { useEffect, useRef, useState } from 'react';
import { Select } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { toast } from 'react-hot-toast';

export default function AssignPackage(
    { options, setSelectedPAckage, handleSubmit, selectedPackage, dates, setDates, finalAmount, refundAmount, refundRaw, MrNo, sePatentType,
        setFinalAmount, setPrice, price, duration, setDuration, flag_dummy, setModalFlag, currentPackage, dueRaw, mrFlag, setMrNo,
        startDate, endDate, setStartDate, setEndDate, sessionDate, setSessionDate, setAdvance, advance, type, setType, discount, setDiscount,
        amountDiscounted, setAmountDiscounted, setAmountAfterDiscountRaw, amountAfterDiscountRaw, patientType,
        setPaidPrevious, showSubmit, PatentType, patientHospital, setPatientType,
        patientTypeReassign, setPatientTypeReassign, setTyprOPD, typeOPD, setCurrency, currency
    } )
{
    const [ hospitalPackages, setHospitalPackages ] = useState( '' );
    const [ currentHospital, setCurrentHospital ] = useState( '' );
    let myRef = useRef();
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    // /* patient type options */
    const optionsType = [
        { value: 'ipd', label: 'IPD' },
        { value: 'opd', label: 'OPD' },
        // { value: 'daily', label: 'Daily' },
        // { value: 'home-visits', label: 'Home Visits' }
    ];

    const currencyOptions = [
        { value: 'USD', label: 'USD' },
        { value: 'INR', label: 'INR' },
        // { value: 'daily', label: 'Daily' },
        // { value: 'home-visits', label: 'Home Visits' }
    ];

    // console.log( PatentType );
    const hospitalPackage = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/package/get-single-hospital-package/${patientHospital}` );
            if ( res.data.success )
            {
                if ( res.data?.packages )
                {
                    setHospitalPackages( res.data.packages );
                }
            }
        } catch ( error )
        {
            toast.error( "something went wrong fetching packages" );
        }
    };

    useEffect( () =>
    {
        setCurrentHospital( Object.values( hospitalPackages ).filter( item => item._id == currentPackage ) );
    }, [ hospitalPackages && currentPackage ] );

    useEffect( () =>
    {
        hospitalPackage();
    }, [] );
    /* handle package change */
    const handleChange = async ( e ) =>
    {
        setSelectedPAckage( e );
        setModalFlag( true );
    };

    useEffect( () =>
    {
        const today = new Date( startDate );
        const finalDate = new Date( today );
        finalDate.setDate( today.getDate() + duration );
        setEndDate( finalDate );
    }, [ startDate, duration ] );

    const isWeekday = ( date ) =>
    {
        const day = date.getDay();
        return day !== 0;
    };

    useEffect( () =>
    {
        if ( duration.length === 0 )
        {
            setAmountDiscounted( 0 );
            setAmountAfterDiscountRaw( 0 );
        }
        if ( discount > 0 && duration )
        {
            setAmountDiscounted( finalAmount - discount );
            setAmountAfterDiscountRaw( finalAmount - discount );
        }
        if ( discount === 0 )
        {
            setAmountDiscounted( 0 );
            setAmountAfterDiscountRaw( finalAmount );
        }
    }, [ discount > 0 && discount, finalAmount ] );

    useEffect( () =>
    {
        setAmountAfterDiscountRaw( finalAmount );
    }, [ discount === 0 && finalAmount ] );

    useEffect( () =>
    {
        // console.log(myRef)
        if ( myRef.current !== undefined )
        {
            // setAdvance( 0 );
        }
    }, [ discount > 0 ] );

    const handleDiscount = ( e ) =>
    {
        const value = e.target.value;
        if ( value >= finalAmount )
        {
            alert( "Dicount must not be more than Grand Amount" );
        }
        else
        {
            setDiscount( value );
        }
    };

    const handleAdvance = ( e ) =>
    {
        const value = e.target.value;

        if ( amountDiscounted > 0 && value > amountDiscounted )
        {
            alert( "Amount must not be more than Total Amount" );
        }
        else if ( amountDiscounted === 0 && value > finalAmount )
        {
            alert( "Amount must not be more than Grand Amount" );
        }
        else
        {
            setAdvance( value );
        }
    };

    const preventMinus = ( e ) =>
    {
        if ( e.code === 'Minus' || e.code === "Period" )
        {
            e.preventDefault();
        }
    };
    // console.log( refundAmount );

    const handleReassignType = ( value ) =>
    {
        // console.log( value + "okok" );
        setPatientTypeReassign( value );
        sePatentType( value );
        setModalFlag( true );
        if ( PatentType === 'opd' )
        {
            setTyprOPD( false );
            setPatientType( false );
        } else
        {
            setTyprOPD( true );
            setPatientType( true );
        }
    };

    // console.log( patientType );

    return (
        <div className='card'>
            <div className='card-header text-center'>
                Assign Package
            </div>
            <div className='row m-1'>
                <div className='col-12'>
                    <form onSubmit={handleSubmit}>
                        {flag_dummy
                            ? ( <div className='mb-3 ms-1'>
                                <label><b>Packages:</b></label>
                                <Select
                                    disabled
                                    className='form-control p-0'
                                    options={options}
                                    placeholder={"Select Package"}
                                    onChange={handleChange}
                                    // onChange={setSelectedPAckage}
                                    value={currentHospital[ 0 ]?.name}
                                />
                            </div> )
                            : ( <div className='mb-3 ms-1'>
                                <label><b>Packages:</b></label>
                                <Select
                                    className='form-control p-0'
                                    options={options}
                                    placeholder={"Select Package"}
                                    onChange={handleChange}
                                    // onChange={setSelectedPAckage}
                                    value={selectedPackage}
                                />
                            </div> )}

                        {/* package meta data */}
                        {selectedPackage
                            ? ( <div className='row'>
                                <div className='col-12'>
                                    {mrFlag ? ( <div className='row'>
                                        <div className='col-6'>
                                            <label>Patient's MrNo.</label>
                                            <input
                                                className='form-control'
                                                value={MrNo}
                                                onChange={( e ) => setMrNo( e.target.value )}
                                                placeholder='Please Enter MR No'
                                                // type='number'
                                                min={0}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>Type</label>
                                            <Select
                                                bordered={false}
                                                options={optionsType}
                                                className='form-control'
                                                onChange={( value ) => handleReassignType( value )}
                                                value={patientTypeReassign}
                                                placeholder={"Select Type"}
                                            />
                                        </div>
                                    </div> ) : ( <></> )}

                                    {/* duration & price */}
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <div className='mb-3'>
                                                <label>Duration (in days) :</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    value={duration}
                                                    min={0}
                                                    onChange={( e ) => setDuration( e.target.value )}
                                                // readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        {PatentType !== 'ipd' ? (
                                            <>
                                                <div className='col-3'>
                                                    <label>Currency : </label>
                                                    <Select
                                                        options={currencyOptions}
                                                        bordered={false}
                                                        className='form-control p-0'
                                                        value={currency}
                                                        onChange={( value ) => setCurrency( value )}
                                                    />
                                                </div>
                                                <div className='col-3'>
                                                    <div className='mb-3'>
                                                        <label>Price :</label>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            value={price}
                                                            min={0}
                                                            onChange={( e ) => setPrice( e.target.value )}
                                                        // readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : ( <></> )}

                                    </div>
                                    {/* calender */}
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className=''>
                                                <label>Start Date :</label>
                                            </div>
                                            <div className="form-group text-center">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={( date ) => setStartDate( date )}
                                                    isClearable
                                                    filterDate={isWeekday}
                                                    minDate={new Date()}
                                                    placeholderText='Select Date'
                                                    className='form-control'
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className=''>
                                                <label>End Date :</label>
                                            </div>
                                            <div className="form-group text-center">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={( date ) => setEndDate( date )}
                                                    isClearable
                                                    minDate={startDate}
                                                    // disabled={disabled}
                                                    selectsEnd
                                                    filterDate={isWeekday}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    placeholderText='Select End Date'
                                                    className='form-control'
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    {PatentType !== 'ipd' ? ( <div className='row mt-2'>
                                        {/* total  */}
                                        <div className='col-6'>
                                            <label>Grand Total Amount</label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={finalAmount}
                                                min={0}
                                                onChange={( e ) => setFinalAmount( e.target.value )}
                                                onKeyDown={preventMinus}
                                            // readOnly={true}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <div>
                                                <label>Discount</label>
                                            </div>
                                            <div>
                                                <input
                                                    className='form-control'
                                                    type='number'
                                                    placeholder='Please Enter Discount Amount'
                                                    onChange={handleDiscount}
                                                    // onChange={e => setDiscount( Number( e.target.value ) )}
                                                    value={discount}
                                                    min={0}
                                                    max={finalAmount}
                                                    // defaultValue={0}
                                                    onKeyDown={preventMinus}
                                                />
                                            </div>
                                        </div>
                                    </div> ) : ( <></> )}
                                    {PatentType || patientTypeReassign !== 'ipd' ? ( <div className='row mt-2'>
                                        <div className='col-6'>
                                            {discount > 0
                                                ? ( <div>
                                                    <div>
                                                        <label>Total Payable Amount</label>
                                                    </div>
                                                    <input
                                                        className='form-control'
                                                        type='number'
                                                        min={0}
                                                        value={amountDiscounted}
                                                        onKeyDown={preventMinus}
                                                    // readOnly={true}
                                                    />
                                                </div> )
                                                : ( <></> )}
                                        </div>
                                        <div className='col-6'>
                                            <div>
                                                <label>Advance Amount Received</label>
                                            </div>
                                            <div>
                                                <input
                                                    className='form-control'
                                                    type='number'
                                                    placeholder='Please Enter Advance Amount'
                                                    onChange={handleAdvance}
                                                    min={0}
                                                    // onChange={( e ) => setAdvance( e.target.value )}
                                                    value={advance}
                                                    onKeyDown={preventMinus}
                                                    ref={myRef}
                                                />
                                            </div>
                                        </div>
                                        {refundRaw > 0 ? ( <div className='col-6'>
                                            <div>
                                                <label>Refunded Amount</label>
                                            </div>
                                            <div>
                                                <input
                                                    className='form-control'
                                                    type='number'
                                                    placeholder='Refunded Amount'
                                                    min={0}
                                                    value={refundRaw}
                                                />
                                            </div>
                                        </div> ) : ( <></> )}
                                        {dueRaw > 0 ? ( <div className='col-6'>
                                            <div>
                                                <label>Due</label>
                                            </div>
                                            <div>
                                                <input
                                                    className='form-control'
                                                    type='number'
                                                    placeholder='Refunded Amount'
                                                    min={0}
                                                    value={dueRaw}
                                                />
                                            </div>
                                        </div> ) : ( <></> )}
                                    </div> ) : ( <>

                                    </> )}
                                </div>
                            </div> )
                            : ( <></> )}
                        <hr />
                        {selectedPackage && showSubmit ? ( <div className='float-end'>
                            <button className='btn btn-success'>Submit</button>
                        </div> ) : ( <></> )}

                    </form>
                </div>
            </div>
        </div>
    );
}
