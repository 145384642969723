import React, { useRef, useState } from 'react';
import Layout from '../../component/layout/Layout';
import AdminMenu from './AdminMenu';
import { Country, State, City } from 'country-state-city';
import { Select } from "antd";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;

export default function AddHospital()
{
    const inputFile = useRef( null );
    const [ indianState, setState ] = useState( '' );
    const [ city, setCity ] = useState( '' );
    const [ selectedImage, setSelectedImage ] = useState( '' );
    const [ name, setName ] = useState( '' );
    const [ address, setAddress ] = useState( '' );
    const [ email, setEmail ] = useState( "" );
    const navigate = useNavigate();

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    /* getting country from imported library */
    const country = Country.getCountryByCode( 'IN' );
    const countryState = State.getStatesOfCountry( country.isoCode );

    /* handle cities */
    const cities = City.getCitiesOfState( country.isoCode, indianState );

    /* image handler */
    const imagehandle = ( e ) =>
    {
        if ( e.target.files && e.target.files.length > 0 )
        {
            setSelectedImage( e.target.files[ 0 ] );
        }
    };

    /* image reset */
    const handleReset = () =>
    {
        if ( inputFile.current )
        {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
            setSelectedImage( '' );
        }
    };
    /* form submit */
    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();
        try
        {
            // aws temp url get request from aws
            const { data } = await axios.get( `${server}/s3Url` );
            // uploading file to aws bucket
            await fetch( data, {
                method: "PUT",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                body: selectedImage
            } );
            // getting image name uploaded on aws
            const imageUrl =  data.split( '?' )[ 0 ] ;
            // append image name to form data to save in mongo
            // const form = new FormData();
            // form.append( 'name', name );
            // form.append( 'email', email );
            // form.append( 'state', indianState );local
            // form.append( 'city', city );
            // form.append( 'address', address );
            // form.append( 'logo', imageUrl );
            // const res = await axios.post( `${server}/api/medi/hospital/create-hospital`, form );
            const res = await axios.post( `${server}/api/medi/hospital/create-hospital`, { name, email, "state": indianState, city, address, "logo": imageUrl } );

            if ( res.data.success )
            {
                toast.success( res.data.message );
                navigate( "/dashboard/admin/create-hospital" );
            } else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };

    const handleChange = ( event ) =>
    {
        const result = event.target.value.replace( /[^A-Za-z ]*/g, '' );
        setName( result );
        // console.log( result );
    };

    // console.log( `${process.env.REACT_APP_IMAGE_PATH}` );
    return (
        <Layout>
            <div className='card'>
                <div className='card-head bg-primary text-light text-center'>
                    <span><b>Add Hospital</b></span>
                </div>
                <div className='card-body'>

                    <div className="mb-3 form-group">
                        <label className="form-label">Hospital Name<sup style={{ color: 'red' }}>*</sup></label>
                        <input type="text" className="form-control" placeholder="Enter Hospital Name" onChange={handleChange} />
                    </div>
                    <div className="mb-3 form-group">
                        <label className="form-label">Email<sup style={{ color: 'red' }}>*</sup></label>
                        <input type="email" className="form-control" placeholder="Enter Hospital Email" onChange={( e ) => setEmail( e.target.value )} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Hospital State<sup style={{ color: 'red' }}>*</sup></label>
                        <Select placeholder='Select a State' size='large' showSearch
                            className='form-select mb-3' onChange={( value ) => { setState( value ); setCity( '' ); }}>
                            {Object.values( countryState ).map( ( c, i ) => (
                                <Option key={i} value={c.isoCode}>{c.name}</Option>
                            ) )}
                        </Select>
                    </div>
                    {indianState.length === 0 ? ( <></> ) : ( <div className="mb-3">
                        <label className="form-label">Hospital City<sup style={{ color: 'red' }}>*</sup></label>
                        <Select placeholder="Select a City" className='form-select mb-3' onChange={( value ) => setCity( value )} showSearch>
                            {cities.map( ( ci, l ) => (
                                <Option key={l} value={ci.name}>{ci.name}</Option>
                            ) )}
                        </Select>
                    </div> )}
                    <div className="mb-3 ">
                        <label className="form-label">Hospital Address<sup style={{ color: 'red' }}>*</sup></label>
                        <input type="text" className="form-control" placeholder="Enter Hospital Address" onChange={( e ) => setAddress( e.target.value )} />
                    </div>

                    <div className="mb-3 ">
                        <label className="form-label">Hospital Image<sup style={{ color: 'red' }}>*</sup></label>
                        <input type="file" className="form-control" placeholder="Please upload hospital image" onChange={imagehandle} ref={inputFile} />
                    </div>

                    <div className='mb-3'>
                        {selectedImage && (
                            <div>
                                <img src={URL.createObjectURL( selectedImage )} alt='prroduct-image' height={'200px'} value={selectedImage} />
                                <button className='btn btn-danger d-flex' onClick={handleReset}>Remove Image</button>
                            </div>
                        )}
                    </div>

                    <div className='mb-3'>
                        <button className='btn btn-success float-end' onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
