import React from 'react';

export default function MrNoPage( { MrNo } )
{
    // console.log( MrNo );
    return (
        < div className='card'>
            <div className='card-header text-center'>
                Mr No
            </div>
            <div className='card-body'>
                <ul>
                    {Object.values( MrNo ).map( ( item ) =>
                    (
                        <li key={item}>{item}</li>
                    ) )}
                </ul>
            </div>
        </div >
    );
}
