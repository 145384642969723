import React, { useEffect, useState } from 'react';

export default function AddDiagnosticsModal( { diagnostic, setDiagnostic, handleSubmit, diagnosticList, setDiagnosticList, handleMoreDiagnostics } )
{
    const [ flag, setFlag ] = useState( false );
    const [ childFlag, setChildFlag ] = useState( false );
    const handleChange = ( e, i ) =>
    {
        const { name, value } = e.target;
        const onChangeV1 = [ ...diagnosticList ];
        onChangeV1[ i ][ name ] = value;
        setDiagnosticList( onChangeV1 );
    };

    const handleDelete = ( i ) =>
    {
        const deleteVal = [ ...diagnosticList ];
        deleteVal.splice( i, i );
        setDiagnosticList( deleteVal );
    };
    // console.log( diagnosticList );

    useEffect( () =>
    {
        if ( diagnostic.length > 0 )
        {
            setFlag( false );
        } else
        {
            setFlag( true );
        }
    }, [ diagnostic ] );

    useEffect( () =>
    {
        // console.log( diagnosticList[ diagnosticList.length - 1 ][ 'sub_name' ] );
        let value = 0;
        Object.values( diagnosticList ).map( ( item ) => ( () =>
        {
            if ( !item.sub_name.length > 0 )
            {
                value = 1;
            }
        } )() );
        if ( value === 1 )
        {
            setChildFlag( true );
        } else
        {
            setChildFlag( false );
        }
    }, [ diagnosticList ] );


    return (
        <div className='card'>
            <div className='card-header text-center' style={{ background: 'green', color: 'white' }}>
                Add Diagnostics
            </div>
            <div className='card-body'>
                <label>Diagnostic Name<sup style={{ color: 'red' }}>*</sup></label>
                <input
                    placeholder='Enter Diagnostic Name'
                    className='form-control'
                    value={diagnostic}
                    onChange={( e ) =>
                    {
                        setDiagnostic( e.target.value );
                    }}
                />
                <div className='row mt-2'>
                    <div className='col-1'></div>
                    <div className='col-xl-10'>
                        <div className='card'>
                            <div className='card-header text-center ' style={{ background: 'skyblue' }}>
                                Sub-Diagnostic
                            </div>
                            <div className='card-body'>
                                {Object.values( diagnosticList ).map( ( item, i ) =>
                                (
                                    <>
                                        <div className='row' key={i}>
                                            <div className='col-10' key={i}>
                                                <label>Sub Diagnostic Name<sup style={{ color: 'red' }}>*</sup></label>
                                            </div>
                                            <div className='col-2'>
                                                <button
                                                    // style={{ color: "red" }}
                                                    className='btn btn-danger btn-sm'
                                                    key={i}
                                                    onClick={() =>
                                                    {
                                                        handleDelete( i );
                                                    }}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <input
                                                className='form-control'
                                                name='sub_name'
                                                key={i}
                                                placeholder='enter sub-diagnostic name'
                                                value={item.sub_name}
                                                onChange={( e ) => { handleChange( e, i ); }}
                                            />
                                        </div>
                                    </>
                                ) )}
                                <button
                                    className='btn btn-warning btn-sm float-end mt-2'
                                    onClick={() =>
                                    {
                                        handleMoreDiagnostics();
                                    }}
                                >
                                    Add More Sub-Diagnostic
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <button
                    id='submit'
                    className={( () =>
                    {
                        if ( !flag && !childFlag )
                        {
                            return (
                                'float-end btn btn-success btn-sm '
                            );
                        } else
                        {
                            return (
                                'float-end btn btn-success btn-sm disabled'
                            );
                        }
                    } )()}
                    onClick={() =>
                    {
                        handleSubmit();
                    }}
                >
                    Submit
                </button>
            </div>
        </div >
    );
}
