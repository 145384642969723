import React from 'react';

export default function Capability( { type, handleSubmit, name, setName } )
{
    return (
        <div className='card'>
            <div className='card-header text-center'>
                {type === 'new' ? ( "Add Capability" ) : ( "Edit Capability" )}
            </div>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    {/* name */}
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Capability Name<sup style={{color:'red'}}>*</sup></label>
                        <input type="type" className="form-control" aria-describedby="emailHelp" onChange={( e ) => setName( e.target.value )}
                            value={name} />
                    </div>
                    <div className='float-end'>
                        <button type="submit" className="btn btn-primary" >Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
