import { useEffect } from "react";

export default function AddMoreMoney( { handleAdvance, advanceNew, SetAdvanceNew, amountDiscounted, due, advance } )
{

    // console.log(advance)


    const handleAdv = ( event ) =>
    {
        const value = event.target.value;
        if ( value > due )
        {
            alert( 'Amount exceeds Total Amount' );
        } else
        {
            SetAdvanceNew( value );
        }
    };

    const preventMinus = ( e ) =>
    {
        if ( e.code === 'Minus' || e.code === "Period" )
        {
            e.preventDefault();
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='text-center bg-light' >
                            <b>Pay Due</b>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-6'>
                                <label>Total Amount</label>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={amountDiscounted}
                                    onKeyDown={preventMinus}
                                    disabled
                                />
                            </div>
                            <div className='col-6'>
                                <label>Pending Amount</label>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={due}
                                    disabled
                                />
                            </div>
                            <div className='col-12 mt-1'>
                                <label>Amount to pay</label>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={advanceNew}
                                    onKeyDown={preventMinus}
                                    onChange={handleAdv}
                                    min={0}
                                    placeholder='Enter Amount to pay'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='float-end'>
                            <button
                                className='btn btn-success form-control'
                                onClick={handleAdvance}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
