import { faEye, faSearch, faCirclePlay, faCommentsDollar, faSearchDollar, faBox, faStop } from '@fortawesome/free-solid-svg-icons';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { startSession, endSession } from '../redux/sessionReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ViewSessionDetails from './admin/ViewSessionDetails';
import { useAuth } from '../component/context/AuthContext';
import { useDispatch, useSelector } from "react-redux";
import PaymentHistory from './admin/PaymentHistory';
import DocumentsView from './admin/DocumentsView';
import PackageDetail from './admin/PackageDetail';
import Pagination from '@mui/material/Pagination';
import { useMediaQuery } from 'react-responsive';
import Layout from '../component/layout/Layout';
import AddMoreMoney from './admin/AddMoreMoney';
import SessionNote from './admin/SessionNote';
import { toast } from 'react-hot-toast';
import Stack from '@mui/material/Stack';
import { Modal, Tooltip } from "antd";
import Session from './admin/Session';
import Invoice from './admin/Invoice';
import { forwardRef } from 'react';
import moment from "moment";
import { uid } from 'uid';
import axios from 'axios';

const Dashboard = forwardRef( ( { show }, ref ) =>
{
  const [ auth ] = useAuth();
  const [ todaysPatients, setTodaysPatients ] = useState( '' );
  const [ page, setPage ] = useState( 1 );
  const [ pageCount, setPageCount ] = useState( 0 );
  const [ query, setQuery ] = useState( '' );
  const [ userId, setUserId ] = useState( '' );
  const [ type, setType ] = useState( '' );
  const [ visible, setVisible ] = useState( false );
  const [ time, setTime ] = useState( 0 );
  const [ selected, setSelected ] = useState( '' );
  const [ sessionDone, setSessionDone ] = useState( '' );
  const [ typeNew, setTypeNew ] = useState( '' );
  const [ sessionTextArea, setSessionTextArea ] = useState( "" );
  const [ sessionList, setSessionList ] = useState( [] );
  const [ date, setDate ] = useState( "" );
  const [ advance, setAdvance ] = useState( 0 );
  const [ patientDetail, setPatientDetail ] = useState( '' );
  const [ totalAmount, setTotalAmount ] = useState( "" );
  const [ advanceNew, SetAdvanceNew ] = useState( 0 );
  const [ paymentHistory, setPaymentHistory ] = useState( [] );
  const [ selectedPatient, setSelectedPatient ] = useState( "" );
  const [ paymentId, setPaymentId ] = useState( '' );
  const [ selectedImage, setSelectedImage ] = useState( [] );
  const [ documentID, setDocumentID ] = useState( '' );
  const [ selectedSession, setSelectedSession ] = useState( '' );
  const [ patientCondition, setPatientCondition ] = useState( '' );
  const [ discount, setDiscount ] = useState( 0 );
  const [ packageDate, setPackageDate ] = useState( "" );
  const [ amountDiscounted, setAmountDiscounted ] = useState( 0 );
  const [ selectedPackageId, setSelectedPackageId ] = useState( '' );
  const [ packageSelected, setPackageSelected ] = useState( '' );
  const [ finalAmount, setFinalAmount ] = useState( '' );
  const [ paidPrevious, setPaidPrevious ] = useState( 0 );
  const [ due, setDue ] = useState( '' );
  const [ packageId, setPackageId ] = useState( '' );
  const [ packageName, setPackageName ] = useState( '' );
  const [ packageArray, setPackageArray ] = useState( '' );
  const [ packageData, setPackageData ] = useState( '' );
  const [ patientProgress, setPatientProgress ] = useState( '' );
  const [ PatentType, sePatentType ] = useState( '' );
  const [ milliSec, setMilliSec ] = useState( 0 );
  const [ timeFinal, setTimeFinal ] = useState( 0 );
  const [ timeArray, setTimeArray ] = useState( [] );
  const [ watch, setWatch ] = useState( false );

  let sessions = '';
  sessions = useSelector( state => state.session.sessions );
  const [ sessionDataRedux, setSessionData ] = useState( sessions );

  useEffect( () =>
  {
    setSessionData( sessions );
  }, [ sessions ] );

  useImperativeHandle( ref, () =>
  ( {
    todaysPatientList
  } ) );

  const isDesktopOrLaptop = useMediaQuery( { query: '(min-width: 1224px)' } );
  const isBigScreen = useMediaQuery( { query: '(min-width: 1824px)' } );
  const isTabletOrMobile = useMediaQuery( { query: '(max-width: 1224px)' } );
  const isPortrait = useMediaQuery( { query: '(orientation: portrait)' } );
  const isRetina = useMediaQuery( { query: '(min-resolution: 2dppx)' } );

  const sessionArray = [];
  const dispatch = useDispatch();

  /* handle Pagination page change */
  const handlePageChange = ( page ) =>
  {
    setPage( page );
  };

  /* search result handler */
  const handleKeyUp = ( e ) =>
  {
    setQuery( e.target.value );
    if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); todaysPatientList();
  };
  /* user id from local storage */
  useEffect( () =>
  {
    setUserId( auth?.user?.id );
    if ( auth?.user?.roleName === 'Admin' )
    {
      setType( "Admin" );
    }
    else
    {
      setType( "Non-Admin" );
    }
  }, [ auth?.user ] );

  let capabilities = [ "" ];
  capabilities = auth?.user?.capabilities;

  const server = `${process.env.REACT_APP_AWS_LAMBDA}`;
  /* Todays Patients List */
  const todaysPatientList = async () =>
  {
    try
    {
      if ( type )
      {
        const res = await axios.get( `${server}/api/medi/patient/todays-patient-list?page=${page}&q=${query}&therapistId=${userId}&type=${type}` );
        if ( res.data.success )
        {
          setTodaysPatients( res.data.patients );
          setPageCount( res.data.pagination.pageCount );
        }
        else
        {
          toast.error( res.data.message );
        }
      }
    } catch ( error )
    {
      toast.error( "something went wrong fetching patients list" );
    }
  };

  useEffect( () =>
  {
    todaysPatientList();
  }, [ userId ] );

  const handleSession = async () =>
  {
    try
    {
      setVisible( false );
      setTypeNew( 'note' );
      setVisible( true );
    } catch ( error )
    {
      toast.error( "something went wrong while session done" );
    }
  };

  const handleSessionNote = async () =>
  {
    try
    {
      const sessionCount = sessionDone;
      const sessionIncr = Number( sessionCount ) + 1;

      const form = new FormData();
      let arrImages = [];
      await Promise.all( Object.values( selectedImage ).map( ( item, i ) => (
        ( async () =>
        {
          // getting aws upload url from aws 
          const { data } = await axios.get( `${server}/s3Url/session` );
          // uploading images to aws s3 bucket 
          await fetch( data, {
            method: "PUT",
            headers: {
              "Content-Type": "multipart/form-data"
            },
            body: item
          } );
          // getting image name
          const imageUrl = data.split( '?' )[ 0 ];
          arrImages.push( imageUrl );
        }
        )() ) ) );

      const sessionData = {
        'id': uid( 16 ), 'dummy': 'key', 'milliSec': milliSec,
        'time': timeFinal, 'sessionDone': sessionIncr, 'sessionTextArea': sessionTextArea, 'patientProgress': patientProgress,
        'date': moment().format(), 'materialDate': date, 'therapist': auth?.user?.name, "therapistID": auth?.user?.id
      };

      form.append( 'sessionData', JSON.stringify( sessionData ) );
      form.append( 'images', JSON.stringify( arrImages ) );

      const res = await axios.post( `${server}/api/medi/patient/session-done/${selected}`, form );
      if ( res.data.success )
      {
        toast.success( res.data.message );
        setVisible( false );
        setTime( '' );
        setSessionTextArea( '' );
        setMilliSec( 0 );
        setSessionDone( "" );
        todaysPatientList();
      }
    } catch ( error )
    {
      toast.error( "Something went wrong while starting session" );
    }
  };

  const invoiceGenerator = async () =>
  {
    try
    {
      const res = await axios.get( `${server}/api/medi/patient/invoice/${selected}` );
      if ( res.data.success )
      {
        setPatientDetail( res.data.patient );
      }
    } catch ( error )
    {
      toast.error( "something went wrong while invoicing" );
    }
  };

  const handleAdvance = async ( e ) =>
  {
    e.preventDefault();
    try
    {

      const duee = Number( due ) - Number( advanceNew );
      const amountDis = totalAmount - discount;
      const dummy1 = packageArray;
      const arrayAdvance = [ {
        'id': uid( 16 ), 'date': new Date(), 'addedBy': auth?.user?.name, 'therapistId': auth?.user?.id,
        'packageID': dummy1[ 0 ].meta.data._id, 'PackageName': dummy1[ 0 ].meta.data.name, momentDate: moment().format(), "packageDate": packageDate,
        'duration': dummy1[ 0 ].meta.data.duration, 'price': dummy1[ 0 ].meta.data.price, 'PackageDesc': dummy1[ 0 ].meta.data.desc,
        'totalAmount': totalAmount, 'discount': discount, 'amountAfterDiscount': amountDis, 'paidPrevious': advance, 'current': Number( advanceNew ), 'due': duee, 'status': 'partial-payment'
      } ];

      console.log( totalAmount, discount, amountDis, advanceNew, advance, duee );
      const res = await axios.post( `${server}/api/medi/patient/advance/${selected}`, { advanceNew, arrayAdvance, advance, duee } );
      if ( res.data.success )
      {
        setAdvance( 0 );
        invoiceGenerator();
        setVisible( false );
        SetAdvanceNew( 0 );
        setTypeNew( 'invoice' );
        setTimeout( () =>
        {
          setVisible( true );
        }, 3000 );
        todaysPatientList();
        toast.success( res.data.success );
      }
    } catch ( error )
    {
      toast.error( "something went wrong on handle advance" );
    }
  };

  const handleClick = async ( id ) =>
  {
    try
    {
      setPaymentId( id[ 0 ].id );
      const res = await axios.get( `${server}/api/medi/patient/invoice/${selectedPatient}` );
      if ( res.data.success )
      {
        setPatientDetail( res.data.patient );
        setTypeNew( 'invoice' );
        setVisible( true );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };

  const handleDocumentClick = async () =>
  {
    try
    {
      if ( documentID )
      {
        const res = await axios.get( `${server}/api/medi/patient/document-view/${selected}/${documentID}` );
        if ( res.data.success )
        {
          setSelectedSession( res.data.session );
          setTypeNew( 'documents' );
          setVisible( true );
        }
      }
    } catch ( error )
    {
      toast.error( "something went wrong viewing documents" );
    }
  };

  /* initiale call to list users */

  useEffect( () =>
  {
    todaysPatientList();
  }, [ sessionDataRedux ] );

  useEffect( () =>
  {
    todaysPatientList();
  }, [ todaysPatients && sessionDataRedux ] );
  useEffect( () =>
  {
    todaysPatientList();
  }, [ page ] );

  let currentDate = new Date().toUTCString();


  return (
    <>
      {show === false ? ( <></> ) : (
        <Layout>
          <div className=''>
            <div className='row'>
              <div className='col-xl-2'></div>
              <div className='col-12 col-xl-8'>
                <h1 className='text-transform-uppercase'>
                  DashBoard
                  {/* {auth?.user?.name} */}
                  &nbsp;

                  <small className='text-secondary fs-5'>({auth?.user?.roleName})</small>
                </h1>
                <hr />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-2'>
            </div>
            <div className='col-12 col-xl-8'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row'>
                    <div className='col-12 col-xl-6'>
                      <b>Today's Patients List</b>
                    </div>
                    <div className='col-8 col-xl-4'>
                      <small>
                      </small>
                      <div className='input-group'>
                        <input type='text' placeholder='Search by name' minLength={3} className='form-control'
                          onKeyUp={( e ) => handleKeyUp( e )}></input>
                        <button className="btn btn-dark" id="btnNavbarSearch" type="button">
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body overflow-auto' style={( () =>
                {
                  if ( isDesktopOrLaptop )
                  {
                    return ( { maxHeight: '480px' } );
                  }
                  if ( isTabletOrMobile )
                  {
                    return ( { maxHeight: '700px' } );
                  }
                } )()}>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>UID / MR No.</Th>
                        <Th>Name</Th>
                        <Th>Amount</Th>
                        <Th>Type</Th>
                        <Th>Sessions</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.values( todaysPatients ).map( p => (
                        <Tr
                          key={p._id}
                          className={( () =>
                          {
                            if ( ( p.advanceAmount < p.amountAfterDiscount ) && ( p.sessionDone < p.duration ) )
                            {
                              return (
                                'alert alert-danger'
                              );
                            }
                            if ( ( p.advanceAmount === p.amountAfterDiscount ) && ( p.sessionDone === p.duration ) )
                            {
                              return (
                                'alert alert-success'
                              );
                            }
                            if ( ( p.advanceAmount === p.amountAfterDiscount ) && ( p.sessionDone < p.duration ) )
                            {
                              return (
                                'alert alert-info'
                              );
                            }
                            if ( ( p.advanceAmount < p.amountAfterDiscount ) && ( p.sessionDone === p.duration ) )
                            {
                              return (
                                'alert alert-primary'
                              );
                            }
                          } )()}
                          // className={p.advanceAmount < p.finalAmount ? ('alert alert-danger') : (<></>)}
                          role={p.advanceAmount < p.finalAmount ? ( 'alert' ) : ( <></> )}
                        >
                          <Td>{p.UID} / {p.MrNo}</Td>
                          <Td>{p.name}</Td>
                          <Td>{p.advanceAmount} / {p.amountAfterDiscount}</Td>
                          <Td>{p.type}</Td>
                          <Td>{JSON.stringify( p.sessionDone )}/{JSON.stringify( p.duration )}</Td>
                          <Td>{( () =>
                          {
                            if ( ( p.advanceAmount < p.amountAfterDiscount && p.type === 'opd' ) && ( p.sessionDone < p.duration ) )
                            {
                              return (
                                'Payment & Sessions Pending'
                              );
                            }
                            if ( ( p.advanceAmount === p.amountAfterDiscount ) && ( p.sessionDone === p.duration ) )
                            {
                              return (
                                'Completed'
                              );
                            }
                            if ( ( ( p.advanceAmount === p.amountAfterDiscount ) && ( p.type === 'opd' ) ) && ( p.sessionDone < p.duration ) )
                            {
                              return (
                                'Sessions Pending'
                              );
                            }
                            if ( ( ( p.ipdPayment === 'due' ) && ( p.type === 'ipd' ) ) && ( p.sessionDone < p.duration ) )
                            {
                              return (
                                'Sessions & Payment Pending'
                              );
                            }
                            if ( ( ( p.ipdPayment === 'done' ) && ( p.type === 'ipd' ) ) && ( p.sessionDone < p.duration ) )
                            {
                              return (
                                'Sessions Pending'
                              );
                            }
                            if ( ( p.advanceAmount < p.amountAfterDiscount ) && ( p.sessionDone === p.duration ) && ( p.type === 'opd' ) )
                            {
                              return (
                                'Payment Pending'
                              );
                            }
                            if ( ( p.type === 'ipd' ) && ( p.ipdPayment === 'done' ) && ( p.sessionDone === p.duration ) )
                            {
                              return (
                                'Completed'
                              );
                            }
                            if ( ( p.type === 'ipd' ) && ( p.ipdPayment === 'due' ) && ( p.sessionDone === p.duration ) )
                            {
                              return (
                                'Payment Pending'
                              );
                            }
                            else
                            {
                              return (
                                "New"
                              );
                            }
                          } )()}</Td>
                          <Td>
                            <div className='row'>
                              <div className='col-12'>
                                <div className='row'>

                                  {/** session start */}

                                  {/* {JSON.stringify( Object.values( sessions ).filter( item => item.id === p._id ).length > 0 )} */}
                                  {( () =>
                                  {
                                    if ( Object.values( sessions ).filter( item => item.id === p._id ).length > 0 )
                                    {
                                      return (
                                        ( capabilities?.includes( 'start-session' ) ) && ( p.sessionDone < p.duration )
                                          ? ( <div className='col-auto'>
                                            <div className='border rounded float-end ' >
                                              <button
                                                className='btn btn-sm btn-danger form-control'
                                                onClick={() =>
                                                {
                                                  setTimeFinal( () =>
                                                  {

                                                    let oldTime = Object.values( sessions ).find( item => item.id === p._id )[ 'sessionTime' ];
                                                    let newTime = moment().format();
                                                    let sessionTime = moment.duration( moment( newTime ).diff( oldTime ) );
                                                    setMilliSec( sessionTime._milliseconds );
                                                    let timeDuration = sessionTime._data.hours + " hrs: " + sessionTime._data.minutes + " min: " + sessionTime._data.seconds + " sec";
                                                    return (
                                                      timeDuration
                                                    );
                                                  } );

                                                  dispatch( endSession( {
                                                    id: p._id
                                                  } ) );
                                                  handleSession();
                                                  // setTypeNew( 'session' );
                                                  // setVisible( true );
                                                  setSelected( p._id );
                                                  setSessionDone( p.sessionDone );
                                                  todaysPatientList();
                                                  // p.sessionDone ? setSessionDone(p.sessionDone) : setSessionDone(0)
                                                }}
                                              >
                                                <Tooltip placement="bottomRight" title={"End Session"} >
                                                  <FontAwesomeIcon icon={faStop} />
                                                </Tooltip>
                                                {/* {"\n"}
                                              {Object.values( sessions ).map( item => ( () =>
                                              {
                                                if ( item.id === p._id )
                                                {
                                                  return ( item.sessionTime );
                                                }
                                              } )() )} */}
                                                {/* <StopWatch pause={pause} /> */}
                                              </button>
                                            </div>
                                          </div> )
                                          : ( <></> )
                                      );
                                    } else
                                    {
                                      return (
                                        ( capabilities?.includes( 'start-session' ) ) && ( p.sessionDone < p.duration )
                                          ? ( <div className='col-auto'>
                                            <div className='border rounded float-end ' >
                                              <button
                                                className='btn btn-sm btn-info form-control'
                                                onClick={() =>
                                                {
                                                  dispatch( startSession( {
                                                    id: p._id,
                                                    sessionTime: moment().format(),
                                                    name: p.name,
                                                    sessionDone: p.sessionDone
                                                  } ) );
                                                  // setTypeNew( 'session' );

                                                  // setVisible( true );
                                                  setSelected( p._id );
                                                  setSessionDone( p.sessionDone );
                                                  todaysPatientList();
                                                  // p.sessionDone ? setSessionDone(p.sessionDone) : setSessionDone(0)
                                                }}
                                              >
                                                <Tooltip placement="bottomRight" title={"Start Session"} >
                                                  <FontAwesomeIcon icon={faCirclePlay} />
                                                  {/* <StopWatch start={start} watch={watch} /> */}
                                                </Tooltip>
                                              </button>
                                            </div>
                                          </div> )
                                          : ( <></> )
                                      );
                                    }
                                  } )()}

                                  {p.sessionList.length > 0
                                    ? ( <div className='col-auto'>
                                      {p.sessionList
                                        ? (
                                          <div className='border rounded float-end ' >
                                            <Tooltip placement="bottomRight" title={"View Sessions"} >
                                              <button
                                                className='form-control btn btn-sm btn-primary'
                                                onClick={() =>
                                                {
                                                  setTypeNew( 'view' );
                                                  setVisible( true );
                                                  setSessionList( p.sessionList );
                                                  setSelected( p._id );
                                                  setPatientCondition( p.condition );
                                                  todaysPatientList();
                                                }}

                                              >
                                                <FontAwesomeIcon icon={faEye} />
                                              </button>
                                            </Tooltip>
                                          </div>
                                        )
                                        : ( <></> )}
                                    </div> )
                                    : ( <></> )}
                                  {/** session views */}

                                  {/* {setAdvance(p.advanceAmount)} */}
                                  {capabilities?.includes( 'accept-payment' ) && p.amountAfterDiscount > p.advanceAmount && p.type !== 'ipd'
                                    ? (
                                      <div className='col-auto'>
                                        <div className='border rounded float-end ' >
                                          <Tooltip placement="bottomRight" title={"Pay Dues"} >

                                            <button
                                              className='btn btn-sm btn-warning'
                                              onClick={() =>
                                              {
                                                setPackageDate( p.packageList.slice( -1 ).flat()[ 0 ][ 'date' ] );
                                                setSelected( p._id );
                                                setAdvance( p.advanceAmount );
                                                setTypeNew( 'add-money' );
                                                setVisible( true );
                                                setTotalAmount( p.finalAmount );
                                                setDiscount( p.discount );
                                                setAmountDiscounted( p.amountAfterDiscount );
                                                setPackageSelected( p.assignPackageArray );
                                                setSelectedPackageId( p.assignPackageId );
                                                setFinalAmount( p.finalAmount );
                                                setPaidPrevious( p.paidPrevious );
                                                setDue( p.due );
                                                setPackageId( p.assignPackageId );
                                                setPackageName( () =>
                                                {
                                                  setPackageArray( JSON.parse( p.assignPackageArray ) );
                                                } );
                                                // setAmountAfterDiscountRaw(p.finalAmount - p.discount)
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faCommentsDollar} />
                                            </button>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    )
                                    : ( <></> )}

                                  {/* payment history */}
                                  {p.paymentHistory.length > 0
                                    ? ( <div className='col-auto'>
                                      <div className='border rounded float-end'>
                                        <Tooltip placement='bottomRight' title={"Payment History"}>
                                          <button
                                            className='btn btn-sm btn-info'
                                            onClick={() =>
                                            {
                                              setTypeNew( 'payment-history' );
                                              setVisible( true );
                                              setPaymentHistory( p.paymentHistory );
                                              setSelectedPatient( p._id );
                                              sePatentType( p.type );
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faSearchDollar} />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div> )
                                    : ( <></> )}


                                  {/* package detail*/}
                                  {p.packageList
                                    ? ( <div className='col-auto'>
                                      <div className='border rounded float-end'>
                                        <Tooltip placement='bottomRight' title={"View Package Details"}>
                                          <button
                                            className='btn btn-sm btn-secondary'
                                            onClick={() =>
                                            {
                                              setSelected( p._id );
                                              setTypeNew( 'package-detail' );
                                              setVisible( true );
                                              setPackageData( p.packageList );
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faBox} />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </div> )
                                    : ( <></> )}

                                </div>

                              </div>
                            </div>
                          </Td>
                        </Tr>
                      ) )}
                    </Tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className='float-end'>
              <div className='float-end mt-2' >
                {pageCount > 1 && (
                  <Stack spacing={2}>
                    <Pagination
                      count={pageCount}
                      showFirstButton
                      showLastButton
                      color='primary'
                      onChange={( event, value ) => setPage( value )}
                    />
                  </Stack>
                  // <MyPagination
                  //   total={pageCount}
                  //   current={page}
                  //   onChangePage={handlePageChange}
                  // />
                )}
              </div>
            </div>
          </div>
        </Layout > )}

      <Modal
        open={visible}
        onCancel={() =>
        {
          setVisible( false );
          setPaymentHistory( '' );
          setSelectedPatient( '' );
          setAdvance( 0 );
          setTotalAmount( '' );
          SetAdvanceNew( 0 );
          setPaymentId( '' );
          setSelectedSession( '' );
          setSelected( '' );
          setSessionList( '' );
          setPackageDate( "" );
          setMilliSec( 0 );
          setDocumentID( '' );
        }}
        footer={null}
        destroyOnClose={true}
        maskClosable={( () =>
        {
          if ( typeNew === 'session' )
          {
            return ( false );
          } else
          {
            return ( true );
          }
        } )()}
        // width={1000}
        width={( () =>
        {
          if ( typeNew === 'invoice' )
          {
            return ( 1000 );
          }
          else
          {
            return ( 600 );
          }
        } )()}
      >
        {( () =>
        {
          if ( typeNew === 'note' )
          {
            return (
              <SessionNote
                sessionTextArea={sessionTextArea} setSessionTextArea={setSessionTextArea}
                handleSessionNote={handleSessionNote} date={date} setDate={setDate}
                selectedImage={selectedImage} setPatientProgress={setPatientProgress}
                setSelectedImage={setSelectedImage} patientProgress={patientProgress}
              />
            );
          }
          if ( typeNew === 'session' )
          {
            return (
              <Session
                timeFinal={time} setTimeFinal={setTime} handleSession={handleSession}
              />
            );
          }
          if ( typeNew === 'view' )
          {
            return (
              <ViewSessionDetails
                sessionList={sessionList}
                documentID={documentID} setDocumentID={setDocumentID}
                patientCondition={patientCondition}
                handleDocumentClick={handleDocumentClick}
              />
            );
          }
          if ( typeNew === 'add-money' )
          {
            return (
              <AddMoreMoney
                setAdvance={setAdvance} advance={advance}
                totalAmount={totalAmount} setTotalAmount={setTotalAmount}
                advanceNew={advanceNew} SetAdvanceNew={SetAdvanceNew}
                handleAdvance={handleAdvance} discount={discount}
                amountDiscounted={amountDiscounted} paidPrevious={paidPrevious} due={due}
                setPaidPrevious={setPaidPrevious} setDue={setDue}
              />
            );
          }
          if ( typeNew === 'invoice' )
          {
            return (
              <Invoice
                patientDetail={patientDetail}
                paymentId={paymentId}
                PatentType={PatentType}
              />
            );
          }
          if ( typeNew === 'payment-history' )
          {
            return (
              <PaymentHistory
                handleClick={handleClick}
                paymentHistory={paymentHistory}
                selectedPatient={selectedPatient}
              />
            );
          }
          if ( typeNew === 'documents' )
          {
            return (
              <DocumentsView
                selectedSession={selectedSession}

              />
            );
          }
          if ( typeNew === 'package-detail' )
          {
            return (
              <PackageDetail
                packageData={packageData}
              />
            );
          }
        } )()}

      </Modal>
    </>
  );
} );

export default Dashboard;