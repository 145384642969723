import React from 'react';

export default function AddMoreMoneyIPD( { setIpdMoney, handleIPDMoneySubmit } )
{
    const preventMinus = ( e ) =>
    {
        if ( e.code === 'Minus' || e.code === "Period" )
        {
            e.preventDefault();
        }
    };
    return (
        <div className='container'>
            <div card>
                <div className='card-header'>
                    <div className='text-center bg-light'>
                        <b>Make Payment</b>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12'>
                            <label>IPD Amount<sup style={{ color: 'red' }}>*</sup></label>
                            <input
                                className='form-control'
                                type='number'
                                onChange={( e ) => setIpdMoney( e.target.value )}
                                placeholder='Enter Amount'
                                onKeyDown={preventMinus}
                                min={0}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <div className='float-end'>
                                <button
                                    className='form-control btn btn-success'
                                    onClick={handleIPDMoneySubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
