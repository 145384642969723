import React, { useState, useEffect } from 'react';
import Layout from '../../component/layout/Layout';
import Register from './Register';
import { Modal } from 'antd';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen, faTrash, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { MyPagination } from '../../component/pagination/MyPagination.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import DeleteUser from './DeleteUser';



export default function CreateUser()
{
    /* variables */
    const [ visible, setVisible ] = useState( false );
    const [ userName, setUserName ] = useState( "" );
    const [ name, setName ] = useState( "" );
    const [ email, setEmail ] = useState( "" );
    const [ password, setPassword ] = useState( "" );
    const [ role, setRole ] = useState( "" );
    const [ users, setUsers ] = useState( "" );
    const [ page, setPage ] = useState( 1 );
    const [ pageCount, setPageCount ] = useState( 0 );
    const [ selected, setSelected ] = useState();
    const [ updatedName, setUpdatedName ] = useState( '' );
    const [ updatedemail, setUpdatedEmail ] = useState( '' );
    // const [ updatedPassword, setUpdatedPassword ] = useState( '' );
    const [ updatedrole, setUpdatedRole ] = useState( '' );
    const [ type, setType ] = useState( '' );
    const [ query, setQuery ] = useState( '' );
    const [ rolesList, setRolesList ] = useState( [] );
    const [ roleOptions, setRoleOptions ] = useState( [] );
    const [ updatedRoleName, setUpdatedRoleName ] = useState( "" );
    const [ updatedRoleArray, setUpdatedRoleArray ] = useState( [] );
    const [ hospitals, setHospitals ] = useState( [] );
    const [ HospitalArray, setHospitalArray ] = useState( [] );
    const [ selectedHospital, setSelectedHospital ] = useState( [] );
    const [ updateSelectedHospital, setUpdateSelectedHospital ] = useState( [] );
    // const [ updatedSelectedHospital, setUpdatedSelectedHospital ] = useState( "" );

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    /* converting hospitals to selected option type */
    const HospitalOptions = ( hospital ) =>
    {
        const options = [];
        Object.values( hospital ).map( element => (
            options.push( { value: element._id, label: element.name } )
        ) );
        setHospitalArray( options );
    };

    /* getting hospital list */
    const getHospitalRecords = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/hospital/get-all-hospital-list-unpaged` );
            if ( res?.data?.success )
            {
                setHospitals( res.data.hospitals );
                HospitalOptions( res.data.hospitals );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };
    useEffect( () =>
    {
        getHospitalRecords();
    }, [] );

    /* getAllRole */
    const getAllRoleList = async () =>
    {
        try
        {
            const options = [];
            const res = await axios.get( `${server}/api/medi/user/get-all-role-list` );
            setRolesList( res.data.roles );
            await Promise.all( Object.values( rolesList ).map( ( r ) => (
                options.push( { value: r._id, label: r.name } )
            ) ) );
            setRoleOptions( options );
        } catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong" );
        }
    };
    /* handle form submit to add new user */
    const handleSubmit = async ( e ) =>
    {
        e.preventDefault();
        try
        {
            /* managing role */
            let roleString = role.value;
            let roleName = role.label;
            const roleArray = JSON.stringify( role );

            let therapistHospitalString = [];
            let therapistHospitalName = [];
            let therapistHsopitalArray = [];

            /* managing hoapital */
            if ( selectedHospital.length !== 0 )
            {
                Object.values( selectedHospital ).map( ( c ) => (
                    therapistHospitalString.push( c.value ),
                    therapistHospitalName.push( c.label )
                ) );
                // therapistHospitalString = selectedHospital.value;
                // therapistHospitalName = selectedHospital.label;
                therapistHsopitalArray.push( JSON.stringify( selectedHospital ) );
            }

            /* api request to backend */
            const res = await axios.post( `${server}/api/medi/auth/register`,
                { name, email, password, roleString, roleName, roleArray, therapistHospitalString, therapistHospitalName, therapistHsopitalArray } );
            if ( res?.data.success )
            {
                toast.success( res?.data.message );
                getAllUsers();
                setPage( 1 );
                setVisible( false );
                setName( "" );
                setEmail( "" );
                setPassword( "" );
                setRole( "" );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong" );
        }
    };

    /* handle Pagination page change */
    const handlePageChange = ( page ) =>
    {
        setPage( page );
    };

    /* search result handler */
    const handleKeyUp = ( e ) =>
    {
        setQuery( e.target.value );
        if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); getAllUsers();
    };

    useEffect( () =>
    {
        getAllRoleList();
    }, [ users ] );

    /* initiale call to list users */
    useEffect( () =>
    {
        getAllUsers();
    }, [ page ] );

    /* function to get list of all users */
    const getAllUsers = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/user/getall-users?page=${page}&q=${query}` );
            setUsers( res.data.users );
            setPageCount( res.data.pagination.pageCount );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something went wrong" );
        }
    };

    /* function to update user details */
    const handleEdit = async ( e ) =>
    {
        e.preventDefault();
        try
        {
            /* managing role */
            let roleString = updatedRoleArray.value;
            let roleName = updatedRoleArray.label;
            const roleArray = JSON.stringify( updatedRoleArray );

            /* manageing hospital */
            let therapistHospitalString = [];
            let therapistHospitalName = [];
            let therapistHsopitalArray = [];

            /* managing hoapital */
            if ( ( roleName === 'Therapist' ) || ( roleName === 'Manager' ) )
            {
                if ( updateSelectedHospital.length !== 0 )
                {
                    Object.values( updateSelectedHospital ).map( ( c ) => (
                        therapistHospitalString.push( c.value ),
                        therapistHospitalName.push( c.label )
                    ) );
                    // therapistHospitalString = updateSelectedHospital.value;
                    // therapistHospitalName = updateSelectedHospital.label;
                    therapistHsopitalArray.push( JSON.stringify( updateSelectedHospital ) );
                }
            }

            /* api request to backend */
            const res = await axios.put( `${server}/api/medi/user/edit-user/${selected}`,
                { updatedName, updatedemail, roleString, roleName, roleArray, therapistHospitalString, therapistHospitalName, therapistHsopitalArray } );
            if ( res.data.success )
            {
                toast.success( 'user is updated' );
                setVisible( false );
                getAllUsers();
                getAllRoleList();
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong" );
        }
    };

    /* function to delete user */
    const handleDelete = async ( id ) =>
    {
        try
        {
            const res = await axios.delete( `${server}/api/medi/user/delete-user/${id}` );
            if ( res.data.success )
            {
                setVisible( false );
                toast.success( res.data.message );
            } else
            {
                toast.error( res.data.message );
            }
            getAllUsers();
        } catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong" );
        }
    };

    return (
        <>
            <Layout>
                <div className='row'>
                    <div className='col-xl-2'></div>
                    <div className='col-12 col-xl-8'>
                        <h2 className='text-transform-uppercase'>
                            <FontAwesomeIcon icon={faUserDoctor} /> &nbsp; User Master
                        </h2>
                        <hr />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-2'></div>
                    <div className='col-12 col-xl-8'>
                        <Card>
                            <Card.Header>
                                <div className='row'>
                                    <div className='col-12 col-xl-6'>
                                        Manage Users
                                    </div>
                                    <div className='col-12 col-xl-6 float-end'>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <div className='input-group'>
                                                        <input type='text' placeholder='Search by name' minLength={3} className='form-control'
                                                            onKeyUp={( e ) => handleKeyUp( e )}></input>
                                                        <button className="btn btn-dark" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <button className='btn btn-success btn-sm' onClick={() =>
                                                    {
                                                        setVisible( true );
                                                        setType( 'new' );
                                                    }}>
                                                        Add User
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className='card-body overflow-auto' style={{ maxHeight: '480px' }}>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values( users ).map( ( c ) => (

                                            <tr key={c._id}>
                                                <td>{c.name}</td>
                                                <td>{c.email}</td>
                                                <td>{c.roleName}</td>
                                                <td>
                                                    <button className='btn btn-primary ms-2 btn-sm' onClick={() =>
                                                    {
                                                        setType( 'update' );
                                                        setVisible( true );
                                                        setSelected( c._id );
                                                        setUpdatedName( c.name );
                                                        setUpdatedEmail( c.email );
                                                        // setUpdatedPassword( c.password );
                                                        setUpdatedRole( c.role );
                                                        setUpdatedRoleName( c.roleName );
                                                        setUpdatedRoleArray( JSON.parse( c.roleArray[ 0 ] ) );
                                                        if ( c.therapistHsopitalArray.length !== 0 )
                                                        {
                                                            setUpdateSelectedHospital( JSON.parse( c.therapistHsopitalArray[ 0 ] ) );
                                                        }
                                                    }}><FontAwesomeIcon icon={faUserPen} />
                                                    </button>

                                                    <button className='btn btn-danger ms-2 btn-sm ms-1' onClick={() =>
                                                    {
                                                        setSelected( c._id );
                                                        setType( 'delete' );
                                                        setVisible( true );
                                                        setUserName( c.name );
                                                        // handleDelete( c._id );
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>

                                        ) )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className='float-end mt-2' >
                    {pageCount > 1 && (
                        <MyPagination
                            total={pageCount}
                            current={page}
                            onChangePage={handlePageChange}
                        />
                    )}
                </div>

                <Modal open={visible} destroyOnClose={true}
                    onCancel=
                    {() =>
                    {
                        // getAllUsers();   
                        setVisible( false );
                        setName( '' );
                        setUpdatedName( '' );
                        setEmail( '' );
                        setSelected( '' );
                        setUpdatedEmail( '' );
                        setPassword( '' );
                        // setUpdatedPassword( '' );
                        setRole( '' );
                        setUpdatedRole( "" );
                        setType( '' );
                    }
                    } footer={null}
                >
                    {( () =>
                    {
                        if ( type === 'new' )
                        {
                            return (

                                <Register
                                    handleSubmit={handleSubmit}
                                    name={name} setName={setName}
                                    email={email} setEmail={setEmail}
                                    password={password} setPassword={setPassword}
                                    role={role} setRole={setRole} roleOptions={roleOptions}
                                    type={type} HospitalArray={HospitalArray}
                                    selectedHospital={selectedHospital} setSelectedHospital={setSelectedHospital}
                                />
                            );
                        }
                        if ( type === 'update' )
                        {
                            return (

                                <Register
                                    handleSubmit={handleEdit}
                                    name={updatedName} setName={setUpdatedName}
                                    email={updatedemail} setEmail={setUpdatedEmail}
                                    // password={updatedpassword} setPassword={setUpdatedPassword}
                                    role={updatedRoleArray} setRole={setUpdatedRoleArray} roleOptions={roleOptions}
                                    type={type} HospitalArray={HospitalArray}
                                    selectedHospital={updateSelectedHospital} setSelectedHospital={setUpdateSelectedHospital}
                                />
                            );
                        }
                        if ( type === 'delete' )
                        {
                            return (
                                <DeleteUser
                                    selected={selected}
                                    handleDelete={handleDelete}
                                    userName={userName}
                                />
                            );
                        }
                    } )()}
                </Modal>

            </Layout>
        </>
    );
}
