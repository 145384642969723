import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Modal } from 'antd';
import { MyPagination } from '../../component/pagination/MyPagination';
import Capability from './Capability';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash, faCube } from '@fortawesome/free-solid-svg-icons';
import DeleteCapability from './DeleteCapability';

export default function CreateCapability()
{

  const [ query, setQuery ] = useState( '' );
  const [ page, setPage ] = useState( 1 );
  const [ visible, setVisible ] = useState( false );
  const [ type, setType ] = useState( '' );
  const [ pageCount, setPageCount ] = useState( 0 );
  const [ name, setName ] = useState( '' );
  const [ capailities, setCapabilities ] = useState( [] );
  const [ selected, setSelected ] = useState( '' );
  const [ updatedName, setUpdatedName ] = useState( "" );
  const [ capaName, setCapaName ] = useState( '' );
  const server = `${process.env.REACT_APP_AWS_LAMBDA}`;


  /* handle search */
  const handleQuery = ( e ) =>
  {
    setQuery( e.target.value );
    if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); getAllCapabilities();
  };

  /* handle pagination */
  const handlePageChange = ( page ) =>
  {
    setPage( page );
  };

  /* save capability to backend */
  const handleSubmit = async ( e ) =>
  {
    e.preventDefault();
    try
    {
      const res = await axios.post( `${server}/api/medi/user/add-capability`, { name } );
      if ( res.data.success )
      {
        toast.success( res.data.success );
        getAllCapabilities();
        setVisible( false );
        setName( '' );
        setUpdatedName( '' );
        setType( '' );
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      console.log( error );
      toast.error( "something went wrong" );
    }
  };

  /* edit capability */
  const handleEdit = async ( e ) =>
  {
    e.preventDefault();
    try
    {
      const res = await axios.put( `${server}/api/medi/user/edit-capability/${selected}`, { updatedName } );
      if ( res.data.success )
      {
        toast.success( res.data.message );
        setVisible(false)
        getAllCapabilities()
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };

  /* get all capabilities */
  const getAllCapabilities = async () =>
  {
    const res = await axios.get( `${server}/api/medi/user/get-all-capabilties?page=${page}&q=${query}` );
    if ( res.data.success )
    {
      setCapabilities( res.data.capabilities );
      setPageCount( res.data.pagination.pageCount );
    }
  };

  useEffect( () =>
  {
    getAllCapabilities();
  }, [ page ] );

  /* delete capability */
  const handleDelete = async ( id ) =>
  {
    try
    {
      const res = await axios.delete( `${server}/api/medi/user/delete-capability/${id}` );
      if ( res.data.success )
      {
        toast.success( res.data.success );
        setVisible( false );
        getAllCapabilities();
      }
      else
      {
        toast.error( res.data.error );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };

  return (
    <Layout>
      <div className='row'>
        <div className='col-xl-2'></div>
        <div className='col-12 col-xl-8'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='text-transform-uppercase'>
                <FontAwesomeIcon icon={faCube} />
                &nbsp; Capability Master
              </h2>
              <hr />
            </div>
          </div>
          <Card>
            <Card.Header>
              <div className='row'>
                <div className='col-12 col-xl-8'>
                  Manage Capabilities
                </div>
                <div className='col-12 col-xl-4'>
                  <div className='row'>
                    <div className='col-7'>
                      <input type='text' placeholder='search by name' onKeyUp={( e ) => { handleQuery( e ); }} className='form-control' />
                    </div>
                    <div className='col-5'>
                      <button className='btn btn-success btn-sm' onClick={() => { setType( 'new' ); setVisible( true ); }}>
                        Add Capability
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values( capailities ).map( ( c ) => (
                    <tr key={c._id}>
                      <td>{c.name}</td>
                      <td>
                        <button className='btn btn-primary btn-sm' onClick={() =>
                        {
                          setVisible( true );
                          setType( 'edit' );
                          setSelected( c._id );
                          setUpdatedName( c.name );
                        }}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button className='btn btn-danger ms-1 btn-sm' onClick={() =>
                        {
                          setType( 'delete' );
                          setSelected( c._id );
                          setCapaName( c.name );
                          setVisible( true );
                          // handleDelete( c._id );
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ) )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className='float-end mt-2' >
        {pageCount > 1 && (
          <MyPagination
            total={pageCount}
            current={page}
            onChangePage={handlePageChange}
          />
        )}
      </div>
      <Modal open={visible} destroyOnClose={true}
        onCancel={() =>
        {
          setVisible( false );
        }} footer={null}>
        {( () =>
        {
          if ( type === 'new' )
          {
            return ( <Capability
              type={type} handleSubmit={handleSubmit}
              name={name} setName={setName}
            >
            </Capability> );
          }
          if ( type === 'edit' )
          {
            return ( <Capability
              type={type} handleSubmit={handleEdit}
              name={updatedName} setName={setUpdatedName}
            >
            </Capability> );
          }
          if ( type === 'delete' )
          {
            return (
              <DeleteCapability
                selected={selected}
                handleDelete={handleDelete}
                capaName={capaName}
              />
            );
          }
        } )()}

      </Modal>
    </Layout>
  );
}
