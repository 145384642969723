// import { lazy } from "react";react-hot-toast
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home.js";
import Dashboard from "./pages/Dashboard.js";
import PrivateRoute from "./component/Routes/PrivateRoute.js";
import CreateUser from "./pages/admin/CreateUser.js";
import CreateHospital from "./pages/admin/CreateHospital.js";
import AddHospital from "./pages/admin/AddHospital.js";
import EditHospital from "./pages/admin/EditHospital.js";
import CreateRole from "./pages/admin/CreateRole.js";
import CreateCapability from "./pages/admin/CreateCapability.js";
import CreatePackage from "./pages/admin/CreatePackage.js";
import PatientRegister from "./pages/PatientRegister.js";
import PatientMaster from "./pages/admin/PatientMaster.js";
import Session from "./pages/Session.js";
import Reports from "./pages/admin/Reports.js";
import ReleaseLog from "../src/Releaselog.js";
import HospitalDash from "./pages/hospital/HospitalDash.js";
import HospitalRoute from "./component/Routes/HospitalRoute.js";
import PatientRoute from "./component/Routes/PatientRoute.js";
import PatientDash from "./pages/patient/PatientDash.js";
import DiagnosticSelectTab from "./pages/admin/DiagnosticSelectTab.js";
import DiagnosticTemplateInputFields from "./pages/admin/DiagnosticTemplateInputFields.js";
import AddDiagnsotic from "./pages/admin/AddDiagnsotic.js";
import CallView from "./pages/admin/CallView.js";
import MeetingRoom from "./pages/admin/MeetingRoom.js";
import MeetingPrescription from "./pages/admin/MeetingPrescription.js";
import MeetingOver from "./pages/admin/MeetingOver.js";
import ForgetPassword from "./pages/admin/ForgetPassword.js";
import OtpVerification from "./pages/admin/OtpVerification.js";
import CreateNewPassword from "./pages/admin/CreateNewPassword.js";
import Confo from "./pages/admin/Confo.js";
import FeedBackLayout from "./pages/admin/FeedBackLayout.js";
import FeedbackSubmittedMsg from "./pages/admin/FeedbackSubmittedMsg.js";
import FeedBackReport from "./pages/admin/FeedBackReport.js";

// const Home = lazy( () => import( './pages/Home.js' ) );
// const Dashboard = lazy( () => import( './pages/Dashboard.js' ) );
// const PrivateRoute = lazy( () => import( './component/Routes/PrivateRoute.js' ) );
// const CreateUser = lazy( () => import( './pages/admin/CreateUser.js' ) );
// const CreateHospital = lazy( () => import( './pages/admin/CreateHospital.js' ) );
// const AddHospital = lazy( () => import( './pages/admin/AddHospital.js' ) );
// const EditHospital = lazy( () => import( './pages/admin/EditHospital.js' ) );
// const CreatePackage = lazy( () => import( './pages/admin/CreatePackage.js' ) );
// const CreateRole = lazy( () => import( './pages/admin/CreateRole.js' ) );
// const CreateCapability = lazy( () => import( './pages/admin/CreateCapability.js' ) );
// const PatientRegister = lazy( () => import( './pages/PatientRegister.js' ) );
// const PatientMaster = lazy( () => import( './pages/admin/PatientMaster.js' ) );


function App()
{
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/release-log" element={< ReleaseLog />} />
      <Route path="/patient-registration" element={<DiagnosticSelectTab />} />
      <Route path="/patient-diagnostic-template" element={<DiagnosticTemplateInputFields />} />
      <Route path="/patient-registration-final" element={<PatientRegister />} />
      <Route path="/patient-register" element={<PatientRegister />} />
      <Route path="/store" element={<Session />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/otp-verification" element={<OtpVerification />} />
      <Route path="/reset-password" element={<CreateNewPassword />} />
      <Route path="/dashboard" element={<PrivateRoute />}>
        <Route path="admin" element={<Dashboard />} />
        <Route path="admin/manage-user" element={<CreateUser />} />
        <Route path="admin/create-role" element={<CreateRole />} />
        <Route path="admin/create-diagnostics" element={<AddDiagnsotic />} />
        <Route path="admin/create-capability" element={<CreateCapability />} />
        <Route path="admin/create-hospital" element={<CreateHospital />} />
        <Route path="admin/add-hospital" element={<AddHospital />} />
        <Route path="admin/edit-hospital/:slug" element={<EditHospital />} />
        <Route path="admin/create-package" element={<CreatePackage />} />
        <Route path="admin/patient-master" element={<PatientMaster />} />
        <Route path="admin/reports" element={<Reports />} />
        <Route path="admin/feedBackReport" element={<FeedBackReport />} />
      </Route>
      <Route path="/hospital" element={<HospitalRoute />}>
        <Route path="dash" element={<HospitalDash />} />
      </Route>
      <Route path="/patient" element={<PatientRoute />}>
        <Route path="dash" element={<PatientDash />} />
      </Route>
      <Route path="sessionValidate/:id" element={<CallView />} />
      <Route path="/meetingRoom/:roomId" element={<MeetingRoom />} />
      <Route path="/multiRoom/:roomId" element={<Confo />} />
      <Route path="/meetingPrescription" element={<MeetingPrescription />} />
      <Route path="/meetingOver" element={<MeetingOver />} />
      <Route path="/feedback/:pid/:id" element={<FeedBackLayout />} />
      <Route path="/feedbackSubmittedMsg" element={<FeedbackSubmittedMsg />} />
    </Routes>
  );
}

export default App;
