import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function MonthPicker( { smonth, syear, setYear, setMonth, setVisible } )
{
  return (
    <>
      {JSON.stringify( new Date( smonth ).getFullYear() )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={[ 'DatePicker', 'DatePicker' ]}>
          <DatePicker label={'"year"'} openTo="year" onChange={( year ) => setYear( year )} />
          <DatePicker
            label={'"month"'}
            openTo="month"
            views={[ 'year', 'month', 'day' ]}
            onChange={( month ) => setMonth( month )}
          />
        </DemoContainer>
      </LocalizationProvider>
      {/* <MonthYearPicker
        selectedMonth={smonth}
        selectedYear={syear}
        minYear={2023}
        maxYear={2030}
        onChangeYear={( year ) => setYear( year )}
        onChangeMonth={( month ) => setMonth( month )}
      /> */}

      <button className='btn btn-sm border float-end btn-secondary' onClick={() =>
      {
        setVisible( false );
      }}>
        Ok
      </button>
    </>
  );
}
