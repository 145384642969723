import React, { useState, useEffect } from 'react';
import Layout from '../../component/layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { Icon } from '@iconify/react';
import { FcSportsMode, FcApprove, FcStatistics } from "react-icons/fc";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { diagnosticTypeR } from '../../redux/sessionReducer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';


export default function DiagnosticTemplateInputFields()
{



    // const initialState = {
    //     pain: 0,
    //     rom: 0,
    //     ms: 0,
    //     swelling: 0,
    //     fa: 0,
    //     muscleTone: 0,
    //     muscleWasting: 0,
    //     muscleStrngth: 0,
    //     superficialSensation: 0,
    //     deepSensation: 0,
    //     reflexes: 0,
    //     postureAnalysis: 0,
    //     gaitAnalysis: 0,
    //     bedMobility: 0,
    //     vitalSigns: 0,
    //     chestExm: 0,
    //     respiratoryExm: 0,
    //     cardiacEnd: 0,
    //     functunalAss: 0
    // };



    const initialState = {
        'Orthopedic': {
            pain: 0,
            rom: 0,
            ms: 0,
            swelling: 0,
            fa: 0,
        },
        'Neurological': {
            muscleTone: 0,
            muscleWasting: 0,
            muscleStrngth: 0,
            superficialSensation: 0,
            deepSensation: 0,
            reflexes: 0,
            postureAnalysis: 0,
            gaitAnalysis: 0,
            bedMobility: 0,
        },
        'Cardiac': {
            vitalSigns: 0,
            chestExm: 0,
            respiratoryExm: 0,
            cardiacEnd: 0,
            functunalAss: 0
        }
    };
    // data from redux
    let diagnosticStore = '';
    diagnosticStore = useSelector( state => state.session.diagnosticTypes );
    const dispatch = useDispatch();

    // usestate variables
    const [ diagnosticType, setDiagnosticType ] = useState( "" );
    const [ assessment, setAssessment ] = useState( initialState );
    const [ type, setType ] = useState( diagnosticStore.type );
    const [ flag, setFlag ] = useState( true );

    const navigate = useNavigate();

    useEffect( () =>
    {
        if ( diagnosticStore?.data )
        {
            let reduxType = diagnosticStore.type;
            let object = Object.assign( {}, assessment );
            object[ reduxType ] = diagnosticStore.data;
            setAssessment( object );
        }
    }, [ diagnosticStore?.data?.length > 0 ] );

    useEffect( () =>
    {
        setDiagnosticType( diagnosticStore.type );
    }, [] );

    function valuetext( value )
    {
        return `${value}°C`;
    }

    const preventMinus = ( e ) =>
    {
        if ( e.code === 'Minus' || e.code === "Period" )
        {
            e.preventDefault();
        }
    };

    useEffect( () =>
    {
        let alert = 0;
        Object.keys( assessment ).map( ( item ) => ( () =>
        {
            if ( item === diagnosticType )
            {
                Object.values( assessment[ item ] ).map( ( value, i ) => ( () =>
                {
                    if ( value > 5 )
                    {
                        document.getElementById( i ).style.borderBlockColor = 'red';
                        if ( document.getElementById( `${`${diagnosticType}-span-${i}`}` ) )
                        {
                            document.getElementById( `${`${diagnosticType}-span-${i}`}` ).style.display = '';
                            alert = 1;
                        }
                    } else
                    {
                        document.getElementById( i ).style.borderBlockColor = '';
                        if ( document.getElementById( `${`${diagnosticType}-span-${i}`}` ) )
                        {
                            document.getElementById( `${`${diagnosticType}-span-${i}`}` ).style.display = 'none';
                        }
                    }
                } )() );
            }
        } )() );
        if ( alert === 1 )
        {
            setFlag( false );
        } else
        {
            setFlag( true );
        }
    }, [ assessment ] );


    return (
        <Layout>
            <div className='row'>
                <div className='row'>
                    <div className='col-xl-2'>

                    </div>
                    <div className='col-12 col-xl-8'>
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className='text-transform-uppercase'>
                                    Patient Registration
                                </h2>
                            </div>
                        </div>
                        <hr />
                        <div className='card'>
                            <div className='card-header text-center'>
                                {( () =>
                                {
                                    if ( diagnosticType === 'Orthopedic' )
                                    {
                                        return (
                                            <FcSportsMode size={50} />
                                        );
                                    }
                                    if ( diagnosticType === 'Neurological' )
                                    {
                                        return (
                                            <FcApprove size={50} />
                                        );
                                    }
                                    if ( diagnosticType === 'Cardiac' )
                                    {
                                        return (
                                            <FcStatistics size={50} />
                                        );
                                    }
                                } )()}
                                <b>{diagnosticType} Assessment</b>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12'>
                                        {( () =>
                                        {
                                            if ( diagnosticType === 'Orthopedic' )
                                            {
                                                return (
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <List aria-labelledby="decorated-list-demo">
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="openmoji:knee-pain" width={30} height={30} /></ListItemDecorator> <b>Pain</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="arcticons:alight-motion" width={30} height={30} /></ListItemDecorator> <b>Range of Motion</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="icon-park-outline:muscle" width={30} height={30} /></ListItemDecorator> <b>Muscle Strength</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="healthicons:intestinal-pain-outline" width={30} height={30} /></ListItemDecorator> <b>Swelling / Oedema</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="oui:function" width={30} height={30} /></ListItemDecorator> <b>Functional Assessment</b>
                                                                    </ListItem>
                                                                </List>
                                                            </div>
                                                            <div className='col-6'>
                                                                <Box>
                                                                    <Slider
                                                                        id={0}
                                                                        aria-label="Temperature"
                                                                        defaultValue={0}
                                                                        getAriaValueText={valuetext}
                                                                        valueLabelDisplay="auto"
                                                                        step={1}
                                                                        marks
                                                                        min={0}
                                                                        max={10}
                                                                        value={assessment.Orthopedic.pain}
                                                                        onChange={( e ) => setAssessment( {
                                                                            ...assessment,
                                                                            Orthopedic: { ...assessment.Orthopedic, pain: e.target.value }
                                                                        } )}
                                                                    />
                                                                </Box>
                                                                <span style={{ color: 'red', display: 'none' }} id={'Orthopedic'}>number must be less than 5</span>

                                                                <hr />
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>

                                                                <input
                                                                    id={1}
                                                                    type='number'
                                                                    onKeyDown={preventMinus}
                                                                    min={0}
                                                                    max={5}
                                                                    placeholder='0-5'
                                                                    value={assessment.Orthopedic.rom}
                                                                    className='form-control'
                                                                    onChange={( e ) => setAssessment( {
                                                                        ...assessment,
                                                                        Orthopedic: { ...assessment.Orthopedic, rom: Number( e.target.value ) }
                                                                    } )}
                                                                />
                                                                <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-1'}>number must be less than 5</span>

                                                                <hr />
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <input
                                                                    id={2}
                                                                    type='number'
                                                                    onKeyDown={preventMinus}
                                                                    min={0}
                                                                    max={5}
                                                                    placeholder='0-5'
                                                                    value={assessment.Orthopedic.ms}
                                                                    className='form-control'
                                                                    onChange={( e ) => setAssessment( {
                                                                        ...assessment,
                                                                        Orthopedic: { ...assessment.Orthopedic, ms: Number( e.target.value ) }
                                                                    } )}
                                                                />
                                                                <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-2'}>number must be less than 5</span>

                                                                <hr />
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <input
                                                                    id={3}
                                                                    type='number'
                                                                    onKeyDown={preventMinus}
                                                                    min={0}
                                                                    max={5}
                                                                    placeholder='0-5'
                                                                    value={assessment.Orthopedic.swelling}
                                                                    className='form-control'
                                                                    onChange={( e ) => setAssessment( {
                                                                        ...assessment,
                                                                        Orthopedic: { ...assessment.Orthopedic, swelling: Number( e.target.value ) }
                                                                    } )}
                                                                />
                                                                <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-3'}>number must be less than 5</span>

                                                                <hr />
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <input
                                                                    id={4}
                                                                    type='number'
                                                                    onKeyDown={preventMinus}
                                                                    min={0}
                                                                    max={5}
                                                                    value={assessment.Orthopedic.fa}
                                                                    placeholder='0-5'
                                                                    className='form-control'
                                                                    onChange={( e ) => setAssessment( {
                                                                        ...assessment,
                                                                        Orthopedic: { ...assessment.Orthopedic, fa: Number( e.target.value ) }
                                                                    } )}
                                                                />
                                                                <span style={{ color: 'red', display: 'none' }} id={'Orthopedic-span-4'}>number must be less than 5</span>

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <button className={( () =>
                                                                {
                                                                    if ( flag )
                                                                    {
                                                                        return ( 'btn btn-success float-end mt-2' );
                                                                    } else
                                                                    {
                                                                        return ( 'btn btn-success float-end mt-2 disabled' );
                                                                    }
                                                                } )()} onClick={() =>
                                                                {
                                                                    dispatch( diagnosticTypeR( {
                                                                        type: 'Orthopedic',
                                                                        data: assessment.Orthopedic
                                                                    } ) );
                                                                    navigate( "/patient-registration-final" );
                                                                }}>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }
                                            if ( diagnosticType === 'Neurological' )
                                            {
                                                return (
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-xl-4 col-12'>
                                                                <List aria-labelledby="decorated-list-demo">
                                                                    <div className='card'>
                                                                        <div className='card-header text-center'>
                                                                            <Icon icon="twemoji:letter-m" width={30} height={30} /><b> Motor Assessment</b>
                                                                        </div>
                                                                        <div className='card-body'>
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='row'>
                                                                                        <div className='col-8'>
                                                                                            <ListItem>
                                                                                                <ListItemDecorator><Icon icon="twemoji:leg-dark-skin-tone" width={30} height={30} /></ListItemDecorator> <b>Muscle Tone</b>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <input
                                                                                                id={0}
                                                                                                type='number'
                                                                                                onKeyDown={preventMinus}
                                                                                                min={0}
                                                                                                max={5}
                                                                                                value={assessment.Neurological.muscleTone}
                                                                                                placeholder='0-5'
                                                                                                className='form-control'
                                                                                                onChange={( e ) => setAssessment( {
                                                                                                    ...assessment,
                                                                                                    Neurological: { ...assessment.Neurological, muscleTone: Number( e.target.value ) }
                                                                                                } )}
                                                                                            />
                                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-0'}>number must be less than 5</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <hr />
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='row'>
                                                                                        <div className='col-8'>
                                                                                            <ListItem>
                                                                                                <ListItemDecorator><Icon icon="arcticons:musclewiki" width={30} height={30} /></ListItemDecorator> <b>Muscle Wasting</b>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <input
                                                                                                id={1}
                                                                                                type='number'
                                                                                                onKeyDown={preventMinus}
                                                                                                min={0}
                                                                                                max={5}
                                                                                                value={assessment.Neurological.muscleWasting}
                                                                                                placeholder='0-5'
                                                                                                className='form-control'
                                                                                                onChange={( e ) => setAssessment( {
                                                                                                    ...assessment,
                                                                                                    Neurological: { ...assessment.Neurological, muscleWasting: Number( e.target.value ) }
                                                                                                } )}
                                                                                            />
                                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-1'}>number must be less than 5</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <hr />
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='row'>
                                                                                        <div className='col-8'>
                                                                                            <ListItem>
                                                                                                <ListItemDecorator><Icon icon="icon-park-outline:muscle" width={30} height={30} /></ListItemDecorator> <b>Muscle Strength</b>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <Box >
                                                                                                <Slider
                                                                                                    id={2}
                                                                                                    aria-label="Temperature"
                                                                                                    defaultValue={0}
                                                                                                    getAriaValueText={valuetext}
                                                                                                    valueLabelDisplay="auto"
                                                                                                    step={1}
                                                                                                    marks
                                                                                                    min={0}
                                                                                                    value={assessment.Neurological.muscleStrngth}
                                                                                                    max={10}
                                                                                                    onChange={( e ) => setAssessment( {
                                                                                                        ...assessment,
                                                                                                        Neurological: { ...assessment.Neurological, muscleStrngth: Number( e.target.value ) }
                                                                                                    } )}
                                                                                                />
                                                                                            </Box>
                                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurologi'}>number must be less than 5</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </List>
                                                            </div >
                                                            <div className='col-xl-4 col-12 mt-2'>
                                                                <div className='card'>
                                                                    <div className='card-header text-center'>
                                                                        <Icon icon="twemoji:letter-s" width={30} height={30} /><b> Sensory Assessment</b>
                                                                    </div>
                                                                    <div className='card-body'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className='row'>
                                                                                    <div className='col-8'>
                                                                                        <ListItem>
                                                                                            <ListItemDecorator><Icon icon="noto:letter-s" width={30} height={30} /></ListItemDecorator> <b>Superficial Sensation</b>
                                                                                        </ListItem>
                                                                                    </div>
                                                                                    <div className='col-4'>
                                                                                        <input
                                                                                            id={3}
                                                                                            type='number'
                                                                                            onKeyDown={preventMinus}
                                                                                            min={0}
                                                                                            max={5}
                                                                                            value={assessment.Neurological.superficialSensation}
                                                                                            placeholder='0-5'
                                                                                            className='form-control'
                                                                                            onChange={( e ) => setAssessment( {
                                                                                                ...assessment,
                                                                                                Neurological: { ...assessment.Neurological, superficialSensation: Number( e.target.value ) }
                                                                                            } )}
                                                                                        />
                                                                                        <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-3'}>number must be less than 5</span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <hr />
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className='row'>
                                                                                    <div className='col-8'>
                                                                                        <ListItem>
                                                                                            <ListItemDecorator><Icon icon="simple-icons:deepgram" width={30} height={30} /></ListItemDecorator> <b> Deep Sensation</b>
                                                                                        </ListItem>
                                                                                    </div>
                                                                                    <div className='col-4'>
                                                                                        <input
                                                                                            id={4}
                                                                                            type='number'
                                                                                            onKeyDown={preventMinus}
                                                                                            min={0}
                                                                                            max={5}
                                                                                            value={assessment.Neurological.deepSensation}
                                                                                            placeholder='0-5'
                                                                                            className='form-control'
                                                                                            onChange={( e ) => setAssessment( {
                                                                                                ...assessment,
                                                                                                Neurological: { ...assessment.Neurological, deepSensation: Number( e.target.value ) }
                                                                                            } )}
                                                                                        />
                                                                                        <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-4'}>number must be less than 5</span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <hr />
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <div className='row'>
                                                                                    <div className='col-8'>
                                                                                        <ListItem>
                                                                                            <ListItemDecorator><Icon icon="simple-icons:r" width={30} height={30} /></ListItemDecorator> <b> Reflexes</b>
                                                                                        </ListItem>
                                                                                    </div>
                                                                                    <div className='col-4'>
                                                                                        <input
                                                                                            id={5}
                                                                                            type='number'
                                                                                            onKeyDown={preventMinus}
                                                                                            min={0}
                                                                                            max={5}
                                                                                            value={assessment.Neurological.reflexes}
                                                                                            placeholder='0-5'
                                                                                            className='form-control'
                                                                                            onChange={( e ) => setAssessment( {
                                                                                                ...assessment,
                                                                                                Neurological: { ...assessment.Neurological, reflexes: Number( e.target.value ) }
                                                                                            } )} />
                                                                                        <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-5'}>number must be less than 5</span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-xl-4 col-12'>
                                                                <List aria-labelledby="decorated-list-demo">

                                                                    <div className='card'>
                                                                        <div className='card-header text-center'>
                                                                            <Icon icon="icon-park:f-n-key" width={30} height={30} /><b> Functional Assessment</b>
                                                                        </div>
                                                                        <div className='card-body'>
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='row'>
                                                                                        <div className='col-8'>
                                                                                            <ListItem>
                                                                                                <ListItemDecorator><Icon icon="fontisto:paypal-p" width={30} height={30} /></ListItemDecorator> <b>Posture Analysis</b>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <input
                                                                                                id={6}
                                                                                                type='number'
                                                                                                onKeyDown={preventMinus}
                                                                                                min={0}
                                                                                                max={5}
                                                                                                value={assessment.Neurological.postureAnalysis}
                                                                                                placeholder='0-5'
                                                                                                className='form-control'
                                                                                                onChange={( e ) => setAssessment( {
                                                                                                    ...assessment,
                                                                                                    Neurological: { ...assessment.Neurological, postureAnalysis: Number( e.target.value ) }
                                                                                                } )}
                                                                                            />
                                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-6'}>number must be less than 5</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <hr />
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='row'>
                                                                                        <div className='col-8'>
                                                                                            <ListItem>
                                                                                                <ListItemDecorator><Icon icon="fa6-brands:glide-g" width={30} height={30} /></ListItemDecorator> <b>Gait Analysis</b>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <input
                                                                                                id={7}
                                                                                                type='number'
                                                                                                onKeyDown={preventMinus}
                                                                                                min={0}
                                                                                                value={assessment.Neurological.gaitAnalysis}
                                                                                                max={5}
                                                                                                placeholder='0-5'
                                                                                                className='form-control'
                                                                                                onChange={( e ) => setAssessment( {
                                                                                                    ...assessment,
                                                                                                    Neurological: { ...assessment.Neurological, gaitAnalysis: Number( e.target.value ) }
                                                                                                } )}
                                                                                            />
                                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-7'}>number must be less than 5</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <hr />
                                                                            <div className='row'>
                                                                                <div className='col-12'>
                                                                                    <div className='row'>
                                                                                        <div className='col-8'>
                                                                                            <ListItem>
                                                                                                <ListItemDecorator><Icon icon="fa6-solid:bed" width={30} height={30} /></ListItemDecorator> <b>Bed Mobility</b>
                                                                                            </ListItem>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <input
                                                                                                id={8}
                                                                                                type='number'
                                                                                                onKeyDown={preventMinus}
                                                                                                min={0}
                                                                                                max={5}
                                                                                                value={assessment.Neurological.bedMobility}
                                                                                                placeholder='0-5'
                                                                                                className='form-control'
                                                                                                onChange={( e ) => setAssessment( {
                                                                                                    ...assessment,
                                                                                                    Neurological: { ...assessment.Neurological, bedMobility: Number( e.target.value ) }
                                                                                                } )}
                                                                                            />
                                                                                            <span style={{ color: 'red', display: 'none' }} id={'Neurological-span-8'}>number must be less than 5</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </List>
                                                            </div >
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <button className={( () =>
                                                                {
                                                                    if ( flag )
                                                                    {
                                                                        return ( 'btn btn-success float-end mt-2' );
                                                                    } else
                                                                    {
                                                                        return ( 'btn btn-success float-end mt-2 disabled' );
                                                                    }
                                                                } )()} onClick={() =>
                                                                {
                                                                    dispatch( diagnosticTypeR( {
                                                                        type: 'Neurological',
                                                                        data: assessment.Neurological
                                                                    } ) );
                                                                    navigate( "/patient-registration-final" );
                                                                }}>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }
                                            if ( diagnosticType === 'Cardiac' )
                                            {
                                                return (
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <List aria-labelledby="decorated-list-demo">
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="mdi:heart-vitals" width={30} height={30} /></ListItemDecorator> <b>Vital Signs</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="icon-park:chest" width={30} height={30} /></ListItemDecorator> <b>Chest Examination</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="medical-icon:i-respiratory" width={30} height={30} /></ListItemDecorator> <b>Respiratory Examination</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="medical-icon:i-cardiology" width={30} height={30} /></ListItemDecorator> <b>Cardiac Endurance</b>
                                                                    </ListItem>
                                                                    <hr />
                                                                    <ListItem>
                                                                        <ListItemDecorator><Icon icon="oui:function" width={30} height={30} /></ListItemDecorator> <b>Functional Assessment</b>
                                                                    </ListItem>
                                                                </List>
                                                            </div>
                                                            <div className='col-6'>
                                                                <Box>
                                                                    <input
                                                                        id="0"
                                                                        type='number'
                                                                        onKeyDown={preventMinus}
                                                                        min={0}
                                                                        max={5}
                                                                        value={assessment.Cardiac.vitalSigns}
                                                                        placeholder='0-5'
                                                                        className='form-control'
                                                                        // style={{ borderBlockColor: 'red' }}
                                                                        onChange={( e ) => setAssessment( {
                                                                            ...assessment,
                                                                            Cardiac: { ...assessment.Cardiac, vitalSigns: Number( e.target.value ) }
                                                                        } )}
                                                                    />
                                                                </Box>
                                                                <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-0'}>number must be less than 5</span>
                                                                <hr />
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <Box>
                                                                    <input
                                                                        id="1"
                                                                        type='number'
                                                                        onKeyDown={preventMinus}
                                                                        min={0}
                                                                        max={5}
                                                                        value={assessment.Cardiac.chestExm}
                                                                        placeholder='0-5'
                                                                        className='form-control'
                                                                        onChange={( e ) => setAssessment( {
                                                                            ...assessment,
                                                                            Cardiac: { ...assessment.Cardiac, chestExm: Number( e.target.value ) }
                                                                        } )}
                                                                    />
                                                                </Box>
                                                                <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-1'}>number must be less than 5</span>

                                                                <hr />

                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <Box>

                                                                    <input
                                                                        id="2"
                                                                        type='number'
                                                                        onKeyDown={preventMinus}
                                                                        min={0}
                                                                        max={5}
                                                                        value={assessment.Cardiac.respiratoryExm}
                                                                        placeholder='0-5'
                                                                        className='form-control'
                                                                        onChange={( e ) => setAssessment( {
                                                                            ...assessment,
                                                                            Cardiac: { ...assessment.Cardiac, respiratoryExm: Number( e.target.value ) }
                                                                        } )}
                                                                    />
                                                                </Box>
                                                                <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-2'}>number must be less than 5</span>

                                                                <hr />
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <Box>

                                                                    <input
                                                                        id="3"
                                                                        type='number'
                                                                        onKeyDown={preventMinus}
                                                                        min={0}
                                                                        max={5}
                                                                        value={assessment.Cardiac.cardiacEnd}
                                                                        placeholder='0-5'
                                                                        className='form-control'
                                                                        onChange={( e ) => setAssessment( {
                                                                            ...assessment,
                                                                            Cardiac: { ...assessment.Cardiac, cardiacEnd: Number( e.target.value ) }
                                                                        } )}
                                                                    />
                                                                </Box>
                                                                <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-3'}>number must be less than 5</span>

                                                                <hr />
                                                                <MobileView>
                                                                    <hr />
                                                                </MobileView>
                                                                <Box>
                                                                    <input
                                                                        id="4"
                                                                        type='number'
                                                                        onKeyDown={preventMinus}
                                                                        min={0}
                                                                        max={5}
                                                                        value={assessment.Cardiac.functunalAss}
                                                                        placeholder='0-5'
                                                                        className='form-control'
                                                                        onChange={( e ) => setAssessment( {
                                                                            ...assessment,
                                                                            Cardiac: { ...assessment.Cardiac, functunalAss: Number( e.target.value ) }
                                                                        } )}
                                                                    />
                                                                </Box>
                                                                <span style={{ color: 'red', display: 'none' }} id={'Cardiac-span-4'}>number must be less than 5</span>

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <button className={( () =>
                                                                {
                                                                    if ( flag )
                                                                    {
                                                                        return ( 'btn btn-success float-end mt-2' );
                                                                    } else
                                                                    {
                                                                        return ( 'btn btn-success float-end mt-2 disabled' );
                                                                    }
                                                                } )()} onClick={() =>
                                                                {
                                                                    dispatch( diagnosticTypeR( {
                                                                        type: 'Cardiac',
                                                                        data: assessment.Cardiac
                                                                    } ) );
                                                                    navigate( "/patient-registration-final" );
                                                                }}>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }
                                        } )()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Layout >
    );
}
