import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-hot-toast';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Modal, Tooltip } from "antd";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { faTableColumns, faHospital, faBoxOpen, faHospitalUser, faUserDoctor, faSignOutAlt, faClock, faUserPlus, faChartPie, faPersonDotsFromLine, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import Session from '../../pages/Session';
import { useDispatch, useSelector } from 'react-redux';
import { resetSession, startSession } from '../../redux/sessionReducer';

const DrawerHeader = styled( 'div' )( ( { theme } ) => ( {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing( 0, 1 ),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
} ) );

export default function Header()
{
    const refreshList = useRef();

    // const dashRef = <Dashboard ref={refreshList} />;
    // console.log( dashRef );

    //redux data 
    const dispatch = useDispatch();
    const [ visible, setVisible ] = useState( false );
    const theme = useTheme();
    const [ open, setOpen ] = React.useState( false );
    const [ time, setTime ] = useState( [] );
    const [ itemTime, setItemTime ] = useState( 0 );
    const [ isRunning, setIsRunning ] = useState( false );
    const [ show, setShow ] = useState( false );
    const [ refCall, setRefCall ] = useState( false );

    let sessions = '';
    sessions = useSelector( state => state.session.sessions );
    const [ sessionData, setSessionData ] = useState( sessions );

    const incrementTime = () =>
    {
        Object.values( sessionData ).map( ( item, i ) => (
            setItemTime( item.sessionTime ),
            setItemTime( ( prevState ) => prevState + 5 ),
            console.log( i ),
            dispatch( startSession( { id: item.id, sessionTime: itemTime, name: item.name } ) )
        )
        );
    };

    useEffect( () =>
    {
        if ( isRunning )
        {
            setInterval( () =>
            {
                // incrementTime();
            }, 5000 );
        }
    }, [ isRunning ] );

    useEffect( () =>
    {
        setSessionData( sessions );
    }, [ sessions ] );

    useEffect( () =>
    {
        if ( sessions.length !== 0 )
        {
            setIsRunning( true );
        }
    }, [ sessionData.length !== 0 ] );

    useEffect( () =>
    {
        if ( sessions.length === 0 )
        {
            setIsRunning( false );
        }
    }, [ sessionData.length === 0 ] );

    const handleDrawerOpen = () =>
    {
        setOpen( true );
    };

    const handleDrawerClose = () =>
    {
        setOpen( false );
    };

    /* useAuth Context */
    const [ auth, setAuth ] = useAuth();

    /* logout function */
    const handleLogout = () =>
    {
        // console.log( 'first' );
        setAuth( {
            ...auth,
            user: null,
            token: ""
        } );
        localStorage.removeItem( 'auth' );
        dispatch( resetSession() );
        toast.success( "logout Successfully" );

    };
    let capabilities = [ "" ];
    capabilities = auth?.user?.capabilities;


    useEffect( () =>
    {
        // refreshList.current.todaysPatientList();
    }, [ refCall ] );

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary shadow">
                <div className="container-fluid">

                    <Link to="/" className="navbar-brand" >
                        <img style={{ width: 200, height: 70 }} src={`/MediRehab-LOGO-1.png`} alt='MediRehab Logo' />
                    </Link>

                    {sessions.length > 0 ? (
                        <Tooltip placement="bottomRight" title={"Running Sessions"}>
                            <button className="nav-link float-end btn btn-warning" onClick={() =>
                            {
                                setVisible( true );
                            }}><FontAwesomeIcon icon={faClock} size="lg" spin={( () =>
                            {
                                if ( sessions.length > 0 )
                                {
                                    return ( true );
                                }
                                else
                                {
                                    return ( false );
                                }
                            } )()} />
                                <div className='row'>

                                    <div className='row'>
                                        &nbsp; Sessions in Progress
                                    </div>
                                    <div className='row'>
                                        <small>click to view</small>
                                    </div>
                                </div>
                            </button>
                        </Tooltip>

                    ) : ( <></> )}

                    {!auth?.user
                        ? ( <></> )
                        : ( <>
                            {auth?.user
                                ? (
                                    <Toolbar>
                                        {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">Enable both scrolling & backdrop</button> */}

                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={handleDrawerOpen}
                                            edge="start"
                                            sx={{ mr: 2, ...( open && { display: 'none' } ) }}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Typography variant="h6" noWrap component="div">

                                        </Typography>

                                        <Drawer
                                            anchor="right"
                                            open={open}
                                        >
                                            <DrawerHeader>
                                                {auth?.user?.name}{" (" + auth?.user?.roleName + ")"}
                                                <IconButton onClick={handleDrawerClose}>
                                                    {theme.direction === 'tr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                                </IconButton>
                                            </DrawerHeader>
                                            <Divider />
                                            <List>
                                                <Sidebar className='bg-light'>
                                                    <Menu>
                                                        {capabilities.includes( 'dashboard' ) ? (
                                                            <MenuItem active={window.location.pathname === "/dashboard/admin/"}
                                                                icon={<FontAwesomeIcon icon={faTableColumns} />} component={<Link to="/dashboard/admin/" ></Link>}>DashBoard</MenuItem>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {capabilities.includes( 'hospital-dashboard' ) ? (
                                                            <MenuItem active={window.location.pathname === "/hospital/dash/"}
                                                                icon={<FontAwesomeIcon icon={faTableColumns} />} component={<Link to="/hospital/dash/" ></Link>}>DashBoard</MenuItem>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {capabilities.includes( 'patient-dashboard' ) ? (
                                                            <MenuItem active={window.location.pathname === "/patient/dash/"}
                                                                icon={<FontAwesomeIcon icon={faTableColumns} />} component={<Link to="/patient/dash/" ></Link>}>DashBoard</MenuItem>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {/* <MenuItem active={window.location.pathname === "/dashboard/admin/"}
                                                            icon={<FontAwesomeIcon icon={faTableColumns} />} component={<Link to="/dashboard/admin/" ></Link>}>DashBoard</MenuItem> */}
                                                        {/* user master */}
                                                        {capabilities?.includes( 'manage-user' ) ?
                                                            ( <SubMenu icon={<FontAwesomeIcon icon={faUserDoctor} />} label='User Master'>
                                                                <MenuItem active={window.location.pathname === "/dashboard/admin/manage-user"}
                                                                    component={<Link to="/dashboard/admin/manage-user"></Link>}>Users</MenuItem>
                                                                <MenuItem active={window.location.pathname === "/dashboard/admin/create-role"}
                                                                    component={<Link to={"/dashboard/admin/create-role"} />}>Role</MenuItem>
                                                                <MenuItem active={window.location.pathname === "/dashboard/admin/create-capability"}
                                                                    component={<Link to={"/dashboard/admin/create-capability"} />}>Capabitilty</MenuItem>
                                                            </SubMenu>
                                                            ) : ( <></> )}
                                                        {/* hospital master */}
                                                        {capabilities?.includes( 'manage-hospital' )
                                                            ? ( <MenuItem active={window.location.pathname === "/dashboard/admin/create-hospital"}
                                                                icon={<FontAwesomeIcon icon={faHospital} />} component={<Link to="/dashboard/admin/create-hospital"></Link>}>Hospital Master</MenuItem> )
                                                            : ( <></> )
                                                        }
                                                        {/* package master */}
                                                        {capabilities?.includes( 'manage-package' )
                                                            ? ( <MenuItem active={window.location.pathname === "/dashboard/admin/create-package"}
                                                                icon={<FontAwesomeIcon icon={faBoxOpen} />} component={<Link to="/dashboard/admin/create-package"></Link>}>Package Master</MenuItem> )
                                                            : ( <></> )
                                                        }
                                                        {/* patient master */}
                                                        {capabilities?.includes( 'manage-diagnostic' )
                                                            ? ( <MenuItem active={window.location.pathname === "/dashboard/admin/create-diagnostics"}
                                                                icon={<FontAwesomeIcon icon={faPersonDotsFromLine} />} component={<Link to="/dashboard/admin/create-diagnostics"></Link>}>Diagnostic Master</MenuItem> )
                                                            : ( <></> )
                                                        }
                                                        {/* patient master */}
                                                        {capabilities?.includes( 'manage-patient' )
                                                            ? ( <MenuItem active={window.location.pathname === "/dashboard/admin/patient-master"}
                                                                icon={<FontAwesomeIcon icon={faHospitalUser} />} component={<Link to="/dashboard/admin/patient-master"></Link>}>My Patients</MenuItem> )
                                                            : ( <></> )
                                                        }

                                                        {capabilities?.includes( 'patient-registration' ) ? (
                                                            <MenuItem icon={<FontAwesomeIcon icon={faUserPlus} />} component={<Link to="/patient-registration"></Link>}>
                                                                Patient Registration
                                                            </MenuItem>
                                                        ) : ( <></> )}

                                                        {capabilities.includes( "feedback-report"
                                                        ) ? (
                                                            <MenuItem icon={<FontAwesomeIcon icon={faFlagCheckered} />} component={<Link to="/dashboard/admin/feedBackReport"></Link>}>
                                                                Feedback  Report
                                                            </MenuItem>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {capabilities?.includes( "reports" ) ? (
                                                            <MenuItem icon={<FontAwesomeIcon icon={faChartPie} />} component={<Link to={"/dashboard/admin/reports"}></Link>}>
                                                                Reports
                                                            </MenuItem>
                                                        ) : ( <></> )}
                                                        <Divider />
                                                        <Divider />
                                                        <Divider />
                                                        <Divider />

                                                        <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />} component={<Link to="/"></Link>}>
                                                            <NavLink onClick={handleLogout} to="/" className="dropdown-item" >Logout</NavLink>
                                                        </MenuItem>

                                                    </Menu>
                                                </Sidebar>
                                            </List>
                                            <Divider />

                                        </Drawer>
                                    </Toolbar> )
                                : ( <></> )}

                        </> )
                    }
                </div>
            </nav >
            <Modal
                open={visible}
                onCancel={() =>
                {
                    setRefCall( true );
                    setTimeout( () =>
                    {
                        setVisible( false );
                    }, 1000 );
                    // refreshList.current.todaysPatientList();
                }}
                footer={null}
                destroyOnClose={true}
            >
                {<Session />}
            </Modal>
        </>
    );
}
