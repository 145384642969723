import React, { useEffect } from 'react';

export default function Revenue( { revenue, amount, setAmount } )
{
    useEffect( () =>
    {
        Object.values( [ revenue ] ).map( ( item ) => ( () =>
        {
            if ( item.status === 'package-assigned' )
            {
                setAmount( ( amount ) => amount + item.amountAfterDiscount );
            }
        } )() );

    }, [ revenue ] );
    return (
        <div className='card'>
            <div className='card-header text-center'>
                Revenue Card
            </div>
            <div className='card-body text-center'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Package Name</th>
                                        <th scope="col">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values( [ revenue ] ).map( ( item ) => ( () =>
                                    {
                                        if ( item.status === 'package-assigned' )
                                        {
                                            return (
                                                <tr>
                                                    <td>{new Date( item.date ).toLocaleDateString()}</td>
                                                    <td>{item.PackageName}</td>
                                                    <td>
                                                        {item.amountAfterDiscount}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    } )() )
                                    }
                                    <tr>
                                        <td></td>
                                        <td>Total Amount </td>
                                        <td>{amount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}
