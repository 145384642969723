import React from 'react';

export default function Feedback( {  feedType, selected, patientName, handleSubmit } )
{
    return (
        <div>
            <div className='card'>
                <div className={( () =>
                {
                    if ( feedType === 'submitted' )
                    {
                        return ( 'card-header flex justify-center bg-green-600 text-white' );
                    } else if ( feedType === 'sent' )
                    {
                        return ( 'card-header flex justify-center bg-red-600 text-white' );
                    } else
                    {
                        return ( 'card-header flex justify-center bg-green-600 text-white' );
                    }
                } )()}>
                    Feedback
                </div>
                <div className='card-body'>
                    {( () =>
                    {
                        if ( feedType === 'submitted' )
                        {
                            return (
                                <>
                                    <div className='flex justify-center'>
                                        {patientName.name}'s {patientName.sessionDone} sessions has been done out of {patientName.duration}.
                                    </div>
                                    <div className='flex justify-center mt-2'>
                                        Click on the Submit button to ask the patient for feedback.
                                    </div>
                                    <div className='flex justify-end'>
                                        <button
                                            className='btn btn-sm btn-success rounded mt-2'
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </>
                            );
                        } else
                        {
                            return (
                                <>
                                    <div className='text-center'>
                                        Mail has been sent to {patientName.name}.
                                    </div>
                                    <div className='text-center mt-2'>
                                        Please remind {patientName.name} to submit feedback.
                                    </div>
                                    <div className='flex justify-center mt-2'>
                                        {patientName.name}'s {patientName.sessionDone} sessions has been done out of {patientName.duration}.
                                    </div>
                                    <div className='flex justify-end'>
                                        <button
                                            className='btn btn-sm btn-success rounded mt-2'
                                            onClick={() => handleSubmit()}
                                        >
                                            Send Again
                                        </button>
                                    </div>
                                </>
                            );
                        }
                    } )()}
                </div>
            </div>
        </div>
    );
}
