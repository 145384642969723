import React, { useEffect, useRef, useState } from 'react';
import Layout from "../../component/layout/Layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faMoneyBillAlt, faSearch, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { DateRangePicker } from 'rsuite';
import isAfter from 'date-fns/isAfter';
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { FcBullish, FcComboChart, FcAreaChart, filterIcon, FcLeave, FcBusiness, FcDebt, FcApproval, FcCollaboration, FcOvertime, FcBearish } from "react-icons/fc";
import { toast } from 'react-hot-toast';
import { Select, Tooltip } from 'antd';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import MyPagination from '../../component/pagination/MyPagination';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';
import { CSVLink, CSVDownload } from "react-csv";
import { json2csv } from 'json-2-csv';
import slugify from "slugify";

import Avatar from '@mui/material/Avatar';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import Refund from './Refund';

export default function Reports()
{
    const [ dateRange, setDateRange ] = useState( '' );
    const [ patientData, setPatientData ] = useState( [] );
    const [ label, setLabel ] = useState( '' );
    const [ sales, setSales ] = useState( 0 );
    const [ list, setList ] = useState( [] );
    const [ query, setQuery ] = useState( '' );
    const [ pageCount, setPageCount ] = useState( 0 );
    const [ page, setPage ] = useState( 1 );
    const [ therapist, setTherapist ] = useState( [] );
    const [ selectedTherapist, setSelectedTherapist ] = useState( "" );
    const [ selectedHospital, setSelectedHospital ] = useState( "" );
    const [ adminHospital, setAdminHospital ] = useState( "" );
    const [ hospital, setHospital ] = useState( '' );
    const [ type, setType ] = useState( '' );
    const [ open, setOpen ] = React.useState( false );
    const [ cash, setCash ] = useState( '' );
    const [ month, setmonth ] = useState( '' );
    const [ totalSales, setTotalSales ] = useState( 0 );
    const [ advance, setAdvance ] = useState( 0 );
    const [ due, setDue ] = useState( 0 );
    const [ paymentArray, setPaymentArray ] = useState( '' );
    const [ patientCount, SetPatientCount ] = useState( 0 );
    const [ outstandingAmount, setOutstanding ] = useState( 0 );
    const [ sessionTimeMilliSec, setSessionTimeMilliSec ] = useState( 0 );
    const [ timeSessionProper, setTimeSessionProper ] = useState( 0 );
    const [ hospitalWiseRecords, setHospitalWiseRecords ] = useState( [] );
    const [ hospitalChart, setHospitalChart ] = useState( [] );
    const [ highPaid, setHighPaid ] = useState( [] );
    const [ highpaidFinal, setHighPaidFinal ] = useState( [] );
    const [ csvData, SetCsvData ] = useState( '' );
    const [ csvHeader, setCsvHeader ] = useState( '' );
    const [ headers, setheaders ] = useState( "" );
    const [ csvDataValues, setCsvDataValues ] = useState( "" );
    const [ resData, setResData ] = useState( "" );
    const [ typeData, setTypeData ] = useState( [] );
    const [ ipdHeader, setIPDHeader ] = useState( '' );
    const [ opdHeader, setOPDHeader ] = useState( '' );
    const [ ipdData, setIPDData ] = useState( '' );
    const [ hospitla, sethospital ] = useState( "" );
    const [ opdData, setOPDData ] = useState( '' );
    const [ ipdTotal, setIPDTotal ] = useState( 0 );
    const [ opdTotal, setOPDTotal ] = useState( 0 );
    const [ refundAmount, setRefundAmount ] = useState( 0 );
    const [ refundMonthWise, setRefundMonthWise ] = useState( 0 );

    const ipdRef = useRef( null );
    const opdRef = useRef( null );

    const toggleDrawer = ( newOpen ) => () =>
    {
        setOpen( newOpen );
    };

    const handleCalender = ( value ) =>
    {
        if ( value !== null )
        {
            let dateok = [];
            Object.values( value ).map( item => (
                dateok.push( moment( item ).format() )
            ) );
            setDateRange( dateok );
        } else
        {
            setDateRange( "" );
        }
    };

    function getMonthName( monthNumber )
    {
        const date = new Date();
        date.setMonth( monthNumber - 1 );

        return date.toLocaleString( 'en-US', {
            month: 'long',
        } );
    }

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    const wholeMonthData = async () =>
    {
        if ( selectedHospital === undefined )
        {
            setSelectedHospital( "" );
        }
        if ( type === undefined )
        {
            setType( "" );
        }
        const { data } = await axios.get( `${server}/api/medi/reports/whole-month?date=${dateRange}&hospital=${selectedHospital}&type=${type}&th=${selectedTherapist}` );
        setPatientData( data.patients );
    };


    useEffect( () =>
    {
        if ( patientData.length === 1 )
        {
            patientData.map( item => ( () =>
            {
                setmonth( getMonthName( item?._id.month ).slice( 0, 3 ) + ', ' + item._id.year );
                setTotalSales( item?.totalSalesAmount );
                // setRefundAmount( item?.refund );
                // setRefundAmount( refundMonthWise ),
                // setAdvance( item?.advance );
                // setDue( item.totalSalesAmount - item.advance );
                setPaymentArray( item.patientId );
            } )() );

        }
        if ( patientData.length > 1 )
        {
            patientData.map( item => ( () =>
            {
                // console.log( item?._id.month );
                if ( ( item._id.month === new Date().getMonth() + 1 ) && ( item._id.year === new Date().getFullYear() ) )
                {
                    return (
                        setmonth( getMonthName( item?._id.month ).slice( 0, 3 ) + ', ' + item._id.year ),
                        setTotalSales( item?.totalSalesAmount ),
                        // setRefundAmount( item?.refund ),
                        // setRefundAmount( refundMonthWise ),
                        // setAdvance( item?.advance ),
                        // setDue( item.totalSalesAmount - item.advance ),
                        setPaymentArray( item.patientId )
                    );
                }
            } )() );

        }
    }, [ patientData, dateRange === "" && month !== new Date().getMonth(), refundMonthWise ] );

    // hospital wise chart request
    const hospitalReport = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/reports/monthly-hospitals-report?date=${dateRange}&hospital=${selectedHospital}&type=${type}&th=${selectedTherapist}` );
            setHospitalWiseRecords( res.data.hospitalsRecords );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something Went wrong on Hsopitals reports" );
        }
    };

    useEffect( () =>
    {
        hospitalReport();
    }, [ dateRange ] );

    useEffect( () =>
    {
        if ( hospitalWiseRecords.length > 0 )
        {

            let data = [];
            Object.values( hospitalWiseRecords ).map( item => (
                data.push( {
                    'id': item._id,
                    label: item._id,
                    value: item.value
                } )
            ) );
            setHospitalChart( data );
        }
    }, [ hospitalWiseRecords ] );

    // console.log( hospitalChart );
    // console.log( moment( moment().startOf( 'month' )._d ).format(), moment().format() );

    const sessionTime = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/reports/sessionTime?date=${dateRange}&hospital=${selectedHospital}&type=${type}&th=${selectedTherapist}` );
            setSessionTimeMilliSec( res.data.sessionTime );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something went wrong on sessionTime" );
        }
    };
    useEffect( () =>
    {
        if ( sessionTimeMilliSec?.length > 1 )
        {
            Object.values( sessionTimeMilliSec ).map( item => ( () =>
            {
                if ( ( item._id.month === new Date().getMonth() + 1 ) && ( item._id.year === new Date().getFullYear() ) )
                {
                    setTimeSessionProper( new Date( item.millisec ).toISOString().slice( 11, -1 ).split( '.' )[ 0 ] );
                }
            } )() );
        }
        if ( sessionTimeMilliSec?.length === 1 )
        {
            Object.values( sessionTimeMilliSec ).map( item => (
                setTimeSessionProper( new Date( item.millisec ).toISOString().slice( 11, -1 ).split( '.' )[ 0 ] )
            ) );
        }
        if ( sessionTimeMilliSec?.length === 0 )
        {
            setTimeSessionProper( 0 );
        }
    }, [ sessionTimeMilliSec ] );

    // console.log( sessionTimeMilliSec );

    useEffect( () =>
    {
        sessionTime();
    }, [ dateRange, type, selectedHospital, selectedTherapist ] );

    useEffect( () =>
    {
        SetPatientCount( [ ...new Set( paymentArray ) ] );
    }, [ paymentArray?.length > 0 && paymentArray ] );

    useEffect( () =>
    {
        wholeMonthData();
    }, [ dateRange, selectedHospital, type, selectedTherapist ] );

    const handlePaymentHistoryList = async () =>
    {
        if ( selectedHospital === undefined )
        {
            setSelectedHospital( "" );
            setPage( 1 );
        }
        if ( type === undefined )
        {
            setType( "" );
            setPage( 1 );
        }
        const res = await axios.get(
            `${server}/api/medi/reports/payment-history-list?q=${query}&hospital=${selectedHospital}&type=${type}&th=${selectedTherapist}&page=${page}&date=${dateRange}`
        );
        setList( res.data.list[ 0 ]?.data );
        setPageCount( res.data.list[ 0 ]?.pagination[ 0 ]?.pageCount );
    };

    useEffect( () =>
    {
        handlePaymentHistoryList();
    }, [ dateRange, selectedHospital, type, selectedTherapist, page ] );

    const getHospitalRecords = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/hospital/get-all-hospital-list-unpaged` );
            if ( res?.data?.success )
            {
                setAdminHospital( res.data.hospitals );
            }
            else
            {
                toast.error( res.data.message );
            }
        } catch ( error )
        {
            toast.error( "something went wrong" );
        }
    };

    useEffect( () =>
    {
        getHospitalRecords();
    }, [] );

    useEffect( () =>
    {
        let adminHospitalArray = [];
        Object.values( adminHospital ).map( item => (
            adminHospitalArray.push( { value: item._id, label: item.name } )
        ) );
        setHospital( adminHospitalArray );
    }, [ adminHospital ] );

    const patientReport = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/reports/high-paid-patients?date=${dateRange}&hospital=${selectedHospital}&type=${type}&th=${selectedTherapist}` );
            setHighPaid( res.data.highPaid );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something went wrong on patient report" );
        }
    };

    useEffect( () =>
    {
        patientReport();
    }, [ dateRange, hospital, type ] );

    useEffect( () =>
    {
        if ( highPaid.length > 0 )
        {
            let dummy = [];
            Object.values( highPaid ).map( item => (
                dummy.push( { id: item._id, label: item?.label[ 0 ], value: item?.value } )
            ) );
            setHighPaidFinal( dummy );
        }
    }, [ highPaid ] );

    // chart meta data 
    const datasd = () =>
    {
        let salesData = [];
        let labelData = [];
        patientData.map( item =>
        (
            salesData.push( item.totalSalesAmount ),
            labelData.push( item._id.month + ", " + item._id.year )
        ) );
        setSales( [ { data: salesData, label: "Sales By Month" } ] );
        setLabel( labelData );
    };

    useEffect( () =>
    {
        datasd();
    }, [ patientData ] );

    const data = {
        labels: label,
        datasets: sales
    };

    /* get patient hospital therapists  */
    const getHospitalTherapist = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/user/get-hospital-therapists/${selectedHospital}` );
            if ( res.data.success )
            {
                let therapistArray = [];
                Promise.all(
                    Object.values( res.data.therapist ).map( item => (
                        therapistArray.push( { value: item._id, label: item.name } )
                    ) )
                );
                setTherapist( therapistArray );
            }
        } catch ( error )
        {
            toast.error( "something went wrong fetching therapist" );
        }
    };
    useEffect( () =>
    {
        if ( selectedHospital?.length )
        {
            getHospitalTherapist();
        }
    }, [ selectedHospital ] );

    useEffect( () =>
    {
        if ( selectedHospital === undefined )
        {
            setSelectedHospital( "" );
            setSelectedTherapist( "" );
        }
    }, [ selectedHospital ] );

    useEffect( () =>
    {
        if ( selectedTherapist === undefined )
        {
            setSelectedTherapist( "" );
        }
    }, [ selectedTherapist ] );

    const handleKeyUp = ( e ) =>
    {
        setQuery( e.target.value );
        if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); handlePaymentHistoryList();
    };

    const outstanding = async () =>
    {
        const res = await axios.get( `${server}/api/medi/reports/outstanding?date=${dateRange}&hospital=${selectedHospital}&type=${type}&th=${selectedTherapist}` );
        setOutstanding( res.data.outstanding );
    };

    useEffect( () =>
    {
        if ( outstandingAmount?.length > 1 )
        {
            if ( dateRange.length === 0 )
            {
                Object.values( outstandingAmount ).map( item => ( () =>
                {
                    if ( ( item._id.month === new Date().getMonth() + 1 ) && ( item._id.year && new Date().getFullYear() ) )
                    {
                        setAdvance( item.advance - refundAmount );
                        setDue( totalSales - item.advance + refundAmount );
                    }
                } )() );
            }
            else
            {
                Object.values( outstandingAmount ).map( item => ( () =>
                {
                    if ( ( item._id.month === new Date( dateRange[ 0 ] ).getMonth() + 1 ) && ( item._id.year && new Date().getFullYear() ) )
                    {
                        setAdvance( item.advance - refundAmount );
                        setDue( totalSales - item.advance + refundAmount );
                    }
                    else
                    {
                        setAdvance( 0 );
                        setDue( 0 );
                    }
                } )() );
                // console.log( new Date( dateRange[ 0 ] ).getMonth() + 1 );
            }

        }
        if ( outstandingAmount?.length === 1 )
        {
            Object.values( outstandingAmount ).map( item => ( () =>
            {
                setAdvance( item.advance - refundAmount );
                setDue( totalSales - item.advance + refundAmount );
            } )() );
        }
    }, [ outstandingAmount, totalSales, refundAmount, dateRange ] );

    useEffect( () =>
    {
        outstanding();
    }, [ selectedHospital, selectedTherapist, type, dateRange ] );

    const typeOptions = [ { value: 'ipd', label: 'IPD' }, { value: 'opd', label: 'OPD' } ];

    // download csv
    const handleReport = async () =>
    {
        try
        {
            const { data } = await axios.get( `${server}/api/medi/reports/csv-export?date=${dateRange}&hospital=${selectedHospital}` );
            setResData( data.data );
            // SetCsvData( data.data[ 0 ].data );
            sethospital( data.data[ 0 ].hospital[ 0 ] );
            Object.values( data.data ).map( item => (
                () =>
                {
                    if ( item._id.type === 'ipd' )
                    {
                        setIPDTotal( item.sum - item.refund );
                    }
                    if ( item._id.type === 'opd' )
                    {
                        setOPDTotal( item.sum - item.refund );
                    }
                }
            )() );
        }
        catch ( error )
        {
            console.log( error );
            toast.error( "something went wrong on export" );
        }
    };

    useEffect( () =>
    {
        let data = [];
        Object.values( resData ).map( item => (
            data.push( { [ item._id.type ]: item.data } )
        ) );
        setTypeData( data );
    }, [ resData ] );

    useEffect( () =>
    {
        let ipdDummy = [];
        let opdDummy = [];
        let x = [];
        let y = [];
        Object.values( typeData ).map( item => (
            () =>
            {
                if ( Object.keys( item )[ 0 ] === 'ipd' )
                {
                    return (
                        ipdDummy.push( Object.keys( Object.values( item )[ 0 ][ 0 ] ) ),
                        // console.log( [ ...new Set( Object.values( item )[ 0 ] ) ] ),
                        setIPDData( Object.values( item )[ 0 ] )
                    );
                }
                if ( Object.keys( item )[ 0 ] === 'opd' )
                {
                    return (
                        opdDummy.push( Object.keys( Object.values( item )[ 0 ][ 0 ] ) ),
                        setOPDData( Object.values( item )[ 0 ] )
                    );
                }
            }
        )() );
        if ( ipdDummy.length > 0 )
        {
            Object.values( ipdDummy[ 0 ] ).map( item => (
                x.push( { label: item, key: item } )
            ) );
        }
        if ( opdDummy.length > 0 )
        {
            Object.values( opdDummy[ 0 ] ).map( item => (
                y.push( { label: item, key: item } )
            ) );
        }
        setIPDHeader( x );
        setOPDHeader( y );

    }, [ typeData ] );

    useEffect( () =>
    {
        setIPDData( prevArray => [ ...prevArray, { 'Invoice_Amount': "Total : " + ipdTotal } ] );
        setTimeout( () =>
        {
            ipdRef?.current?.link.click();
        }, 500 );
    }, [ ipdHeader.length > 0 && ipdData.length > 0 ] );

    useEffect( () =>
    {
        setOPDData( prevArray => [ ...prevArray, { 'Invoice_Amount': "Total : " + opdTotal } ] );
        setTimeout( () =>
        {
            opdRef?.current?.link.click();
        }, 500 );
    }, [ opdHeader.length > 0 && opdData.length > 0 ] );

    // refund 
    const refundReport = async () =>
    {
        try
        {
            const res = await axios.get( `${server}/api/medi/reports/refundReport?date=${dateRange}&hospital=${selectedHospital}&type=${type}&th=${selectedTherapist}` );
            setRefundMonthWise( res.data.patients );
            // setRefundAmount( res.data.patients );
        } catch ( error )
        {
            console.log( error );
            toast.error( "Something Went wrong on Hsopitals reports" );
        }
    };

    useEffect( () =>
    {
        refundReport();
    }, [ dateRange, selectedHospital, type, selectedTherapist ] );

    useEffect( () =>
    {
        if ( refundMonthWise?.length === 1 && dateRange.length === 0 )
        {
            // console.log( 'po' );
            setRefundAmount( refundMonthWise[ 0 ][ 'refund' ] );
        }
        else if ( refundMonthWise?.length === 1 && dateRange.length !== 0 )
        {
            setRefundAmount( refundMonthWise[ 0 ][ 'refund' ] );
        }
        else if ( refundMonthWise?.length > 1 )
        {
            Object.values( refundMonthWise ).map( ( item ) => (
                () =>
                {
                    if ( dateRange.length === 0 )
                    {
                        if ( item._id.month === new Date().getMonth() + 1 )
                        {
                            setRefundAmount( item.refund );
                        }
                    }
                    else if ( dateRange.length > 0 )
                    {
                        if ( item._id.month === new Date( dateRange[ 0 ] ).getMonth() + 1 )
                        {
                            setRefundAmount( item.refund );
                        }
                    }
                }
            )() );
        }
        else if ( refundMonthWise?.length === 0 )
        {
            setRefundAmount( 0 );
        }
    }, [ refundMonthWise, dateRange ] );
    // console.log( refundAmount );

    return (
        <>
            <Layout>
                <div className='row'>
                    <div className='col-xl-2 mt-2'>
                        <BrowserView>
                            <div className='card'>
                                <div className='card-header'>
                                    <FilterAltIcon /><b>Filters</b>
                                </div>
                                <div className='card-body'>
                                    <div className='col-12 mt-2'>
                                        <label><b>Search by Name</b></label>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Name'
                                                onKeyUp={handleKeyUp}
                                            />
                                            <button className='btn btn-dark btn-sm' type='button'>
                                                <FontAwesomeIcon icon={faSearch} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <label><b>Category</b></label>
                                        <Select
                                            options={typeOptions}
                                            className='form-control'
                                            placeholder={"Select Type"}
                                            onChange={( value ) => setType( value )}
                                            allowClear
                                        />
                                    </div>
                                    <div className='col-12 mt-2'>
                                        <label><b>Hospital</b></label>
                                        <Select
                                            options={hospital}
                                            className='form-control'
                                            placeholder={"Select Hospital"}
                                            allowClear
                                            onChange={( value ) => { setSelectedHospital( value ); }}
                                        />
                                    </div>
                                    {selectedHospital?.length > 0 ? (
                                        <div className='col-12 mt-2'>
                                            <label><b>Therapist</b></label>
                                            <Select
                                                options={therapist}
                                                className='form-control'
                                                placeholder={"Select a Therapist"}
                                                allowClear
                                                onChange={( value ) => { setSelectedTherapist( value ); }}
                                            />
                                        </div>
                                    ) : ( <></> )}
                                    <div className='col-12 mt-2'>
                                        <label><b>Date</b></label>
                                        <DateRangePicker
                                            className='form-control'
                                            hoverRange={'month'}
                                            oneTap
                                            showOneCalendar
                                            onChange={( value ) => handleCalender( value )}
                                            shouldDisableDate={date => isAfter( date, new Date() )}
                                            placeholder={"Filter Date"}
                                        // defaultValue={[ moment().startOf( 'month' ).local().toDate(), moment().local().toDate() ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <Box sx={{ textAlign: 'center', pt: 1 }}>
                                <Button onClick={toggleDrawer( true )}>Filters</Button>
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                Month
                                            </div>
                                            <div className='card-body'>
                                                {month}
                                                {/* {patientData[ 0 ]?._id.month}, {patientData[ 0 ]?._id.year} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                Sales
                                            </div>
                                            <div className='card-body'>
                                                {totalSales}
                                                {/* {patientData[ 0 ]?.totalSalesAmount} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='card'>
                                            <div className='card-header'>
                                                Due
                                            </div>
                                            <div className='card-body'>
                                                {due}
                                                {/* {patientData[ 0 ]?.advance} */}
                                                {/* {Object.values( due ).map( item => ( ( () =>
                                                {
                                                    if ( item._id.month === new Date().getMonth() + 1 ) 
                                                    {
                                                        return ( item.outstanding );
                                                    }
                                                } )() ) )} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <SwipeableDrawer
                                open={open}
                                onClose={toggleDrawer( false )}
                                onOpen={toggleDrawer( true )}
                                anchor="top"
                            >
                                <div className='form-control'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <FilterAltIcon /><b>Filters</b>
                                        </div>
                                        <div className='card-body'>
                                            <div className='col-12 mt-2'>
                                                <label><b>Search by Name</b></label>
                                                <div className='input-group'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Search By Name'
                                                        onKeyUp={handleKeyUp}
                                                    />
                                                    <button className='btn btn-dark btn-sm' type='button'>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label><b>Category</b></label>
                                                <Select
                                                    options={typeOptions}
                                                    className='form-control'
                                                    placeholder={"Select Type"}
                                                    onChange={( value ) => setType( value )}
                                                    allowClear
                                                />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label><b>Hospital</b></label>
                                                <Select
                                                    options={hospital}
                                                    className='form-control'
                                                    placeholder={"Select Hospital"}
                                                    allowClear
                                                    onChange={( value ) => { setSelectedHospital( value ); }}
                                                />
                                            </div>
                                            {selectedHospital?.length > 0 ? (
                                                <div className='col-12 mt-2'>
                                                    <label><b>Therapist</b></label>
                                                    <Select
                                                        options={therapist}
                                                        className='form-control'
                                                        placeholder={"Select a Therapist"}
                                                        allowClear
                                                        onChange={( value ) => { setSelectedTherapist( value ); }}
                                                    />
                                                </div>
                                            ) : ( <></> )}
                                            <div className='col-12 mt-2'>
                                                <label><b>Date</b></label>
                                                <DateRangePicker
                                                    className='form-control'
                                                    hoverRange={'month'}
                                                    oneTap
                                                    showOneCalendar
                                                    onChange={( value ) => handleCalender( value )}
                                                    shouldDisableDate={date => isAfter( date, new Date() )}
                                                    placeholder={"Filter Date"}
                                                // defaultValue={[ moment().startOf( 'month' ).local().toDate(), moment().local().toDate() ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </SwipeableDrawer>
                        </MobileView>
                    </div>
                    <div className='col-12 col-xl-8 mt-2'>
                        <section>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-12 col-xl-4'>
                                            <h2>
                                                <FcAreaChart /> Reports
                                            </h2>
                                        </div>
                                        <div className='col-xl-6'>

                                        </div>
                                        {selectedHospital ? ( <div className='col-12 col-xl-2'>
                                            <Tooltip placement="bottomRight" title={"Export to CSV"}>
                                                <CSVLink
                                                    data={ipdData}
                                                    headers={ipdHeader}
                                                    asyncOnClick={true}
                                                    ref={ipdRef}
                                                    target="_blank"
                                                    filename={hospitla + "_ipd_" + ( dateRange ? ( moment( dateRange[ 0 ] ).format( 'MMM' ) ) : ( moment().format( "MMM" ) ) )}
                                                />
                                                <CSVLink
                                                    data={opdData}
                                                    headers={opdHeader}
                                                    asyncOnClick={true}
                                                    ref={opdRef}
                                                    filename={hospitla + "_opd_" + ( dateRange ? ( moment( dateRange[ 0 ] ).format( 'MMM' ) ) : ( moment().format( "MMM" ) ) )}
                                                    target="_blank"
                                                />
                                                <button className='form-control btn btn-success mt-2' onClick={handleReport}>
                                                    Download
                                                </button>
                                            </Tooltip>
                                        </div> ) : ( <></> )}

                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row' style={{ height: '200px' }}>
                                        {data.labels?.length > 0
                                            ? ( <Bar data={data} className='border' />
                                            ) : ( <div className='text-center p-5'>No Records Found</div> )}
                                        {data.labels?.length > 0
                                            ? (
                                                <>
                                                    {/* <label>Sales By Hospitals</label> */}
                                                    <PieChart
                                                        series={[
                                                            {
                                                                data: hospitalChart,
                                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                                faded: { innerRadius: 30, additionalRadius: -30 },
                                                            },
                                                        ]}
                                                        sx={{
                                                            [ `& .${pieArcClasses.faded}` ]: {
                                                                fill: 'gray',
                                                            },
                                                        }}
                                                        label={"sales by hospitals"}
                                                        width={250}
                                                        height={200}
                                                        className='border' />
                                                </>
                                            ) : ( <div className='text-center p-5'>No Records Found</div> )}
                                        {data.labels?.length > 0
                                            ? (
                                                <>
                                                    {/* <label>Sales By Hospitals</label> */}
                                                    <PieChart
                                                        series={[
                                                            {
                                                                data: highpaidFinal,
                                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                                faded: { innerRadius: 30, additionalRadius: -30 },
                                                            },
                                                        ]}
                                                        sx={{
                                                            [ `& .${pieArcClasses.faded}` ]: {
                                                                fill: 'gray',
                                                            },
                                                        }}
                                                        label={"sales by hospitals"}
                                                        width={250}
                                                        height={200}
                                                        className='border' />
                                                </>
                                            ) : ( <div className='text-center p-5'>No Records Found</div> )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='mt-2'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='col-12'>
                                        <div className='d-flex'>
                                            <div>
                                                <h2>
                                                    <FcComboChart />  &nbsp;
                                                </h2>
                                            </div>
                                            <div className='mt-3'>
                                                <h5>Transation History</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body overflow-auto' style={{ height: '200px' }} >
                                    <Table striped hover responsive bordered>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>UID/MR</th>
                                                <th>Patient Name</th>
                                                <th>Hospital</th>
                                                <th>Therapist</th>
                                                <th>Amount</th>
                                                <th>Paid</th>
                                                <th>Due</th>
                                                <th>Refund</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map( ( item, i ) => (
                                                <tr key={i}>
                                                    <td>{new Date( item.paymentHistory[ 0 ].momentDate ).toLocaleDateString()}</td>
                                                    <td>{item.UID}/{item.MrNo}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.hospitalName}</td>
                                                    <td>{item.paymentHistory[ 0 ].addedBy}</td>
                                                    <td>{item.paymentHistory[ 0 ].amountAfterDiscount}</td>
                                                    <td>{item.paymentHistory[ 0 ].current}</td>
                                                    <td>{item.paymentHistory[ 0 ].due}</td>
                                                    <td>{item.paymentHistory[ 0 ]?.refundAmount}</td>
                                                    <td>{item.paymentHistory[ 0 ].status}</td>
                                                </tr>
                                            ) )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className='col-2 mt-2'>
                        <BrowserView>
                            {patientData[ 0 ]?._id.month
                                ? (
                                    <div className="overflow-auto" style={{ maxHeight: '600px' }}>
                                        <div>
                                            <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                <CardHeader
                                                    avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcLeave /></Avatar>}
                                                    title={`Month : ` + month
                                                        // `${patientData[ 0 ]?._id.month}` + ", " + `${patientData[ 0 ]?._id.year}`
                                                    }
                                                />
                                            </Card>
                                        </div>
                                        <hr />
                                        <div>
                                            <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                <CardHeader
                                                    avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcBusiness /></Avatar>}
                                                    title={'Sales : ' + totalSales
                                                        // `${patientData[ 0 ]?.totalSalesAmount}`
                                                    }
                                                // sx={{}}
                                                />
                                            </Card>
                                        </div>
                                        <hr />
                                        <div>
                                            <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                <CardHeader
                                                    avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcApproval /></Avatar>}
                                                    title={'Advance : ' + advance
                                                        // `${patientData[ 0 ]?.advance}`
                                                    }
                                                // sx={{}}
                                                />
                                            </Card>
                                        </div>
                                        <hr />
                                        <div>
                                            <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                <CardHeader
                                                    avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcBearish /></Avatar>}
                                                    title={'Refund : ' + refundAmount
                                                        // `${Object.values( due ).map( item => ( ( () =>
                                                        // {
                                                        //     if ( item._id.month === new Date().getMonth() + 1 ) 
                                                        //     {
                                                        //         return ( item.outstanding );
                                                        //     }
                                                        // } )() ) )}`
                                                    }
                                                />
                                            </Card>
                                        </div>
                                        <hr />
                                        <div>
                                            <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                <CardHeader
                                                    avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcDebt /></Avatar>}
                                                    title={'Due : ' + due
                                                        // `${Object.values( due ).map( item => ( ( () =>
                                                        // {
                                                        //     if ( item._id.month === new Date().getMonth() + 1 ) 
                                                        //     {
                                                        //         return ( item.outstanding );
                                                        //     }
                                                        // } )() ) )}`
                                                    }
                                                />
                                            </Card>
                                        </div>
                                        <hr />
                                        <div>
                                            <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                <CardHeader
                                                    avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcCollaboration /></Avatar>}
                                                    title={"Count : " + patientCount.length
                                                    }
                                                />
                                            </Card>
                                        </div>
                                        <hr />
                                        <div>
                                            <Card sx={{ borderRadius: '16px', backgroundColor: '', width: '200px', border: 1 }}>
                                                <CardHeader
                                                    avatar={<Avatar sx={{ bgcolor: 'white', border: '0.1px solid lightgray' }}><FcOvertime /></Avatar>}
                                                    title={"Time : " + timeSessionProper
                                                    }
                                                />
                                            </Card>
                                        </div>
                                    </div>
                                )
                                : ( <></> )}
                        </BrowserView>
                        <MobileView>

                        </MobileView>
                    </div>
                </div >
                <div className='float-end'>
                    <div className='float-end mt-2' >
                        {pageCount > 1 && (
                            <Stack spacing={2}>
                                <Pagination
                                    count={pageCount}
                                    showFirstButton
                                    showLastButton
                                    color='primary'
                                    onChange={( event, value ) => setPage( value )}
                                />
                            </Stack>
                        )}
                    </div>
                </div>
            </Layout >
        </>
    );
};
