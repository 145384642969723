import React, { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { MyPagination } from '../../component/pagination/MyPagination';
import { Modal } from 'antd';
import Role from './Role';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash, faSuitcaseRolling } from '@fortawesome/free-solid-svg-icons';
import DeleteRole from './DeleteRole';

export default function CreateRole()
{
  const [ query, setQuery ] = useState( '' );
  const [ page, setPage ] = useState( 1 );
  const [ visible, setVisible ] = useState( false );
  const [ type, setType ] = useState( '' );
  const [ pageCount, setPageCount ] = useState( 0 );
  const [ name, setName ] = useState( '' );
  const [ capailities, setCapabilities ] = useState( [] );
  const [ selected, setSelected ] = useState( '' );
  const [ updatedName, setUpdatedName ] = useState( "" );
  const [ selectedOption, setSelectedOption ] = useState( [] );
  const [ capabilityArray, setCapabilityArray ] = useState( [] );
  const [ roles, setRoles ] = useState( "" );
  const [ selectedValue, setSelectedValue ] = useState( [] );
  const [ roleName, setRoleName ] = useState( '' );

  const server = `${process.env.REACT_APP_AWS_LAMBDA}`;


  /* getting data a=ready for select */
  const options = [];
  const handleOptions = ( capailities ) =>
  {
    Object.values( capailities ).map( ( c ) => (
      options.push( { value: c._id, label: c.name, slug: c.slug } )
    ) );
    setCapabilityArray( options );
  };

  /* handle search */
  const handleKeuUp = ( e ) =>
  {
    setQuery( e.target.value );
    if ( ( query.trim().length === 0 ) || ( query.trim().length >= 3 ) ) setPage( 1 ); getAllRoles();
  };

  /* handle pagination */
  const handlePageChange = ( page ) =>
  {
    setPage( page );
  };

  /* get all capabilities */
  const getAllCapabilities = async () =>
  {
    const res = await axios.get( `${server}/api/medi/user/get-all-capabilties-list` );
    if ( res.data.success )
    {
      setCapabilities( res.data.capabilities );
      handleOptions( res.data.capabilities );
    }
  };
  useEffect( () =>
  {
    getAllCapabilities();
  }, [] );

  /* handle submit */
  const handleSubmit = async ( e ) =>
  {
    e.preventDefault();
    const stringOptions = JSON.stringify( selectedOption );
    /* getting hospital id from select box array  */
    const roleSelectedValues = [];
    const roleSelectedNames = [];
    Object.values( selectedOption ).map( ( c ) => (
      roleSelectedValues.push( c.value ),
      roleSelectedNames.push( c.slug )
    ) );
    // Object.values( selectedValue ).map( ( c ) => (
    // ) );
    try
    {
      const res = await axios.post( `${server}/api/medi/user/create-role`, { name, stringOptions, roleSelectedValues, roleSelectedNames } );
      if ( res.data.success )
      {
        toast.success( res.data.message );
        setVisible( false );
        getAllRoles()
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      console.log( error );
      toast.error( "Something went wrong" );
    }
  };

  const getAllRoles = async () =>
  {
    try
    {
      const res = await axios.get( `${server}/api/medi/user/get-all-role?page=${page}&q=${query}` );
      if ( res.data.success )
      {
        setRoles( res.data.roles );
        setPageCount( res.data.pagination.pageCount );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };

  useEffect( () =>
  {
    getAllRoles();
  }, [ page ] );


  const handleEdit = async ( e ) =>
  {
    e.preventDefault();
    try
    {
      const stringOptions = JSON.stringify( selectedValue );
      /* getting hospital id from select box array  */
      const roleSelectedValues = [];
      const roleSelectedNames = [];
      Object.values( selectedValue ).map( ( c ) => (
        roleSelectedValues.push( c.value ),
        roleSelectedNames.push( c.slug )
      ) );
      // Object.values( selectedValue ).map( ( c ) => (
      // ) );
      const res = await axios.put( `${server}/api/medi/user/edit-role/${selected}`, { updatedName, stringOptions, roleSelectedValues, roleSelectedNames } );
      if ( res.data.success )
      {
        toast.success( res.data.message );
        setVisible( false );
        getAllRoles();
        setUpdatedName( '' );
        setSelectedValue( '' );
        setSelectedOption( "" );
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };

  const handleDelete = async ( id ) =>
  {
    try
    {
      const res = await axios.delete( `${server}/api/medi/user/delete-role/${id}` );
      if ( res.data.success )
      {
        toast.success( res.data.message );
        setVisible( false );
        getAllRoles();
      }
      else
      {
        toast.error( res.data.message );
      }
    } catch ( error )
    {
      toast.error( "something went wrong" );
    }
  };
  return (
    <Layout>
      <div className='row'>
        <div className='col-xl-2'></div>
        <div className='col-12 col-xl-8'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='text-transform-uppercase'>
                <FontAwesomeIcon icon={faSuitcaseRolling} />{" "}
                &nbsp; Role Master
              </h2>
              <hr />
            </div>
          </div>
          <Card>
            <Card.Header>
              <div className='row'>
                <div className='col-12 col-xl-8'>
                  Manage Role
                </div>
                <div className='col-12 col-xl-4'>
                  <div className='row'>
                    <div className='col-8'>
                      <input type='text' placeholder='search by name' minLength={3} className='form-control'
                        onKeyUp={( e ) => { handleKeuUp( e ); }} />
                    </div>
                    <div className='col-4'>
                      <button className='btn btn-success btn-sm' onClick={() =>
                      {
                        setVisible( true );
                        setType( 'new' );
                      }}>Add Role</button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values( roles ).map( ( c ) => (
                    <tr key={c._id}>
                      <td>{c.name}</td>
                      <td>
                        <button className='btn btn-primary btn-sm' onClick={() =>
                        {
                          setVisible( true );
                          setType( "edit" );
                          setSelected( c._id );
                          setUpdatedName( c.name );
                          setSelectedValue( JSON.parse( c?.capabilities[ 0 ] ) );
                        }}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button className='btn btn-danger ms-1 btn-sm' onClick={() =>
                        {
                          setType( 'delete' );
                          setVisible( true );
                          setRoleName( c.name );
                          setSelected( c._id );
                          // handleDelete( c._id );
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ) )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className='float-end mt-2' >
        {pageCount > 1 && (
          <MyPagination
            total={pageCount}
            current={page}
            onChangePage={handlePageChange}
          />
        )}
      </div>
      <Modal open={visible} destroyOnClose={true}
        onCancel={() =>
        {
          setVisible( false );

        }} footer={null} >
        {( () =>
        {
          if ( type === 'new' )
          {
            return ( <Role
              type={type} name={name} setName={setName}
              selectedOption={selectedOption} setSelectedOption={setSelectedOption}
              capabilityArray={capabilityArray} handleSubmit={handleSubmit}
            /> );
          }
          if ( type === 'edit' )
          {
            return ( <Role
              type={type} name={updatedName} setName={setUpdatedName}
              selectedOption={selectedValue} setSelectedOption={setSelectedValue}
              capabilityArray={capabilityArray} handleSubmit={handleEdit}
            /> );
          }
          if ( type === 'delete' )
          {
            return (
              <DeleteRole
                selected={selected}
                handleDelete={handleDelete}
                roleName={roleName}
              />
            );
          }
        } )()}

      </Modal>
    </Layout>
  );
}
