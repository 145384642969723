import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Layout from '../../component/layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { diagnosticTypeR } from "../../redux/sessionReducer";
import axios from 'axios';
import toast from 'react-hot-toast';

export default function DiagnosticSelectTab()
{
    const [ diagnosticType, setDiagnosticType ] = useState( "" );
    const navigate = useNavigate();
    const [ diagnosticData, setDiagnosticData ] = useState( '' );
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;


    const diagnosticStore = '';
    // diagnosticStore = useSelector( state => state.session.diagnosticTypes );


    const getDiagnosticList = async () =>
    {
        const { data } = await axios.get( `${server}/api/medi/diagnostic/diagnosticList` );
        if ( data.success )
        {
            setDiagnosticData( data.DiagnoasticList );
        } else
        {
            toast.error( data.message );
        }
    };

    useEffect( () =>
    {
        getDiagnosticList();
    }, [] );

    // console.log( diagnosticData );

    const dispatch = useDispatch();
    return (
        <Layout>
            <div className='row'>
                <div className='col-xl-2'>

                </div>
                <div className='col-12 col-xl-8'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-transform-uppercase'>
                                Patient Registration
                            </h2>
                        </div>
                    </div>
                    <hr />
                    <hr />
                    <hr />

                    <div className='card'>
                        <div className='card-header text-center'>
                            <b>Select Diagnostic Type</b>
                        </div>
                        <div className='card-body'>
                            <div className='card-group'>
                                <Button onClick={() =>
                                {
                                    setDiagnosticType( 'Orthopedic' );
                                    dispatch( diagnosticTypeR( {
                                        type: 'Orthopedic'
                                    } ) );
                                    navigate( '/patient-diagnostic-template' );
                                }}>
                                    <Card sx={{ maxWidth: 300 }} className='mx-1'>
                                        <CardActionArea>
                                            <CardMedia
                                                component={'img'}
                                                // height={140}
                                                alt='card-image'
                                                image='ortho.jpg'
                                            />
                                        </CardActionArea>
                                        {/* <CardActions>
                                            <FormControlLabel control={<Checkbox />} label="Ortho" />
                                        </CardActions> */}
                                        <CardContent>
                                            {Object.values( diagnosticData ).map( ( item ) => (
                                                () =>
                                                {
                                                    if ( item.name === 'Orthopedic' )
                                                    {
                                                        return ( <div>{item.name}</div> );
                                                    }
                                                }
                                            )() )}
                                        </CardContent>
                                    </Card>
                                </Button>
                                <Button onClick={() =>
                                {
                                    setDiagnosticType( 'Neurological' );
                                    dispatch( diagnosticTypeR( {
                                        type: 'Neurological'
                                    } ) );
                                    navigate( '/patient-diagnostic-template' );
                                }}>
                                    <Card sx={{ maxWidth: 300 }} className='mx-1'>
                                        <CardActionArea>
                                            <CardMedia
                                                component={'img'}
                                                // height={140}
                                                alt='card-image'
                                                image='neuro.jpg'
                                            />
                                        </CardActionArea>
                                        <CardContent>
                                            {Object.values( diagnosticData ).map( ( item ) => (
                                                () =>
                                                {
                                                    if ( item.name === 'Neurological' )
                                                    {
                                                        return ( <div>{item.name}</div> );
                                                    }
                                                }
                                            )() )}
                                        </CardContent>
                                    </Card>
                                </Button>
                                <Button onClick={() =>
                                {
                                    setDiagnosticType( 'Cardiac' );
                                    dispatch( diagnosticTypeR( {
                                        type: 'Cardiac'
                                    } ) );
                                    navigate( '/patient-diagnostic-template' );
                                }}>
                                    <Card sx={{ maxWidth: 300 }} className='mx-1'>
                                        <CardActionArea>
                                            <CardMedia
                                                component={'img'}
                                                // height={140}
                                                alt='card-image'
                                                image='cardic.jpg'
                                            />
                                        </CardActionArea>
                                        <CardContent>
                                            {Object.values( diagnosticData ).map( ( item ) => (
                                                () =>
                                                {
                                                    if ( item.name === 'Cardiac' )
                                                    {
                                                        return ( <div>{item.name}</div> );
                                                    }
                                                }
                                            )() )}
                                        </CardContent>
                                    </Card>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
